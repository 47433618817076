import {makeAutoObservable} from 'mobx';
import {apiUrl} from "../constants/constants";

export class CartDataStore {
    cartQuantity = 0;

    constructor() {
        makeAutoObservable(this);
    }

    callCartCount = async () => {
        const res = await fetch(apiUrl + '/api/cart/count', {credentials: 'include'});
        if (res.status !== 200) {
            // alert('서버와의 통신에 실패했습니다. 잠시후 다시 시도해 주세요');
            return;
        }
        const data = await res.json();
        this.cartQuantity = data;
    };
}
