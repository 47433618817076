import React from "react";
import RestockingModal from "../components/RestockingModal/RestockingModal";
import HolidayDeliveryModal from "../components/HolidayDeliveryModal/HolidayDeliveryModal";

function ModalPage() {
    return (
        <>
            <RestockingModal/>
            <HolidayDeliveryModal/>
        </>
    )

}

export default ModalPage