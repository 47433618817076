




import React, { useEffect, useRef, useState } from 'react';
import './PopupWrapper.scss';


const PopupWrapper = ({
  popUpCloseAction,
  children,
  className,
  aleadyRendered,
  aleadyRenderedVisibility,
  ...otherProps

}) => {


  const [doNotClose, setDoNotClose] = useState();

  const bodyOverFlowRef = useRef();
  useEffect(()=>{
    // let bodyOverFlow;
    if (aleadyRendered){
      if (aleadyRenderedVisibility){
        if(document.body){
          bodyOverFlowRef.current = document.body.style.overflow || 'scroll';
          document.body.style.overflow = 'hidden';
        }
                
      } else {
        if(document.body){
          // alert('scroll 3<!@#!@#');
          // document.body.style.overflow = 'scroll';
          document.body.style.overflow= bodyOverFlowRef.current;
          // document.body.style.overflow= bodyOverFlow;
        }
      }


    } else {
      document.body.style.overflow = 'hidden';
      return ()=>{
        // alert('scroll 2');
        document.body.style.overflow = 'scroll';
      };
    }
        
  }, [aleadyRendered, aleadyRenderedVisibility]);

  const bgClickClose =(e)=>{
        
    if(doNotClose){
      return;
    }

    if(e.target.classList[0] === 'popup-bg'){
      popUpCloseAction();
    } else{
      return;
    }        
  };

  return (
    <div className={`popup-bg ${className ? className : ''}`} onClick={(e)=>{
      bgClickClose(e);
    }}
    {...otherProps}
    >
      <div className="popup-box"  
        onMouseDown = {(e)=>{
          setDoNotClose(true);
        }} 
        onMouseUp = {(e)=>{
          setDoNotClose(false);

        }}
            
            
      >
        {children}
      </div>
    </div>



  );


};



export default PopupWrapper;