import React from "react"
import "./RestockingButton.scss"
import {AppContext} from "../../context/AppContext";

interface IRestockProps {
    productId
}

function RestockingButton(props: IRestockProps) {
    const {productId} = props
    const {modalDataStore} = React.useContext(AppContext);
    const {setIsRestockingModalProductId, setIsRestockingModalVisible} = modalDataStore
    return (
        <div className="restocking-button-wrapper"
             onClick={(event) => {
                 setIsRestockingModalVisible(true);
                 setIsRestockingModalProductId(productId);
                 event.stopPropagation();
             }}
        >
            <div className="restocking-button">
                <div className="alarm-img-wrapper">
                    <img src={require("../../../img/icon/alarm_black.png")}/>
                </div>
                <div className="alarm-text">
                    입고 알림 받기
                </div>
            </div>
        </div>
    )
}

export default RestockingButton
