import {makeAutoObservable} from 'mobx';

export class HomePageStore {
    isBlackIcons;

    constructor() {
        makeAutoObservable(this);
    }

    setIsBlackIcons = (bool: boolean) => {
        this.isBlackIcons = bool;
    };
}
