import React from 'react';

export const HalfStar = () => {
    return (
        <svg height="15" viewBox="0 0 19.5 15" width="16" xmlns="http://www.w3.org/2000/svg">
            <g fill="#604448" fillRule="evenodd" transform="translate(1, -1.8)">
                <path
                    d="m9.5 0 2.9389263 5.95491503 6.5716389.95491503-4.7552826 4.63525494 1.1225699 6.5450849-5.8778525-3.0901699z"
                    opacity=".284482"/>
                <path
                    d="m0 0 2.93892626 5.95491503 6.5716389.95491503-4.75528258 4.63525494 1.12256994 6.5450849-5.87785252-3.0901699z"
                    transform="matrix(-1 0 0 1 9.5106 0)"/>
            </g>
        </svg>
    )
}

export const FilledStar = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
            <g fill="none" fillRule="evenodd">
                <g fill="#604448">
                    <g>
                        <path
                            d="M8 15L3.298 17.472 4.196 12.236 0.392 8.528 5.649 7.764 8 3 10.351 7.764 15.608 8.528 11.804 12.236 12.702 17.472z"
                            transform="translate(-48 -247) translate(48 244)"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}


export const EmptyStar = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
            <g fill="none" fillOpacity=".2" fillRule="evenodd">
                <g fill="#604448">
                    <g>
                        <path
                            d="M68 15L63.298 17.472 64.196 12.236 60.392 8.528 65.649 7.764 68 3 70.351 7.764 75.608 8.528 71.804 12.236 72.702 17.472z"
                            transform="translate(-108 -247) translate(48 244)"/>
                    </g>
                </g>
            </g>
        </svg>

    )


}
export const SmallHalfStar = () => {
    return (
        <svg height="9" viewBox="0 0 19.5 9" width="10" xmlns="http://www.w3.org/2000/svg">
            <g fill="#604448" fillRule="evenodd" transform="translate(0, -4.6)">
                <path
                    d="m9.5 0 2.9389263 5.95491503 6.5716389.95491503-4.7552826 4.63525494 1.1225699 6.5450849-5.8778525-3.0901699z"
                    opacity=".284482"/>
                <path
                    d="m0 0 2.93892626 5.95491503 6.5716389.95491503-4.75528258 4.63525494 1.12256994 6.5450849-5.87785252-3.0901699z"
                    transform="matrix(-1 0 0 1 9.5106 0)"/>
            </g>
        </svg>
    )
}

export const SmallFilledStar = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9">
            <g fill="none" fillRule="evenodd">
                <g fill="#604448">
                    <g>
                        <g>
                            <path
                                d="M29 7.5L26.061 9.045 26.622 5.773 24.245 3.455 27.531 2.977 29 0 30.469 2.977 33.755 3.455 31.378 5.773 31.939 9.045z"
                                transform="translate(-278 -559) translate(48 556) translate(206 3)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}


export const SmallEmptyStar = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="9px" viewBox="0 0 10 9" version="1.1">

            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.2">
                <g id="M_review_01" transform="translate(-302.000000, -559.000000)" fill="#604448">
                    <g id="01" transform="translate(48.000000, 556.000000)">
                        <g id="MO/icon/review_star" transform="translate(206.000000, 3.000000)">
                            <polygon id="Star-Copy-3"
                                     points="53 7.5 50.0610737 9.04508497 50.6223587 5.77254249 48.2447174 3.45491503 51.5305369 2.97745751 53 0 54.4694631 2.97745751 57.7552826 3.45491503 55.3776413 5.77254249 55.9389263 9.04508497"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )


}


export const BigFilledStar = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22">
            <g fill="none" fillRule="evenodd">
                <g fill="#604448">
                    <path
                        d="M60 222L52.947 225.708 54.294 217.854 48.587 212.292 56.473 211.146 60 204 63.527 211.146 71.413 212.292 65.706 217.854 67.053 225.708z"
                        transform="translate(-48 -204)"/>
                </g>
            </g>
        </svg>
    )
}


export const BigEmptyStar = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22">
            <g fill="none" fillOpacity=".2" fillRule="evenodd">
                <g fill="#604448">
                    <path
                        d="M144 222L136.947 225.708 138.294 217.854 132.587 212.292 140.473 211.146 144 204 147.527 211.146 155.413 212.292 149.706 217.854 151.053 225.708z"
                        transform="translate(-132 -204)"/>
                </g>
            </g>
        </svg>

    )


}
