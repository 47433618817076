import React, {
    FunctionComponent,
    ReactComponentElement,
    ReactNode,
    useEffect,
    useReducer,
    useRef,
    useState
} from 'react';
import {BrownCloseIcon} from '../../../assets/CloseIcon';

import './BubbleContent.scss';


const Arrow = ({IsOpen, setIsContentVisible}) => {


    return (
        <>
            {IsOpen ?
                <img
                    onClick={() => {
                        setIsContentVisible(true);
                    }}
                    src="/src/img/bubble-up.svg" alt=""/> :
                <img
                    onClick={() => {
                        setIsContentVisible(false);
                    }}
                    src="/src/img/bubble-down.svg" alt=""/>
            }
        </>
    );
};


const BubbleContent: FunctionComponent<{
    bubbleTarget: ReactNode;
    bubbleContent: ReactNode;
//  tailPosition : string;
    autoHide?: boolean;
    withArrow?: boolean;
}> = ({
          bubbleTarget,
          bubbleContent,
          autoHide = false,
          withArrow = false,

      }) => {
    const [isContentVisible, setIsContentVisible] = useState<boolean>(false);


    // 내부 로직으로 처리해 버리면?
    // 이 컨텐츠가아닌 다른걸 클릭하면 꺼지는 방향으로 하면 이컴포넌트 내에서 차리할 수 있다.

    useEffect(() => {
        const closeContent = (e: Event) => {
            const target = e.target as HTMLDivElement;
            if (target.classList[0] !== 'noBubbleClose' && isContentVisible) {
                setIsContentVisible(false);
                if (isContentVisible) {
                    e.stopPropagation();
                }
            }
        };
        document.body.addEventListener('mouseup', closeContent);

        return (() => {
            document.body.removeEventListener('mouseup', closeContent);

        });
    }, [isContentVisible]);

    useEffect(() => {
        if (isContentVisible && autoHide) {
            setTimeout(() => {
                setIsContentVisible(false);
            }, 3000);
        }
    }, [isContentVisible]);

    return (
        <div
            style={{
                position: 'relative'
            }}
        >
            <div
                onClick={() => {
                    setIsContentVisible(true);
                }}
                style={{
                    cursor: 'pointer',
                    display: 'flex',

                }}

            >
                {bubbleTarget}{withArrow ?
                <Arrow
                    IsOpen={isContentVisible}
                    setIsContentVisible={setIsContentVisible}
                /> : null}
            </div>
            {
                isContentVisible &&
                <div className={'noBubbleClose bubble-content-wrapper center'}>
                    <div
                        style={{
                            position: 'absolute',
                            top: '16px',
                            right: '16px',
                            cursor: 'pointer'
                        }}
                        className="close"

                        onClick={() => {
                            setIsContentVisible(false);
                        }}
                    >
                        <BrownCloseIcon/>
                    </div>

                    {bubbleContent}


                </div>
            }

        </div>
    );
};


export default BubbleContent; 