import React, {useEffect} from "react"
import "./KakaoGiftModal.scss"
import {kakaoGiftList} from "../../constants/constants";
import ExternalIcon from "../../../img/icon/icon-external-link.svg"
import KakaoGiftLogo from "../../../img/icon/kakaogift-logo.svg"
import CloseIcon from "../../../img/close-white-button.svg"
interface IProps {
    isKakaoGiftPopup: boolean;
    closeAction: () => void;
    productData: any;
}

const KakaoGiftModal = (props: IProps) => {
    const {isKakaoGiftPopup, closeAction, productData} = props
    if (!isKakaoGiftPopup) {
        return <></>
    }

    return (
        <div className={"kakaoGift-modal-modal-wrapper"}>
            <div className={"kakaoGift-modal-button-wrapper"}>
                <div className={"close-button"} onClick={() => closeAction()}>
                    <img src={CloseIcon}/>
                </div>
                <div className={"kakaoGift-modal-header"}>
                    해당 제품은 [카카오톡 선물하기]<br/>전용 상품입니다.
                </div>
                <div className={"kakaoGift-modal-description"}>
                    소중한 분에게 카카오톡으로 바로 선물할 수도 있으며,<br/>&apos;나에게 선물하기&lsquo;로 직접 구매 역시 가능합니다.
                </div>
                <div className={'kakaoGift-button-wrapper'}>
                    <div className={'kakaoGift-button'}
                         onClick={() => window.open(kakaoGiftList.get(productData?.product?.slug), '_blank').focus()}
                    >
                        <div className={'gift-icon'}><img
                            src={KakaoGiftLogo}
                        /></div>
                        <div className={'title-wrapper'}>
                            <div className={'text'}>카카오톡 선물하기</div>
                            <div className={'link-icon'}><img
                                src={ExternalIcon}/>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className={'kakaoGift-subText'}>선물하기 / 일반 구매 모두 가능</div>
                </div>
            </div>
        </div>
    )
}

export default KakaoGiftModal
