import React from 'react';


interface IProps {
    imgUrl: string;
    className?: string;
    style?: any;
    id?: string;
}

export default function LazyImage(props: IProps) {
    const {imgUrl, className, style, id} = props
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const imgRef = React.useRef<HTMLImageElement>(null);
    const observer = React.useRef<IntersectionObserver>();

    React.useEffect(() => {
        observer.current = new IntersectionObserver(intersectionObserver);
        imgRef.current && observer.current.observe(imgRef.current);
    }, [])

    const cache = {};

    // const preLoadImage = (url: string) => {
    //     if (!cache[url]) {
    //         const img = new Image();
    //         img.onload = () => {
    //             setIsLoading(true)
    //         }
    //         img.src = url;
    //         cache[url] = img;
    //     }
    // };
    //
    // React.useEffect(() => {
    //     preLoadImage(imgUrl);
    // }, [imgUrl]);

    const intersectionObserver = React.useCallback(
        (entries: IntersectionObserverEntry[], io: IntersectionObserver) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    io.unobserve(entry.target);
                    setIsLoading(true);
                }
            })
        },
        [setIsLoading]
    );
    return (
        <img className={className} style={style} ref={imgRef} src={isLoading ? imgUrl : ""} id={id} loading={'lazy'}/>
    )
}