import React, {Component, FunctionComponent, useEffect, useRef, useState} from 'react';

import './BroProducts.scss';

// import { constants } from 'buffer';

// const useIsMounted = () => {
// 	const isMounted = React.useRef(false);
// 	React.useEffect(() => {
// 		isMounted.current = true;
// 		return () => isMounted.current = false;
// 	}, []);
// 	return isMounted;
// };

const BroProducts: FunctionComponent<{

    productItems?: any,
    wording?: string,

}> = (
    {
        productItems,
        wording,

    }
) => {


    return (


        <div className="broWrapper">
            <div className="broTitle">{productItems[0].bro_product_wording}</div>
            <div className="broList">

                {

                    (productItems) && (

                        productItems.map((menuItem, i) => {

                            return (
                                <div
                                    className={i !== 0 ? `broItem` : `broItem selected`}

                                    onClick={() => {

                                        if (i === 0) {
                                            return;
                                        }

                                        window.location.href = "/product/" + menuItem.slug;
                                    }}
                                    key={i}>

                                    <div className="image">
                                        {menuItem.images &&
                                            <img src={menuItem.bro_product_thumbnail} alt="" width="92" height="92"/>}
                                    </div>
                                    <div className="title"> {menuItem.bro_product_title} </div>
                                </div>
                            );
                        })
                    )
                }


            </div>
        </div>
    );
};

export default BroProducts;