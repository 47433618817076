import React, {useState, useMemo} from 'react';
import {
    callChatPlugIn,
    linkToBrunch,
    linkToInsta,
    linkToSpotify,
    linkToYoutube,
    moveToLink,
    moveToLogin,
    moveToSignup,
    openPlusKakao,
    showOverlay,
} from '../../utils/utils';
import './FooterIncenseVer.scss';
import InstaIcon from "../../../img/incenseHome/common-icon-sns-instagram-line-white.svg"
import YoutubeIcon from "../../../img/incenseHome/common-icon-sns-youtube-line-white.svg"
import BrunchIcon from "../../../img/incenseHome/common-icon-sns-brunch-line-white@2x.png"
import KakaoIcon from "../../../img/incenseHome/common-icon-sns-kakao-line-white.svg"
import MusicIcon from "../../../img/incenseHome/common-icon-sns-music-line-white.svg"
import MinusIcon from "../../../img/incenseHome/icon-footer-minus.svg"
import PlusIcon from "../../../img/icon-footer-plus.svg"
import {AppContext} from "../../context/AppContext";
import Terms from "../Terms/Terms";
import {useNavigate} from "react-router-dom";
import NewTabIcon from "../../../img/icon/newTabIcon.png"

const FooterIncenseVer = ({className = '', isHome = false}) => {
    const [openCategories, setOpenCategories] = useState(false);
    const {userDataStore} = React.useContext(AppContext);
    const isLogin = userDataStore.isLogin;
    const navigate = useNavigate()
    const date = useMemo(() => new Date(), []);
    const year = useMemo(() => date.getFullYear(), [date]);

    const categoriesBoxMb = useMemo(() => (
        <div className="categoriesBox mb">
            <div onClick={() => {
                navigate('/collection/moodwater');
            }}>무드워터
            </div>
            <div onClick={() => {
                navigate('/collection/fragrance');
            }}>프래그런스
            </div>
            <div onClick={() => {
                navigate('/collection/gift');
            }}>기프트
            </div>
            <div onClick={() => {
                navigate('/collection/homewear');
            }}>홈웨어
            </div>
            <div onClick={() => {
                navigate('/collection/hygiene');
            }}>청결용품
            </div>
            <div onClick={() => {
                navigate('/collection/goods');
            }}>브랜드 굿즈
            </div>
            <div onClick={() => {
                navigate('/collection/all');
            }}>모든 제품
            </div>
        </div>
    ), []);

    const categoriesBoxPc = useMemo(() => (
        <div className="categoriesBox pc">
            <div onClick={() => {
                navigate('/collection/moodwater');
            }}>무드워터
            </div>
            <div onClick={() => {
                navigate('/collection/fragrance');
            }}>프래그런스
            </div>
            <div onClick={() => {
                navigate('/collection/gift');
            }}>기프트
            </div>
            <div onClick={() => {
                navigate('/collection/homewear');
            }}>홈웨어
            </div>
            <div onClick={() => {
                navigate('/collection/hygiene');
            }}>청결용품
            </div>
            <div onClick={() => {
                navigate('/collection/goods');
            }}>브랜드 굿즈
            </div>
            <div onClick={() => {
                navigate('/collection/all');
            }}>모든 제품
            </div>
        </div>
    ), []);


    return (
        <footer id="footer" className={`FooterIncenseVer ${className} ${isHome ? 'home' : ''}`}>
            <div className={`categories ${openCategories ? 'open' : ''}`}>
                <div className={'categoriesWrapper'} style={{marginTop: "0"}}>
                    <div
                        onClick={() => setOpenCategories((prev) => !prev)}
                        className={`categoryItem category ${openCategories ? 'open' : ''}`}
                    >
                        모든 제품
                        <img
                            src={
                                openCategories
                                    ? MinusIcon
                                    : PlusIcon
                            }
                            alt="see more category button"
                        />
                    </div>
                    {openCategories && categoriesBoxMb}
                    <div className="categoryItem" onClick={()=>{
                        navigate('/places');
                    }}>
                        오프라인 스토어 찾기
                    </div>
                    <div
                        onClick={() => {
                            callChatPlugIn();
                        }}
                        className="categoryItem"
                    >
                        상담 문의
                    </div>
                    <div
                        onClick={() => {
                            navigate('/makersnote');
                        }}
                        className="categoryItem"
                    >
                        Maker&apos;s Note
                    </div>
                    <div
                        onClick={() => {
                            navigate('/music');
                        }}
                        className="categoryItem"
                    >
                        Music
                    </div>
                </div>
                {openCategories && categoriesBoxPc}
                <div className={'categoriesWrapper'}>
                    <div className="categoryItem">
                        <a
                            style={{cursor: 'pointer', textDecoration: 'unset'}}
                            href="https://gitt.notion.site/Collins-NEW-ba09df29eceb40f088f42cd7e97e53d2"
                            target="blank"
                        >
                            업무 제휴
                            <img className={"img-icon"} src={NewTabIcon}/>
                        </a>
                    </div>
                    <div className="categoryItem">
                        <a
                            style={{cursor: 'pointer', textDecoration: 'unset'}}
                            href="https://gitt.notion.site/Collins-258ec20a2cd84a52aa9a1af5bff8d0c3"
                            target="blank"
                        >
                            대량구매/유통
                            <img className={"img-icon"} src={NewTabIcon}/>
                        </a>
                    </div>
                    <div className="categoryItem">
                        <a
                            style={{cursor: 'pointer', textDecoration: 'unset'}}
                            href="https://gitt.notion.site/Collins-Wholesale-Global-Partnership-5f6e983f6aba4ebda91d159fa06c13c4"
                            target="blank"
                        >
                            Wholesale/Global
                            <img className={"img-icon"} src={NewTabIcon}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="divider"/>
            <div className="companyInfo">
                <div className="title">주식회사 깃컴퍼니</div>
                <div className="companyDetails">
                    주소: 서울 성동구 성수일로 8길 59, B동 8층 802호 / 사업자등록번호: 157-86-00367 / TEL: 1522-8671 / Email: hello@collinslife.co /
                    대표이사: 박성민 / 개인정보책임자: 이광배 / 통신판매업신고: 2018-서울강남-04755{' '}
                </div>
                <div className="mores">
                    <div
                        onClick={() => {
                            showOverlay('#terms');
                        }}
                    >
                        이용 약관
                    </div>
                    <div
                        onClick={() => {
                            showOverlay('#privacy');
                        }}
                    >
                        개인정보취급방침
                    </div>
                </div>
            </div>
            {!isLogin && (
                <div className="loginOrSignup">
                    <div
                        onClick={() => {
                            navigate('/login/menu');
                        }}
                    >
                        로그인
                    </div>
                    <div
                        onClick={() => {
                            navigate('/signup/menu')
                        }}
                    >
                        회원가입
                    </div>
                </div>
            )}
            <div className="icons">
                <img
                    onClick={() => {
                        linkToInsta();
                    }}
                    src={InstaIcon}
                />
                <img
                    onClick={() => {
                        linkToYoutube();
                    }}
                    src={YoutubeIcon}
                />
                <img
                    onClick={() => {
                        linkToBrunch();
                    }}
                    src={BrunchIcon}
                />
                <img
                    onClick={() => {
                        openPlusKakao();
                    }}
                    src={KakaoIcon}
                />
                <img
                    onClick={() => {
                        linkToSpotify();
                    }}
                    src={MusicIcon}
                />
            </div>
            <div className="copyright">© {year} Gitt Company</div>
        </footer>
    );
};

export default FooterIncenseVer;
