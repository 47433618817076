import 'isomorphic-fetch';
import 'react-app-polyfill/ie11';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React, {Suspense, useEffect, useMemo, useState} from 'react';
import './homepage.scss';
import {
    CartIconIncenseVerDt,
    CartIconIncenseVerMo, CartWithNumberDtChangeColor, CartWithNumberMoChangeColor
} from '../../assets/CartWithNumber';

import {moveToLink, callHiddenNav} from '../utils/utils.js';
import smoothscroll from 'smoothscroll-polyfill';
import SpinnerControl from '../components/SpinnerControl/SpinnerControl.jsx';
import Header from '../components/Header/Header.component';
import CartPopUp from "../components/CartPopUp/CartPopUp.component";
import HamburgerIcon from "../../img/incenseHome/hamburger-icon.svg"
import LogoIcon from "../../img/incenseHome/collins-logo-w.svg"
import LogoIconB from "../../img/incenseHome/collins-logo-b.svg"

import {AppContext} from "../context/AppContext";
import CollinsHomeRenewalComponent from "../components/CollinsHomeRenewal/CollinsHomeRenewal.component";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {ColorLogo} from "../../assets/Logo";

smoothscroll.polyfill();

(function () {
    scrollTo();
})();

const HomePage = observer(() => {
    const {cartDataStore, bannerDataStore, userDataStore} =
        React.useContext(AppContext);
    const {isLogin, user} = userDataStore
    const {callCartCount, cartQuantity} = cartDataStore;
    const [isSpinnerShow, setIsSpinnerShow] = useState(false);
    const [dataForCart, setDataForCart] = useState<any>({});
    const [imageDataForCart, setImageDataForCart] = useState<Array<string>>([]);
    const [isCartPopUp, setIsCartPopUp] = useState<boolean>();
    const [userName] = useState(isLogin ? user.firstName + '씨' : '당신');
    const [isScrollTop, setIsScrollTop] = useState(false)
    const [isSectionNavSticky] = useState<boolean>()
    const navigate = useNavigate()

    const FooterIncenseVer = useMemo(
        () => React.lazy(() => import('../components/Footer/FooterIncenseVer.component')),
        []
    );

    const channelScroll = () => {
        const fadeStart = 0
            , fadeUntil = 128
            , fading = document.getElementById('ch-plugin')
        if (fading) {
            const fadingElDivChild = fading.querySelectorAll('div')[1];
            const offset = window.scrollY
            if (checkChScriptStatusInDt()) {
                fadingElDivChild.style.opacity = "1";
                return
            }
            let opacity = 1
            ;
            if (offset <= fadeStart) {
                opacity = 0;
            } else if (offset <= fadeUntil) {
                opacity = 1 - (1 - offset / fadeUntil);
            }
            if (fadingElDivChild) {
                if (fadingElDivChild.id === 'ch-plugin-script') {
                    setTimeout(channelScroll, 1000)
                } else {
                    fadingElDivChild.style.opacity = String(opacity)
                }
            }
        }
    }

    const checkChScriptStatusInDt = () => {
        const chScriptElement = document.getElementsByClassName('ch-desk-messenger');
        if (chScriptElement.length > 0) {
            if (!chScriptElement[0].classList.contains('ch-messenger-hidden')) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }

    }

    const showChannelIcon = () => {
        const fading = document.getElementById('ch-plugin')
        if (fading) {
            const fadingElDivChild = fading.querySelectorAll('div')[1];
            if (fadingElDivChild) {
                fadingElDivChild.style.opacity = "1";
            }
        }
    }

    const currentScrollPosition = () => {
        channelScroll()
        if (window.scrollY === 0) {
            setIsScrollTop(false)
        } else {
            setIsScrollTop(false)
        }
    }


    useEffect(() => {
        callCartCount();
        bannerDataStore.callTopBannerInfo();
        const scrollHandler = () => {
            const headLogo = document.querySelector('.header-logo.fixed_logo.dt');
            if (headLogo) {
                if (window.scrollY > 15) {
                    headLogo?.classList.add('transparent');
                } else {
                    headLogo?.classList.remove('transparent');
                }
            }
        };
        scrollHandler();
        setTimeout(channelScroll, 400)

        window.addEventListener('scroll', scrollHandler);

        window.addEventListener("scroll", currentScrollPosition);
        return () => {
            window.removeEventListener('scroll', scrollHandler);

            window.removeEventListener("scroll", currentScrollPosition)
            showChannelIcon()
        }
    }, [])

    return (
        <SpinnerControl isSpinnerShow={isSpinnerShow}>
            <CartPopUp
                isLogin={isLogin}
                productData={dataForCart}
                imageData={imageDataForCart}
                closeAction={() => {
                    setIsCartPopUp(false);
                }}
                visible={isCartPopUp}
                callCartCount={callCartCount}
                setIsSpinnerShow={setIsSpinnerShow}
                isInSearch={null}
            />
            <div id="homePage">
                <>
                    <div style={{display: isScrollTop ? "none" : "block"}}>
                        <Header
                            isSearchIconColor={"#604448"}
                            isNoHeaderMove={true}
                            isInHome={true}
                            callCartCount={callCartCount}
                            setIsSpinnerShow={setIsSpinnerShow}
                            isSectionNavSticky={isSectionNavSticky}
                            topBannerData={{data: bannerDataStore.topBannerData}}
                            topBannerEnabled={
                                bannerDataStore.topBannerData && bannerDataStore.topBannerData.enabled
                            }
                            leftContent={
                                <>
                                    {/* <HambergerForHiddenNav className="dt" /> */}
                                    <img
                                        src="https://fly.gitt.co/collins/img/gnb_menu_40.svg"
                                        // src={HamburgerIcon}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            callHiddenNav();
                                        }}
                                        className="hamburger dt "
                                        id="homeHamburgerIconSearchOpened"
                                    />
                                    <img
                                        src={LogoIconB}
                                        className="mo"
                                        style={{cursor: 'pointer'}}
                                        onClick={(e) => {
                                            moveToLink('/', e);
                                        }}
                                    />
                                </>
                            }
                            centerContent={
                                <>
                                    {!isSectionNavSticky && (
                                        <img
                                            src={LogoIconB}
                                            className="header-logo fixed_logo dt"
                                            style={{pointerEvents: 'all', cursor: 'pointer'}}
                                            onClick={(e) => {
                                                moveToLink('/', e);
                                            }}
                                            id="homeLogoIconSearchOpened"
                                        />
                                    )}
                                </>
                            }
                            rightContent={
                                <>
                                    <div
                                        className="CartIconWidthNumberDt dt"
                                        style={{
                                            height: '40px',
                                        }}
                                        onClick={(e) => {
                                            moveToLink('/cart', e);
                                        }}
                                        // id="homeCartIconSearchOpened"
                                    >
                                        <CartWithNumberDtChangeColor color={'#604448'} fontColor={"#FFFFF5"}
                                                                     number={cartQuantity}/>
                                    </div>
                                    <div
                                        className="flex  mo"
                                        // style ={{display: 'flex'}}
                                    >
                                        <div
                                            className="CartIconWidthNumberMo "
                                            style={{marginRight: '8px'}}
                                            onClick={(e) => {
                                                moveToLink('/cart', e);
                                            }}
                                        >
                                            <CartWithNumberMoChangeColor color={'#604448'} fontColor={"#FFFFF5"}
                                                                         number={cartQuantity}/>
                                        </div>
                                        {/* <HambergerForHiddenNav /> */}
                                        <img
                                            src="https://fly.gitt.co/collins/img/gnb_menu_40.svg"
                                            // src={HamburgerIcon}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                callHiddenNav();
                                            }}
                                            className="hamburger"
                                        />
                                    </div>
                                </>
                            }
                            isHeaderBgColorChange
                        />
                    </div>
                    <CollinsHomeRenewalComponent userName={userName}
                                                 setDataForCart={setDataForCart}
                                                 setIsCartPopUp={setIsCartPopUp}
                                                 setImageDataForCart={setImageDataForCart}/>
                    {/*<div*/}
                    {/*    className="menuCartIconWidthNumberDt hidden"*/}
                    {/*    onClick={(e) => {*/}
                    {/*        moveToLink('/cart', e);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <CartWithNumberDtChangeColor color={'#604448'} fontColor={"#FFFFF5"} number={cartQuantity}/>*/}
                    {/*</div>*/}
                    {/*<div*/}
                    {/*    className="menuCartIconWidthNumberMo hidden"*/}
                    {/*    onClick={(e) => {*/}
                    {/*        moveToLink('/cart', e);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <CartWithNumberMoChangeColor color={'#604448'} fontColor={"#FFFFF5"} number={cartQuantity}/>*/}
                    {/*</div>*/}
                    <Suspense fallback={<div></div>}>
                        <FooterIncenseVer isHome={true}/>
                    </Suspense>
                </>
            </div>
        </SpinnerControl>
    );
});

export default HomePage;
