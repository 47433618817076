import {makeAutoObservable} from 'mobx';

export class UserDataStore {

    isLogin = false;
    user = null;

    constructor() {
        makeAutoObservable(this);
    }

    setUserData = (data) => {
        this.user = data
    }
    setIsLogin = (data) => {
        this.isLogin = data
    }
}