import React, {useRef, useEffect, useState} from 'react';
import {gsap} from 'gsap';
import "./VideoTitle.scss";
import 'locomotive-scroll/src/locomotive-scroll.scss';
import VisibilitySensor from "react-visibility-sensor";
import {moveToLink, SetScreenSize} from "../../../../utils/utils";
// import SloganImg from "../../../../../img/homeRenewal/01-Collins-slogan-mo:pc:ta.png"
// import SloganWebpImg from "../../../../../img/homeRenewal/01-Collins-slogan-mo_pc_ta.webp"

import SloganImg from "../../../../../img/homeRenewal/comminsoon.png"
import SloganWebpImg from "../../../../../img/homeRenewal/comminsoon.webp"
import SloganPcImg from "../../../../../img/homeRenewal/pc_comminsoon.png"
import SloganPcWebpImg from "../../../../../img/homeRenewal/pc_comminsoon.webp"

import ScrollIcon from "../../../../../img/homeRenewal/arrow.svg"
// import PcMainVideo from "../../../../../img/homeRenewal/collins_main_30s-16x9.mp4"
// import MoMainVideo from "../../../../../img/homeRenewal/collins_main_30s_9x16-low.mp4"
// import PcMainVideo from "../../../../../img/homeRenewal/mw-home-pc-black.mp4"
// import MoMainVideo from "../../../../../img/homeRenewal/mw-home-mo-black.mp4"
// import PcMainVideo from "../../../../../img/moodwater/moodwater-tasting-kit/moodwater_tasting_kit_pc.mp4"
// import MoMainVideo from "../../../../../img/moodwater/moodwater-tasting-kit/moodwater_tasting_kit_mo.mp4"
import PcMainVideo from "../../../../../img/homeRenewal/MoodSoap/web_home_top_desktop_clean_fast1080.mp4"
import MoMainVideo from "../../../../../img/homeRenewal/MoodSoap/web_home_top_mobile_clean_fast2160.mp4"
import MoMainImg from "../../../../../img/homeRenewal/collins_main_30s_9x16-low_000.jpg"
import PcMainImg from "../../../../../img/homeRenewal/collins_main_30s-16x9_000.jpg"

import {throttle} from "lodash";
import ArrowIcon from "../../../../../img/collinsday1969/arrow.svg";
import BlackArrowIcon from "../../../../../img/collinsday1969/arrow-copy@2x.png"
import * as animationData from "../../../../../img/homeRenewal/gift_lottie.json";
import Lottie from 'react-lottie';
import {useNavigate} from "react-router-dom";


interface IProps {
    device: "MB" | "TB" | "DT"
}

let checkCurrentTime;

function VideoTitleComponent(props: IProps) {
    const {device} = props

    const videoRef = useRef(null);
    const subVideoRef = useRef(null)
    const videoWrapperRef = useRef(null);
    const titleRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(0)
    const upperTextLineRef = useRef(null);
    const moodwaterTextLineRef = useRef(null)
    const moodwaterDescriptionTextLineRef = useRef(null)
    const navigate = useNavigate()
    const [targetVideo, setTargetVideo] = useState(0)
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const initLoad = () => {
        const tl = gsap.timeline();
        tl.to(upperTextLineRef.current, {
            opacity: 1,
            transform: 'none',
            transitionDuration: '0.8'
        })

    };
    // const initLoad = () => {
    //     const tl = gsap.timeline({immediateRender: true});
    //
    //     tl.to(upperTextLineRef.current, {
    //         opacity: 1,
    //         transform: 'none',
    //         transitionDuration: '0.8s',
    //         // transitionDelay: '0.3s',
    //     })
    //         // .to(lowerTextLineRef.current, {
    //         //     opacity: 1,
    //         //     transform: 'none',
    //         //     transitionDuration: '0.8s',
    //         //     // transitionDelay: '0.3s',
    //         // })
    //         .to(btnRef.current, {
    //             opacity: 1,
    //             transform: 'none',
    //             // transitionDuration: '1s',
    //             transitionDelay: '0s',
    //         }).to(subTitleRef.current, {
    //         opacity: 1,
    //         y: 0,
    //         transitionDuration: '1s',
    //         // transitionDelay: '0.3s',
    //     }).to(periodRef.current, {
    //             opacity: 1,
    //             y: 0,
    //             transitionDuration: '1s'
    //         });
    // };

    const lowerTextLineRef = useRef(null);
    const subTitleRef = useRef(null);
    const btnRef = useRef(null);
    const periodRef = useRef(null)


    useEffect(() => {
        // initLoad();
        checkVideoCurrentTime()
        SetScreenSize();
        window.addEventListener('resize', () => SetScreenSize(), {passive: true});
        window.addEventListener("scroll", currentScrollPosition, {passive: true});
        return () => {
            window.removeEventListener("scroll", currentScrollPosition);
            window.removeEventListener('resize', () => SetScreenSize());
        }
    }, []);

    const currentScrollPosition = throttle(() => {
        if (window.scrollY < window.innerHeight) {
            setScrollTop(window.scrollY)
        }
    }, 100);
    //
    useEffect(() => {
        getVideoSource()
    }, [device]);

    const checkVideoCurrentTime = () => {
        const video = videoRef.current;
        const subVideo = subVideoRef.current
        checkCurrentTime = setInterval(async () => {
            if (video.currentTime + 0.4 >= video.duration && subVideo.currentTime === 0) {
                subVideo.play();
                setTimeout(() => {
                    video.style.opacity = 0;
                    subVideo.style.opacity = 1;
                    video.pause()
                    video.currentTime = 0;
                    setTargetVideo(1)
                }, 200)
            } else if (subVideo.currentTime + 0.4 >= subVideo.duration && video.currentTime === 0) {
                video.play();
                setTimeout(() => {
                    subVideo.style.opacity = 0;
                    video.style.opacity = 1;
                    subVideo.pause();
                    subVideo.currentTime = 0;
                    setTargetVideo(0)
                }, 200)
            }
        }, 100)
    }


    const getVideoSource = () => {
        const video = videoRef.current;
        const subVideo = subVideoRef.current;
        const sources = video.getElementsByTagName('source');
        const sources2 = subVideo.getElementsByTagName('source');

        video.style.opacity = 0;
        subVideo.style.opacity = 0;
        if (device === "MB") {
            sources[0].src = MoMainVideo;
            sources2[0].src = MoMainVideo;
            video.load();
            subVideo.load();
        } else {
            sources[0].src = PcMainVideo;
            sources2[0].src = PcMainVideo;
            video.load();
            subVideo.load();
        }

        video.addEventListener('loadeddata', () => {
            video.style.opacity = 1
        })

        return () => {
            video.removeEventListener('loadeddata', () => {
                video.style.opacity = 1
            })
        }
    }

    return (
        <VisibilitySensor partialVisibility={true}
                          onChange={(isVisible) => {
                              if (videoRef.current.buffered.length > 0) {
                                  if (isVisible) {
                                      if(targetVideo ===0){
                                          videoRef.current.play()
                                      }else{
                                          subVideoRef.current.play()
                                      }
                                  } else {
                                      if(targetVideo ===0){
                                          videoRef.current.pause()
                                      }else{
                                          subVideoRef.current.pause()
                                      }
                                  }
                              }
                          }
                          }
        >
            <div ref={videoWrapperRef} className="full-video-section">
                <video ref={videoRef}
                       playsInline muted
                       autoPlay
                       loop
                       preload="metadata">
                    <source/>
                </video>
                <video ref={subVideoRef}
                       playsInline muted
                       preload="metadata">
                    <source/>
                </video>
                <div className='video-background'></div>
                {/*<div className="textWrapper"*/}
                {/*     style={{opacity: scrollTop > 0 ? window.innerHeight * 0.2 / scrollTop - 1 : 1}}>*/}
                {/*    <div className="mainTitle" ref={titleRef}>*/}
                {/*        <div className="textLine" ref={upperTextLineRef}>*/}
                {/*            <div className="event-img-wrapper">*/}
                {/*                <picture>*/}
                {/*                    <source*/}
                {/*                        srcSet={require("../../../../../img/homeRenewal/Moodwater/home_button_img.webp")}*/}
                {/*                        type={"image/webp"}/>*/}
                {/*                    <img src={require("../../../../../img/homeRenewal/Moodwater/home_button_img.png")}/>*/}
                {/*                </picture>*/}
                {/*            </div>*/}
                {/*            <div className="event-img-hover-wrapper" onClick={() => navigate('/collection/moodwater')}>*/}
                {/*                <picture>*/}
                {/*                    <source*/}
                {/*                        srcSet={require("../../../../../img/homeRenewal/Moodwater/home_button_hover_img.webp")}*/}
                {/*                        type={"image/webp"}/>*/}
                {/*                    <img*/}
                {/*                        src={require("../../../../../img/homeRenewal/Moodwater/home_button_hover_img.png")}/>*/}
                {/*                </picture>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*    /!*<div*!/*/}
                {/*    /!*    onClick={(e) => moveToLink('/collection/collinsday', e)}*!/*/}
                {/*    /!*    className="event-signup-button-wrapper"*!/*/}
                {/*    /!*    ref={btnRef}*!/*/}
                {/*    /!*>*!/*/}
                {/*    /!*    <img className='arrow-img' src={BlackArrowIcon}/>*!/*/}
                {/*    /!*    <div className='event-signup-text'>특가 제품 확인하기</div>*!/*/}
                {/*    /!*    <img className='arrow-img' src={BlackArrowIcon}/>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*    /!*<div className="mainSubTitle" ref={subTitleRef}>*!/*/}
                {/*    /!*    1년에 단 한 번, 특별한 혜택*!/*/}
                {/*    /!*</div>*!/*/}
                {/*    /!*<div className="orange-text" ref={periodRef}>7/20(목) - 7/24(월)</div>*!/*/}

                {/*    /!*<div*!/*/}
                {/*    /!*    onClick={(e) => moveToLink('/collection/fragrance', e)}*!/*/}
                {/*    /!*    className="incenseCollectionBtn"*!/*/}
                {/*    /!*    ref={btnRef}*!/*/}
                {/*    /!*>*!/*/}
                {/*    /!*    <div>*!/*/}
                {/*    /!*        콜린스 인센스*!/*/}
                {/*    /!*        /!* <SmallLinkRightArrowColorChagne color="#f6edde" /> *!/*!/*/}
                {/*    /!*    </div>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*    /!*<div className="mainTitle" ref={titleRef}>*!/*/}
                {/*    /!*    <div className="textLine" ref={upperTextLineRef}>*!/*/}
                {/*    /!*        <div>*!/*/}
                {/*    /!*            1년에 단 한 번 주어지는 혜택,*!/*/}
                {/*    /!*        </div>*!/*/}
                {/*    /!*        <div>*!/*/}
                {/*    /!*            콜린스 인센스 9,900원*!/*/}
                {/*    /!*        </div>*!/*/}

                {/*    /!*    </div>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*    /!*<div className="mainTitle" ref={titleRef}>*!/*/}
                {/*    /!*    <div className="textLine" ref={upperTextLineRef}>*!/*/}
                {/*    /!*        <div>*!/*/}
                {/*    /!*            7/20(목)-7/24(월)*!/*/}
                {/*    /!*        </div>*!/*/}
                {/*    /!*    </div>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*</div>*/}
                <div className="mood-water-button-wrapper" onClick={() => navigate('/collection/mood')}>
                    <div className="mood-water-button-lottie-wrapper">
                        <img src={require("../../../../../img/homeRenewal/MoodSoap/new2.gif")}/>
                    </div>
                    <div className="mood-water-button-text">
                        무드워터 & 무드솝
                    </div>
                </div>
                {/*<div className="scroll-icon-wrapper"*/}
                {/*     style={{opacity: scrollTop > 0 ? window.innerHeight * 0.2 / scrollTop - 1 : 1}}>*/}
                {/*    <img src={ScrollIcon}/>*/}
                {/*    <div className="scroll-icon-text">SCROLL</div>*/}
                {/*</div>*/}
            </div>
        </VisibilitySensor>
    );
}

export default VideoTitleComponent
