import {CartDataStore} from './CartDataStore';
import {CollectionPageStore} from './CollectionPageStore';
import {SpinnerStore} from './SpinnerStore';
import {BannerDataStore} from './BannerDataStore';
import {HomePageStore} from './HomePageStore';
import {UserDataStore} from "./UserDataStore";
import {ModalDataStore} from "./ModalDataStore"

export class RootStore {
    collectionPageStore;
    cartDataStore;
    spinnerStore;
    bannerDataStore;
    homePageStore;
    userDataStore;
    modalDataStore;

    constructor() {
        this.collectionPageStore = new CollectionPageStore();
        this.cartDataStore = new CartDataStore();
        this.spinnerStore = new SpinnerStore();
        this.bannerDataStore = new BannerDataStore();
        this.userDataStore = new UserDataStore();
        this.homePageStore = new HomePageStore();
        this.modalDataStore = new ModalDataStore();
    }
}
