import React, {useEffect} from 'react';
import './Modal.scss';
import CTAButton from '../CTAButton/CTAButton.component.jsx';
import {MidSmallBrownCloseIcon} from '../../../assets/CloseIcon';

const Modal = ({
                   text,
                   buttonText,
                   buttonText2,
                   buttonAction,
                   buttonAction2,
                   alignText,
                   oneButton,
                   oneButtonText,
                   oneButtonAction,
                   vertiCalButton,
                   withClose,
                   closeAction,
               }) => {
    const bgClickClose = (e) => {
        const target = e.target;
        if (target.classList[0] === 'modal-bg-position') {
            closeAction && closeAction();
            document.body.style.overflow = "auto"

        } else {
            return;
        }
    };

    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => document.body.style.overflow = "auto"
    }, [])


    return (
        <div
            className="modal-bg-position"
            onClick={(e) => {
                bgClickClose(e);
            }}
        >
            <div
                className={`modal-box ${vertiCalButton ? 'vertical' : ''}  ${
                    alignText === 'center' ? 'centered' : ''
                }`}
            >
                {/* <h1 className="modal-mainText">{mainText.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}</h1> */}
                {withClose && (
                    <div
                        className="close"
                        onClick={() => {
                            closeAction && closeAction();
                            document.body.style.overflow = "auto"

                        }}
                    >
                        <MidSmallBrownCloseIcon/>
                    </div>
                )}
                <p className={'modal-text '}>
          <span>
            {text.split('\n').map((line, i) => (
                <span key={i}>
                {line}
                    <br/>
              </span>
            ))}
          </span>
                </p>

                <div className={`modal-buttonWrapper ${vertiCalButton ? 'vertical' : ''}`}>
                    {oneButton ? (
                        <CTAButton
                            style={{
                                width: '100%',
                            }}
                            height="48px"
                            color="incenseCustomGrayBtn"
                            className="modal-button"
                            onClick={() => {
                                oneButtonAction();
                                document.body.style.overflow = "auto"
                            }

                            }
                        >
                            {oneButtonText}
                        </CTAButton>
                    ) : vertiCalButton ? (
                        <>
                            <CTAButton
                                color="incenseCustomBlackBtn"
                                className="modal-button-vertical"
                                height="48px"
                                onClick={() => {
                                    buttonAction()
                                    document.body.style.overflow = "auto"
                                }}
                            >
                                {buttonText}
                            </CTAButton>
                            {buttonText2 && (
                                <CTAButton
                                    className="modal-button-vertical"
                                    color="incenseCustomGrayBtn"
                                    height="48px"
                                    onClick={() => {
                                        buttonAction2()
                                        document.body.style.overflow = "auto"
                                    }}
                                >
                                    {buttonText2}
                                </CTAButton>
                            )}
                        </>
                    ) : (
                        <>
                            <CTAButton
                                className="modal-button"
                                color="incenseCustomGrayBtn"
                                height="48px"
                                onClick={() => {
                                    buttonAction()
                                    document.body.style.overflow = "auto"
                                }}
                            >
                                {buttonText}
                            </CTAButton>
                            {buttonText2 && (
                                <CTAButton
                                    color="incenseCustomBlackBtn"
                                    className="modal-button"
                                    height="48px"
                                    onClick={() => {
                                        buttonAction2()
                                        document.body.style.overflow = "auto"
                                    }}
                                >
                                    {buttonText2}
                                </CTAButton>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;
