import React, {useEffect, useRef, useState} from 'react';
//import { moveScrollToId } from '../../utils/utils';
import {Swiper, SwiperSlide} from 'swiper/react';
import {PreveSlideArrow, NextSlideArrow} from '../../../assets/Arrows';
import {breakpointDt} from '../../constants/constants';
import './NavigationTab.scss';
import PrevButtonIcon from "../../../img/incenseHome/incense-prev-button.svg"
import NextButtonIcon from "../../../img/incenseHome/incense-next-button.svg"

// 생각 해보자, 이 컴포넌트는 재사용 가능 해야 함
// 첫째 줄은, 스테이트에 따라서 위치를 다르게 표현한다.
// 핵심은 스테이트에 따라서 위치를 다르게 표현하는 것
// 스테이트가 1이면 1지점으로, 2이면 2지점으로 3이면 3지점으로 이동하면 됨
// 각탭을 클릭했을때 취해야 될 액션은 탭 클릭 액션 이라는 걸 만들어서 props로 받는다.
// 1. 아래 줄의 위치가 변한다, tap click 액션을 수행한다.

const NavigationTab = ({tabInfo, isVisibleInDt, defaultFocus, pageInfo, isIncense = 'false'}) => {
    const [currentPosition, setCurrentPosition] = useState(defaultFocus ? defaultFocus : 0);
    const [showNavHandle, setShowNavHandle] = useState(false);
    const swiperWrapperRef = useRef(null);

    const [swiper, setSwiper] = useState(null);

    const positionCheck = (tab, i) => {
        const tabPosition = document.getElementById(tab?.targetDomId)?.offsetTop;
        if (!tabPosition) {
            return false;
        }

        if (pageInfo.altScrollParent?.className === "content-area scroll") {
            if (pageInfo.altScrollParent.scrollTop < tabPosition - 146) {
                setCurrentPosition(i === 0 ? 0 : i - 1);
                return true;
            } else if (i === tabInfo.length - 1) {
                setCurrentPosition(i);
                return true;
            }
        } else {
            if (pageInfo.scrollPosition() < tabPosition - 146) {
                setCurrentPosition(i === 0 ? 0 : i - 1);
                return true;
            } else if (i === tabInfo.length - 1) {
                setCurrentPosition(i);
                return true;
            }
        }
        return false
    };

    const moveNavigation = (tab, i) => {
        let target = document.getElementById(tab.targetDomId);
        if (target) {
            const targetOffset = target.offsetTop - 120;
            //미니 카트에 스크롤 발생 시 조건문
            if (pageInfo.altScrollParent?.className === "content-area scroll") {
                pageInfo.altScrollParent.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: targetOffset,
                })
            } else {
                pageInfo.scrollParent.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: targetOffset,
                });
            }
        }
        // console.log(!positionCheck(tab, i))
        // console.log(tab, i)
        // setTimeout(() => {
        //     if (positionCheck(tab, i)) {
        //         moveNavigation(tab, i)
        //     }
        // }, 500)
        // if (positionCheck(tab, i) === false) {
        //     console.log(tab, i)
        //     setTimeout(() => moveNavigation(tab, i), 200)
        // }
    }


    const scrollHighlightHandler = () => {
        // 스크롤 위치에 따른 탭 이동
        tabInfo.some(positionCheck);
    };
    //
    // if (pageInfo && pageInfo.scrollParent) {
    //     pageInfo.scrollParent.addEventListener('scroll', scrollHighlightHandler);
    //     pageInfo.altScrollParent.addEventListener('scroll', scrollHighlightHandler);
    // }

    useEffect(() => {
        if (tabInfo[0].moveWhenClicked) {
            window.addEventListener('load', () => {
                scrollHighlightHandler();
            });
        }
        if (pageInfo && pageInfo.scrollParent) {
            pageInfo.scrollParent.addEventListener('scroll', scrollHighlightHandler);
            pageInfo.altScrollParent.addEventListener('scroll', scrollHighlightHandler);
        }
        // pageInfo.scrollParent.addEventListener('scroll', scrollHighlightHandler);

        // return ()=>{
        //   if(scrollHighlightHandler){
        //     pageInfo.scrollParent.removeEventListener('scroll', scrollHighlightHandler);
        //   }
        return () => {
            if (pageInfo && pageInfo.scrollParent) {
                pageInfo.scrollParent.removeEventListener('scroll', scrollHighlightHandler);
                pageInfo.altScrollParent.removeEventListener('scroll', scrollHighlightHandler);
            }
        }
        // };
    }, [pageInfo]);

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(currentPosition);
        }
    }, [currentPosition]);

    useEffect(() => {
        if (defaultFocus !== 0) {
            setCurrentPosition(defaultFocus)
        }
    }, [defaultFocus])


    useEffect(() => {
        if (swiper && swiperWrapperRef.current) {
            const resizeHandler = () => {
                const currentWidth = window.innerWidth;
                const swiperSize = swiperWrapperRef.current.scrollWidth;
                if (currentWidth >= breakpointDt && swiperSize >= currentWidth / 2) {
                    setShowNavHandle(true);
                } else if (currentWidth >= breakpointDt && swiperSize < currentWidth / 2) {
                    setShowNavHandle(false);
                }
            };

            setTimeout(resizeHandler, 1200);

            window.addEventListener('resize', resizeHandler);

            return () => {
                window.removeEventListener('resize', resizeHandler);
            };
        }
    }, [swiper]);

    return (
        <div className={`navigation-wrapper  ${isVisibleInDt ? 'visible-dt' : ''}`}>
            {!isIncense ? (
                <>
                    <div className="control-wrapper">
                        {tabInfo.map((tab, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`navigation-control  ${currentPosition == i && 'active'}`}
                                    onClick={() => {
                                        if (tab.moveWhenClicked) {
                                            //moveScrollToId(tab.targetDomId);

                                            let target = document.getElementById(tab.targetDomId);

                                            if (target) {
                                                const targetOffset = target.offsetTop - 120;

                                                pageInfo.scrollParent.scroll({
                                                    behavior: 'smooth',
                                                    left: 0,
                                                    top: targetOffset,
                                                });
                                            }
                                        }
                                        if (tab.tabAction) {
                                            tab.tabAction();
                                            setCurrentPosition(i);
                                        }
                                    }}
                                >
                                    {tab.tabName}
                                </div>
                            );
                        })}
                    </div>
                    <div className="bar">
                        <div
                            className="bar-current"
                            style={{
                                transform: `translate(${currentPosition * 100}%, 0)`,
                                width: `${100 / tabInfo.length}%`,
                            }}
                        ></div>
                    </div>
                </>
            ) : (
                <div className="control-wrapper incensePage">
                    <Swiper
                        ref={swiperWrapperRef}
                        // spaceBetween={24}
                        slidesPerView={'auto'}
                        watchSlidesVisibility={true}
                        watchSlidesProgress={true}
                        slideToClickedSlide={true}
                        preventClicks={true}
                        // objectsForThumbNailPagination={tabInfo}
                        breakpoints={{
                            1200: {
                                // spaceBetween: 56,
                                navigation: {
                                    nextEl: '.swiper-button-next-content.incenseProductPage',
                                    prevEl: '.swiper-button-prev-content.incenseProductPage',
                                },
                            },
                        }}
                        // passiveListeners={true}
                        // onSwiper={(swiper) => swiper.slideTo(currentPosition)}
                        // observer={true}
                        // observeParents={true}
                        onSwiper={(swiper) => setSwiper(swiper)}
                    >
                        {tabInfo.map((tab, i) => {
                            return (
                                <SwiperSlide style={{width: 'fit-content'}} key={i} threshold={10}>
                                    <div
                                        key={i}
                                        className={`navigation-control  ${currentPosition == i && 'active'} incenseTab`}
                                        onClick={() => {
                                            if (tab.moveWhenClicked) {
                                                moveNavigation(tab, i)
                                                // let target = document.getElementById(tab.targetDomId);
                                                // if (target) {
                                                //     const targetOffset = target.offsetTop - 120;
                                                //     //미니 카트에 스크롤 발생 시 조건문
                                                //     if (pageInfo.altScrollParent?.className === "content-area scroll") {
                                                //         pageInfo.altScrollParent.scroll({
                                                //             behavior: 'smooth',
                                                //             left: 0,
                                                //             top: targetOffset,
                                                //         })
                                                //     } else {
                                                //         pageInfo.scrollParent.scroll({
                                                //             behavior: 'smooth',
                                                //             left: 0,
                                                //             top: targetOffset,
                                                //         });
                                                //     }
                                                //
                                                // }
                                            }
                                            if (tab.tabAction) {
                                                tab.tabAction();
                                                setCurrentPosition(i);
                                            }
                                        }}
                                    >
                                        {tab.tabName}
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                        <div className="bar">
                            <div
                                className="bar-current"
                                style={{
                                    transform: `translate(${currentPosition * 100}%, 0)`,
                                    width: 'fit-content',
                                }}
                            ></div>
                        </div>
                    </Swiper>
                    <div
                        className={`swiper-button-prev-content incenseProductPage ${
                            showNavHandle ? 'showHandle' : ''
                        }`}
                    >
                        <img src={PrevButtonIcon}/>
                    </div>
                    <div
                        className={`swiper-button-next-content incenseProductPage ${
                            showNavHandle ? 'showHandle' : ''
                        }`}
                    >
                        <img src={NextButtonIcon}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavigationTab;
