

import React from 'react';
import './CTAButton.scss';


const CTAButton = ({ 
  color, 
  width = '100%', 
  children, 
  height = '',
  font, 
  ...otherProps }) => {

    let colorClass = "default";
    if(color === 'green-cta') {
      colorClass = 'green-cta';
    } else if(color === 'brown') {
      colorClass = 'brown-cta';
    } else if(color === 'darkBrown') {
      colorClass = 'darkBrown-cta';
    } else if(color) {
      colorClass = color;
    }

  return (
    <div {...otherProps}>
      <button className={`cta-btn ${colorClass} `} style={{ width: width, display: 'inline', height: height} }>
        
        {children}
      </button>

    </div>

  );
};

export default CTAButton;