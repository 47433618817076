import React, {useEffect, useState} from 'react';
import './ImageSlider.scss';

import SwiperCore, {Navigation, Pagination, A11y, Lazy} from 'swiper/core';
import {Swiper, SwiperSlide} from 'swiper/react';
// import 'swiper/swiper.scss';
import {breakpointDt} from '../../constants/constants';
import PreloadImage from 'react-preload-image';
import LazyImage from "../../hooks/LazyImage";

SwiperCore.use([Navigation, Pagination, A11y, Lazy]);

const ImageSlider = ({
                         data,
                         sliderHeight,
                         isImageThumbnail,
                         alwaysSlide,
                         isHoverCursorEnabled
                     }) => {

    // 사진 썸네일을 쓰고자 하는 경우,
    // swiper-pagination-bullet을 다 잡는다.
    // data로 들어온 이미지를 인덱스 별로 연결한다.
    // 스타일은 그거에 맞춰서 준다.

    const [slideThreshold, setSlideThreshold] = useState(window.innerWidth > breakpointDt ? 100 : 0);
    useEffect(() => {

        if (isImageThumbnail) {
            const bullets = document.querySelectorAll('.pagination-main-detail-images .swiper-pagination-bullet');

            bullets.forEach((bullet, i) => {
                bullet.classList.add('imageThumbnail');
                let targetFormat;

                Array.from(data)[i][1].forEach((value, key) => {
                    if (key !== 'webp' && key !== "type") {
                        targetFormat = key;
                    }
                })
                bullet.innerHTML = Array.from(data)[i][1].has('webp') ? `
                            <picture class="pagination-dt">
                               <source srcSet=${Array.from(data)[i][1].get('webp')} type="image/webp"/>
                                <img src=${Array.from(data)[i][1].get(targetFormat)}
                                     loading='lazy'
                                      style="width: 64px; height: 64px; object-fit:cover; display:flex;"/>
                            </picture>
            ` : ` <picture class="pagination-dt">
                    <img src=${Array.from(data)[i][1].get(targetFormat)}
                      loading='lazy'
                    style="width: 64px; height: 64px; object-fit:cover; display:flex;"/>
                </picture>`;
            });
        }

    }, [data]);

    return (

        <div className="imgSlider" style={{width: '100%', height: '100%'}}>
            <Swiper
                lazy={true}
                preloadImages={true}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                    el: '.pagination-main-detail-images',
                    type: 'bullets',
                    clickable: true
                }}
                loop={true}
                threshold={alwaysSlide ? 0 : slideThreshold}
                style={{width: '100%', height: '100%'}}
                navigation={isHoverCursorEnabled ?
                    {
                        nextEl: '.hoverCursorNext',
                        prevEl: '.hoverCursorPrev',

                    } :
                    false
                }


            >


                {Array.from(data).map((img, i) => {
                    let targetFormat;

                    img[1].forEach((value, key) => {
                        if (key !== 'webp' && key !== "type") {
                            targetFormat = key;
                        }
                    })
                    return (
                        // cover 인지 contain인지에 따라 cover 클래스를 다르게 주어야 함
                        <SwiperSlide key={i}>
                            {/*<div className={`imgSliderContent ${img.type === 'cover' ? 'cover' : ''} swiper-lazy`}*/}
                            {/*     loading={'lazy'}*/}
                            {/*     style={{*/}
                            {/*         height: sliderHeight,*/}
                            {/*         backgroundPosition: 'center',*/}
                            {/*         backgroundRepeat: 'no-repeat',*/}
                            {/*         backgroundImage: `url(${img.src})`*/}
                            {/*     }}>*/}

                            <picture>
                                {img[1].has('webp') && <source type={'image/webp'} srcSet={`${img[1].get('webp')}`}/>}
                                <img src={`${img[1].get(targetFormat)}`}
                                     loading='lazy'
                                     className={`imgSliderContent ${img[1].get('type') === 'cover' ? 'cover' : ''}`}
                                     style={{
                                         height: sliderHeight,
                                         width: "100%",
                                         objectFit: img[1].get('type')
                                     }}/>
                            </picture>
                            {/*<LazyImage className={`imgSliderContent ${img.type === 'cover' ? 'cover' : ''}`}*/}
                            {/*           style={{height: sliderHeight, width: "100%", objectFit: "cover"}}*/}
                            {/*           imgUrl={img.src}/>*/}
                            {/*<div className="swiper-lazy-preloader swiper-lazy-preloader-collins"></div>*/}
                            {/*</div>*/}
                        </SwiperSlide>
                    );
                })}

                {isHoverCursorEnabled &&
                    <>
                        <div className="hoverCursorPrev"
                             style={{
                                 cursor: 'url(/img/arrow_prev_b_72.svg)'
                             }}
                        ></div>
                        <div className="hoverCursorNext"
                             style={{
                                 cursor: 'url(/img/arrow_prev_b_72.svg)'
                             }}
                        ></div>
                    </>
                }
                <div className="pagination-main-detail-images">

                </div>
            </Swiper>

        </div>


    );
};

export default ImageSlider;
