import { makeAutoObservable } from 'mobx';




export class SpinnerStore {

  
  isSpinnerShow = false;
  

  constructor() {
    makeAutoObservable(this);
  }

  setIsSpinnerShow = (state :boolean) =>{
    
    this.isSpinnerShow = state;

  }

  
}
