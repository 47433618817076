import React, {Suspense, useEffect, useMemo, useState, lazy} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import './css/main.css';
import {AppContext} from "./main/context/AppContext";
import {fetchAndReceiveJSON} from "./main/utils/utils";
import {apiUrl} from "./main/constants/constants";
import SpinnerControl from './main/components/SpinnerControl/SpinnerControl';
import {observer} from "mobx-react";
import ScrollToTop from "./main/utils/ScrollToTop";
import ModalPage from "./main/pages/ModalPage";
import {VideoRedirectPage} from "./main/pages/VideoRedirectPage";
import HomePage from './main/pages/HomePage';
import CollectionPage from './main/pages/CollectionPage';
import ProductPage from './main/pages/ProductPage';

const CollinsStory = lazy(() => import("./main/pages/CollinsStory"))
const SignUpPage = lazy(() => import("./main/pages/SignUpPage"))
const LogInPage = lazy(() => import("./main/pages/LogInPage"))
const LoginForm = lazy(() => import("./main/pages/LoginForm"))
const SignUpForm = lazy(() => import("./main/pages/SignUpForm"))
const WelcomePage = lazy(() => import("./main/pages/WelcomePage"))
const AccountPage = lazy(() => import("./main/pages/AccountPage"))
const CartPage = lazy(() => import("./main/pages/CartPage"))
const ShippingForm = lazy(() => import("./main/pages/ShippingForm"))
const BasicInfoForm = lazy(() => import("./main/pages/BasicInfoForm"))
const PasswordForm = lazy(() => import("./main/pages/PasswordForm"))
const OrderCompletePage = lazy(() => import("./main/pages/OrderCompletePage"))
const CollinsPlace = lazy(() => import("./main/pages/CollinsPlace"))
const CollinsMusic = lazy(() => import("./main/pages/CollinsMusic"))
const MoodWaterTeaserPage = lazy(() => import("./main/pages/MoodWaterTeaserPage"))
const Collinsday1969 = lazy(() => import("./main/pages/Collinsday1969"))
const ErrorPage = lazy(() => import("./main/pages/ErrorPage"))
const ServicePage = lazy(() => import("./main/pages/UnderMaintenancePage"))
const BrandArchiveListPage = lazy(() => import("./main/pages/BrandArchiveListPage"))
const BrandArchivePage = lazy(() => import("./main/pages/BrandArchivePage"))
const NameForm = lazy(() => import("./main/pages/NameForm"))
const Terms = lazy(() => import("./main/components/Terms/Terms"))


const App = observer(() => {
    const {userDataStore, spinnerStore} = React.useContext(AppContext);
    const {setUserData, setIsLogin, isLogin, user} = userDataStore;
    const [cartQuantity, setCartQuantity] = useState(0);
    const {isSpinnerShow, setIsSpinnerShow} = spinnerStore;


    const HiddenMenuComponent = useMemo(
        () => React.lazy(() => import('./main/components/HiddenMenu/HiddenMenu.component')),
        []
    );

    const MoodwaterRedirectMoodCollection = () => {
        if (window.location.pathname === "/collection/moodwater") {
            window.location.href = "/collection/mood"
        }
    }

    const KakaoLiveShowRedirect = () => {
        const currentTime = new Date()
        const targetTime = new Date(2024, 7, 17, 21, 30,0)
        if (window.location.pathname === "/kko-20240816-sms") {
            if (targetTime < currentTime) {
                window.location.href = "https://gift-talk.kakao.com/appredirect?to=https%3A%2F%2Fgift.kakao.com%2Fbrand%2F13236&input_channel_id=2048"
            } else {
                window.location.href = "https://kko.to/yFcNq0lXda"

            }
        } else if (window.location.pathname === "/kko-20240816-kko") {
            if (targetTime < currentTime) {
                window.location.href = "https://gift-talk.kakao.com/appredirect?to=https%3A%2F%2Fgift.kakao.com%2Fbrand%2F13236&input_channel_id=2048"
            } else {
                window.location.href = 'https://shoppinglive.kakao.com/live/29093?ref=brand_live&ad=talkchannel'
            }
        }
    }

    const callCartCount = async () => {
        const res = await fetch(apiUrl + '/api/cart/count', {credentials: 'include'});
        if (res.status !== 200) {
            alert('서버와의 통신에 실패했습니다. 잠시후 다시 시도해 주세요');
            return;
        }
        const data = await res.json();
        setCartQuantity(data);
    };

    useEffect(() => {
        MoodwaterRedirectMoodCollection()
        KakaoLiveShowRedirect()
        if (window.location.pathname.includes('/ytvid/')) {
            const id = window.location.pathname.split('/ytvid/')[1]
            VideoRedirectPage(id)
        } else {
            autoLogin()
            callCartCount()
        }
    }, [])


    const autoLogin = async () => {
        if (!isLogin && user === null) {
            setIsSpinnerShow(true);
            await fetchAndReceiveJSON({
                url: apiUrl + '/api/my/info',
                method: 'GET',
                body: null
            }).then(res => {
                const data = {
                    id: res.id,
                    firstName: res.first_name,
                    email: res.email,
                    lastName: res.last_name,
                    username: res.username,
                    billing: res.billing,
                    shipping: res.shipping,
                    isKakao: res.meta_data.some(item => item.key === "kakao_id")
                }
                if (res.id) {
                    setUserData(data);
                    setIsLogin(true);
                    setIsSpinnerShow(false);
                } else {
                    if (window.location.pathname.includes("account")) {
                        window.location.href = '/'
                    }

                }
            }).catch(err => {
                setUserData(null);
                setIsLogin(false);
                setIsSpinnerShow(false);
                if (window.location.pathname.includes("account")) {
                    window.location.href = '/'
                }
            });
            setIsSpinnerShow(false);
        }
    }


    return (
        <SpinnerControl isSpinnerShow={isSpinnerShow}>
            <BrowserRouter>
                <Suspense fallback={<div/>}>
                    <Terms/>
                </Suspense>
                <ScrollToTop/>

                <Suspense fallback={<div style={{minHeight: '100vh', width: "100%"}}/>}>
                    <Routes>
                        <Route path={'/'} element={<HomePage/>}/>
                        <Route path={'/*'} element={<Navigate to={"/error"}></Navigate>}/>
                        <Route path={'/collection/:categoryName'} element={<CollectionPage/>}/>
                        <Route path={'/product/:productName'} element={<ProductPage/>}/>
                        <Route path={'/brandstory'} element={<CollinsStory/>}/>
                        <Route path={'/signup/menu'} element={<SignUpPage/>}/>
                        <Route path={'/login/menu'} element={<LogInPage/>}/>
                        <Route path={'/login/form'} element={<LoginForm/>}/>
                        <Route path={'/signup/form'} element={<SignUpForm/>}/>
                        <Route path={'/welcome'} element={<WelcomePage/>}/>
                        <Route path={'/account'} element={<AccountPage/>}/>
                        <Route path={'/account/&kakao_login=auto'} element={<AccountPage/>}/>
                        <Route path={'/cart'} element={<CartPage/>}/>
                        <Route path={'/account/shipping/form'} element={<ShippingForm/>}/>
                        <Route path={'/account/basicinfo/form'} element={<BasicInfoForm/>}/>
                        <Route path={'/name/form'} element={<NameForm/>}/>
                        <Route path={'/account/password/form'} element={<PasswordForm/>}/>
                        <Route path={'/order/complete'} element={<OrderCompletePage/>}/>
                        <Route path={'/places'} element={<CollinsPlace/>}/>
                        <Route path={'/music'} element={<CollinsMusic/>}/>
                        <Route path={'/collinsday1969'} element={<Collinsday1969/>}/>
                        <Route path={'/makersnote/preview/:boardId'} element={<BrandArchivePage/>}/>
                        <Route path={'/makersnote/:slug'} element={<BrandArchivePage/>}/>
                        <Route path={'/makersnote'} element={<BrandArchiveListPage/>}/>
                        <Route path={'/moodwater-teaser'} element={<MoodWaterTeaserPage/>}/>
                        <Route path={'/error'} element={<ErrorPage/>}/>
                        <Route path={'/maintenance'} element={<ServicePage/>}/>
                        <Route path={'/ytvid/:id'} element={<div/>}/>
                        <Route path={'/kko-20240816-sms'} element={<div/>}/>
                        <Route path={'/kko-20240816-kko'} element={<div/>}/>


                    </Routes>
                </Suspense>
                <Suspense fallback={<div style={{minHeight: '100vh'}}></div>}>
                    <HiddenMenuComponent
                        cartQuantity={cartQuantity}
                        callCartCount={callCartCount}
                        setIsSpinnerShow={setIsSpinnerShow}
                    />
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <ModalPage/>
                </Suspense>
            </BrowserRouter>
        </SpinnerControl>

    );
})

export default App;
