import React, {FunctionComponent, useEffect, useState} from 'react';
import {moveToLink, stringLineBreak} from '../../utils/utils';
import ReactStars from 'react-rating-stars-component';
import './CollectionItemMoodwater.scss';
import {HalfStar, SmallEmptyStar, SmallFilledStar, SmallHalfStar,} from '../../../assets/Star';
import {productDataType} from '../../@types/types';
import PreloadImage from 'react-preload-image';
import {getRedirectlink} from '../../utils/collectionUtils.js';
import KakaoGiftModal from "../KakaoGiftModal/KakaoGiftModal.component";
import {kakaoGiftList} from "../../constants/constants";
import LazyImage from "../../hooks/LazyImage";
import SmallLinkRightArrow from "../../../img/small-link-arrow.svg"
import CartIcon from "../../../img/cart-icon-collection.svg"
import StarIcon from "../../../img/badge/star.svg"
import CartFillIcon from "../../../img/cart-icon-collection-fill.svg"
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../context/AppContext";

const CollectionItemMoodwater: FunctionComponent<{
    mainThumbnail;
    subThumbnail;
    event: string;
    subName: string;
    name: string;
    onSale: boolean;
    score: string;
    reviewCount: number;
    discountRate: string | number;
    regularPrice: string;
    discountPrice: string;
    link: string;
    setDataForCart?: CallableFunction;
    setIsCartPopUp?: CallableFunction;
    setIsKakaoGiftPopup?: CallableFunction;
    itemData?: productDataType;
    setImageDataForCart?: CallableFunction;

    // 구매 기능을 위한 부가 props
    // isSimple : boolean,
    // options : Options,
    // thumbnails : Array<string>
}> = ({
          mainThumbnail,
          subThumbnail,
          event,
          subName,
          name,
          onSale,
          score,
          reviewCount,
          discountRate,
          regularPrice,
          discountPrice,
          link,
          setDataForCart,
          setIsCartPopUp,
          itemData,
          setImageDataForCart,
          setIsKakaoGiftPopup
      }) => {
    const {modalDataStore} = React.useContext(AppContext);
    const {setIsRestockingModalProductId, setIsRestockingModalVisible} = modalDataStore
    link = getRedirectlink(link);

    const navigate = useNavigate()
    return (
        <div className="collectionMoodwaterItemWrapper">
            <div className="border-brush-horizontal"></div>
            <div style={{display: "flex"}}>
                <div className="border-brush-vertical"></div>
                <div
                    className="thumbnailArea"
                    onClick={(e) => {
                        // alert(link);
                        if (e) {
                            if (e.metaKey || e.ctrlKey) {
                                window.open(link);
                                return;
                            }
                            // else window.location = url;
                        }
                        navigate(link);
                    }}
                >
                    {itemData.stock_status === "outofstock" && <div className={'outOfStockArea'}>
                        <div className={'outOfStock-text'}>sold out</div>
                    </div>}
                    {/* 이미지 두장을 넣어서 hover하면 한개 없어지게 하자 */}
                    <picture>
                        {mainThumbnail &&
                            <source type={'image/webp'} srcSet={`${mainThumbnail}`}/>}
                        <LazyImage imgUrl={mainThumbnail}
                            // loading='lazy'
                                   className={`thumbnail1`}/>
                    </picture>
                    {/*<picture>*/}
                    <picture>
                        {subThumbnail &&
                            <source type={'image/webp'} srcSet={subThumbnail}/>}
                        <LazyImage imgUrl={subThumbnail}
                            // loading='lazy'
                                   className={`thumbnail2`}/>
                    </picture>
                    {/*  */}
                    {/*</picture>*/}
                    {/*<picture>*/}
                    {/*    <img className="thumbnail1" imgUrl={mainThumbnail}/>*/}
                    {/*</picture>*/}
                    {/*<PreloadImage*/}
                    {/*    style={{*/}
                    {/*        paddingTop: '100%',*/}
                    {/*    }}*/}
                    {/*    className="thumbnail1"*/}
                    {/*    src={mainThumbnail}*/}
                    {/*    alt=""*/}
                    {/*    // lazy*/}
                    {/*/>*/}
                    {/* <img className="thumbnail1" src={ mainThumbnail } alt=""/> */}
                    {/* <img className="thumbnailPlace" src={ mainThumbnail } alt=""/> */}
                    {/*<LazyImage className="thumbnail2" imgUrl={subThumbnail}/>*/}
                    {discountRate === 100 && <div className="discountFreeBadge"><img src={StarIcon}/>
                        <div className="discountFreeText">FREE</div>
                    </div>}
                    {discountRate != 0 && discountRate !== 100 && <div className="discountBadge">{discountRate}%</div>}
                    {event && event.length > 0 && <div className="eventBadge">{event}</div>}
                </div>
                <div className="border-brush-vertical"></div>
            </div>
            <div className="border-brush-horizontal"></div>
            <div className="infoArea">
                <div className="subName">{subName}</div>
                <div className="name">{stringLineBreak(name)}</div>
                <div className="priceArea">
                    {onSale ? (
                        <>
                            <div className="regularPrice crosslined">{regularPrice}</div>
                            <div className="discountPrice">{discountPrice}</div>
                        </>
                    ) : (
                        <div className="regularPrice">{regularPrice}</div>
                    )}
                </div>
                <div className="bottomArea">
                    <div className="reviewInfo">
                        <div className="ratingStars">
                            <ReactStars
                                a11y={true}
                                count={5}
                                value={Math.round(Number(score) * 2) / 2}
                                edit={false}
                                // onChange={ratingChanged}
                                size={10}
                                isHalf={true}
                                emptyIcon={
                                    <div style={{marginRight: '4px'}}>
                                        {' '}
                                        <SmallEmptyStar/>
                                    </div>
                                }
                                halfIcon={
                                    <div style={{marginRight: '4px'}}>
                                        {' '}
                                        <SmallHalfStar/>{' '}
                                    </div>
                                }
                                filledIcon={
                                    <div style={{marginRight: '4px'}}>
                                        {' '}
                                        <SmallFilledStar/>{' '}
                                    </div>
                                }
                            />
                        </div>
                        <div
                            className="reviewCount"
                            onClick={(e) => {
                                if (e) {
                                    if (e.metaKey || e.ctrlKey) {
                                        window.open(`${link}#review`);
                                        return;
                                    }
                                    // else window.location = url;
                                }
                                navigate(`${link}#review`);
                            }}
                        >
                            리뷰 {reviewCount}
                            <img src={SmallLinkRightArrow} alt=""/>
                        </div>
                    </div>
                    <img
                        src={CartIcon}
                        onClick={(event) => {
                            // 단순 상푸 구분 로직 넣어야 함
                            if (kakaoGiftList.get(itemData.slug)) {
                                const productForMinicart = itemData.parent_product ? itemData.parent_product : itemData;
                                setDataForCart({
                                    product: productForMinicart
                                });
                                setIsKakaoGiftPopup(true)
                            } else {
                                const productForMinicart = itemData.parent_product ? itemData.parent_product : itemData;
                                if (productForMinicart.type === 'simple' && itemData.stock_status === "outofstock") {
                                    setIsRestockingModalVisible(true);
                                    setIsRestockingModalProductId(itemData.id);
                                    event.stopPropagation();
                                    return;
                                }
                                setImageDataForCart(productForMinicart.images);
                                let optionsForMinicart = null;
                                if (productForMinicart.type !== 'simple') {
                                    optionsForMinicart = itemData.product_variations;
                                    setIsCartPopUp(true);
                                }
                                setDataForCart({
                                    product: productForMinicart,
                                    optionProducts: optionsForMinicart,
                                });
                            }
                        }}
                        className="cartPopUpIcon1"
                    />
                    <img src={CartFillIcon} className="cartPopUpIcon2"/>
                </div>
            </div>
        </div>
    );
};

export default CollectionItemMoodwater;
