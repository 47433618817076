import {observer} from 'mobx-react';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {AppContext} from '../../context/AppContext';
import DropdownCloseIcon from "../../../img/dropdownclose.svg"
import DropdownOpenIcon from "../../../img/dropdownopen.svg"
import {collectionMeta} from '../../@types/types';
import './CollectionSelector.scss';
import {toJS} from 'mobx';
import {useNavigate} from "react-router-dom";

const CollectionSelectorTop: FunctionComponent = observer(() => {
    const [isOpen, setIsOpen] = useState<boolean>();
    const {collectionPageStore} = React.useContext(AppContext);
    const navigate = useNavigate()

    const removeFocusOnElements = (collectionSelectors) => {
        collectionSelectors.forEach((selector) => {
            selector.classList.remove('focus');
        });
    };

    useEffect(() => {

        const collectionSelectors = document.querySelectorAll('.collectionSelect');
        collectionSelectors.forEach((selector) => {
            selector.addEventListener('mouseover', (e) => {
                removeFocusOnElements(collectionSelectors);
                e.target.classList.add('focus');
            });
        });

        // const addCloseEventOnBody = (e) =>{

        //   if(e.target.classList[0] === 'curCollection'
        //   || e.target.classList[0] === 'dropdownlogo'
        //   // || e.target.classList[0] === 'collectionSelect'
        //   ){
        //     return;
        //   }

        //   // setIsOpen(false);
        // };
        // // if(document.body){
        // //   document.body.addEventListener('click', addCloseEventOnBody);
        // // }

        // return ()=>{
        //   document.body.removeEventListener('click', addCloseEventOnBody);

        // };
    }, [collectionPageStore.allCollections]);

    // 닫힐 때 마다 포커싱 되는 컴포넌트를 현재 focus Component로 바꾸는 effect
    // useEffect(() =>{

    //   const collectionSelectors = document.querySelectorAll('.collectionSelect');
    //   removeFocusOnElements(collectionSelectors);
    //   collectionSelectors.forEach( selector => {
    //     if(selector.id == collectionPageStore.collectionNow.id){
    //       selector.classList.add('focus');
    //     }
    //   });
    // }, [isOpen, collectionPageStore.collectionNow] );

    return (
        <div
            className="collectionSelectorWrapper"
            onClick={() => {
                setIsOpen(!isOpen);
                const collectionBackgroundWrapper = document.querySelector('.collectionHeadComponent');
                if (isOpen) {
                    collectionBackgroundWrapper.classList.remove('expand');
                } else {
                    collectionBackgroundWrapper.classList.add('expand')
                }
            }}
        >
            <div
                className="curCollection"
                style={{
                    opacity: collectionPageStore.collectionNow.title
                        ? 1
                        : collectionPageStore.defaultCollectionTitle.length === 0
                            ? 0
                            : 1,
                    transform: collectionPageStore.collectionNow.title
                        ? 'unset'
                        : collectionPageStore.defaultCollectionTitle.length === 0
                            ? 'translateY(20px)'
                            : 'unset',
                    transition: 'opacity 800ms 500ms, transform 800ms 500ms',
                }}
            >
                {collectionPageStore.collectionNow.title
                    ? collectionPageStore.collectionNow.title
                    : collectionPageStore.defaultCollectionTitle}
                {isOpen ? (
                    <img className="dropdownlogo" src={DropdownCloseIcon} alt=""/>
                ) : (
                    <img className="dropdownlogo" src={DropdownOpenIcon} alt=""/>
                )}
            </div>

            <div className={`collectionSelectWrapper ${isOpen ? '' : 'hidden'}`}>
                {collectionPageStore.allCollections &&
                    collectionPageStore.allCollections.filter(item => item.isExpose).map((collection, i) => {
                        return (
                            <div
                                key={i}
                                id={collection.id}
                                className={`collectionSelect ${
                                    collectionPageStore.collectionNow &&
                                    collectionPageStore.collectionNow.id === collection.id
                                        ? 'focus'
                                        : ''
                                }`}
                                onClick={() => {
                                    navigate(`/collection/${collection.url}`)
                                    collection.subCategory = collection.subCategories[0]
                                    collectionPageStore.setCollectionNow(collection);
                                }}
                            >
                                {collection.title}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
});

export default CollectionSelectorTop;
