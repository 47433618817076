import React from 'react';





const Spinner = ({ color }) => {
    return (
        <div className="loadingio-spinner" 
        //  style={{ position: 'fixed', top: 0, left: 0, right:0, bottom:0, backgroundColor: "rgba(96, 68, 72, 0.5)", display:'flex', alignItems: "center", justifyContent: "center", zIndex: 1000000}}
         >

            <div className="loadingio-spinner-dual-ball-hqgr8n5kmog"><div className="ldio-gv9lev5i3zo">
                <div></div><div></div><div></div>
            </div></div>
        </div>


    );
}

export default Spinner;