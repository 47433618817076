import * as React from 'react';
import {HomePageStore} from '../stores/HomePageStore';
import {RootStore} from '../stores/RootStore';

const rootStore = new RootStore();

export function createStores() {
    return {
        collectionPageStore: rootStore.collectionPageStore,
        spinnerStore: rootStore.spinnerStore,
        cartDataStore: rootStore.cartDataStore,
        bannerDataStore: rootStore.bannerDataStore,
        homePageStore: rootStore.homePageStore,
        userDataStore: rootStore.userDataStore,
        modalDataStore: rootStore.modalDataStore
    };
}

export const stores = createStores();

export const AppContext = React.createContext(stores);
