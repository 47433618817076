import React, {useEffect, useRef, useState} from 'react';
import './PurchaseMenu.scss';
import Dropdown from '../Dropdown/Dropdown.component';
import DropdownMulti from '../DropdownMulti/DropdownMulti.component';
import BroProducts from '../BroProducts/BroProducts.component';
import {DownLoadArrow, SmallLinkRightArrow} from '../../../assets/Arrows.jsx';
import OptionAndTotalPrice from '../OptionAndTotalPrice/OptionAndTotalPrice.component.jsx';
import CTAButton from '../CTAButton/CTAButton.component.jsx';
import {checkCSSHasSafeArea, checkDate, moveToLink, SetScreenSize} from '../../utils/utils';
import {kakaoGiftList} from "../../constants/constants";
import {AppContext} from "../../context/AppContext";

let C_IS_LOGIN;

const PurchaseMenu = ({
                          buyNowAction,
                          productData,
                          optionQuantityData,
                          onOptionSelect,
                          totalPrice,
                          isButtonInclude,
                          cartOpenOrAction,
                          cartAddurl,
                          isDesktop,
                          isCanbuyNow,
                          isInMobileFloating,
                          setHomeMobileCartHeight,
                          homeMobileCartHeight,
                          moveToOtherPage,
                          isForProductNpay,
                          slug,
                          isInSearch,
                          isInProductpage,
                          isInPopUp,
                          resetOptionInfo,
                          isLogin,
                          setLoginModal
                      }) => {
    const [optionData, setOptionQuantityData] = useState(optionQuantityData);
    const [optionOpen, setOptionOpen] = useState(false);
    const {modalDataStore} = React.useContext(AppContext);
    const {setIsRestockingModalProductId, setIsRestockingModalVisible} = modalDataStore
    const overflowChecker = useRef()

    useEffect(() => {
        setOptionQuantityData(optionQuantityData);
    }, [optionQuantityData]);
    // useEffect(() => {
    //     if (isInPopUp) {
    //         const handleNotch = () => {
    //             if (checkCSSHasSafeArea()) {
    //                 buttonWrapperRef.current.style.paddingBottom = '20px';
    //             }
    //         };
    //         SetScreenSize()
    //         handleNotch();
    //         window.addEventListener('scroll', handleNotch);
    //
    //         return () => {
    //             window.removeEventListener('scroll', handleNotch);
    //         };
    //     }
    // }, []);

    useEffect(() => {
        if (document.getElementsByClassName("mobile-cartPopup-wrapper") && document.getElementsByClassName("mobile-cartPopup-wrapper")[0]) {
            if (optionOpen) {

                document.getElementsByClassName("mobile-cartPopup-wrapper")[0].style.minHeight = "340px"
            } else {
                document.getElementsByClassName("mobile-cartPopup-wrapper")[0].style.minHeight = "0"
            }
        }
    }, [optionOpen])


    useEffect(() => {
        if ((document.body.clientWidth < 900) && (overflowChecker.current?.getBoundingClientRect().height > 320)) {
            overflowChecker.current.style.overflow = "auto"
        } else {
            overflowChecker.current.style.overflow = "visible"
        }
    }, [overflowChecker.current?.getBoundingClientRect().height])

    const validationById = (id) => {
        const targetOptionData = optionQuantityData?.filter(data => productData.product?.up_sale_products?.filter(product => product.id === data.id).length === 0)
        if (targetOptionData?.length === 1 && targetOptionData[0].id === id) {
            return true
        }
        return false
    }

    return (
        <div
            ref={overflowChecker}
            className={`purchaseMenu-wrapper ${isInMobileFloating !== undefined && (isInMobileFloating ? "mobile-active" : "")}`}
            style={{
                opacity: productData.product && productData.product.display_title1 ? 1 : 0,
                transition: 'opacity 500ms',
                overflow: "visible",
            }}
        >
            {productData.product && productData.product.paths && isInProductpage && (
                <div className="pathArea">
                    {productData.product.paths.map((el, i) => (
                        <div key={el.name}>
              <span
                  className="pathName"
                  style={{
                      cursor: 'pointer',
                  }}
                  onClick={(e) => {
                      moveToLink(`/collection/${el.slug}`, e);
                  }}
              >
                {el.name}
              </span>
                            {i < productData.product.paths.length - 1 && (
                                <img className="pathArrow" src="/src/img/mo-icon-review-arrow.svg"/>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {productData.product && (
                <>
                    <div className="purchaseMenu-name">{productData.product.sub_title}</div>
                    <h1 className="purchaseMenu-mainText">
                        {productData.product.slug === 'a-card-gift-set-2021cal' ? (
                            isDesktop ? (
                                <>
                                    <div>{productData.product.display_title1}</div>
                                    <div>{productData.product.display_title2}</div>
                                </>
                            ) : (
                                <>
                                    <div>{productData.product.display_title1}</div>
                                    <div>{productData.product.display_title2}</div>
                                </>
                            )
                        ) : isDesktop ? (
                            <>
                                <div>{productData.product.display_title1}</div>
                                <div>{productData.product.display_title2}</div>
                            </>
                        ) : (
                            productData.product.title
                        )}
                    </h1>

                    <div className="purchaseMenu-priceAndReview">
                        {productData ? (
                            <>
                                {productData.product.on_sale ? (
                                    <>
                    <span className="price-before">
                      {productData.product.display_regular_price}
                    </span>
                                        <span className="price-after">{productData.product.display_price}</span>
                                        <span className="discount-rate">{productData.product.discount_rate}</span>
                                    </>
                                ) : (
                                    <span className="price-no-sale">{productData.product.display_price}</span>
                                )}
                                <span style={{cursor: 'pointer'}} className="number-of-rveiws">
                  <span
                      onClick={(e) => {
                          if (productData && moveToOtherPage) {
                              moveToLink(`/product/${productData.product.slug}#review`, e);
                          } else {
                              window.location.href = '#review';
                          }
                      }}
                  >
                    리뷰 {productData.product.rating_count} <SmallLinkRightArrow/>
                  </span>
                </span>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </>
            )}

            {/* 지금 주문 자리 */}
            {isCanbuyNow && slug !== "moodwater-yuja" && slug !== "moodwater-violet" && (
                <div className="buyNow-text">
                    <img className="thunder dt" src="/src/img/thunder_w9_h12.svg"/>
                    <img className="thunder mo" src="/src/img/thunder_w7_h10.svg"/>
                    지금 주문 시 오늘 출발!
                </div>
            )}

            {productData.product &&
                productData.product.cross_sale_products &&
                productData.product.cross_sale_products.length > 0 && (
                    <div>
                        <BroProducts
                            productItems={[productData.product, ...productData.product.cross_sale_products]}
                        />
                    </div>
                )}
            {<div>
                {productData.product &&
                    productData.product.type !== 'simple' &&
                    productData.product.attributes &&
                    productData.product.attributes.length > 0 && (
                        <div className="optionArea" key={optionOpen}>
                            {productData.product.attributes && productData.product.attributes.length > 1 ? (
                                <DropdownMulti
                                    setLoginModal={setLoginModal}
                                    isLogin={isLogin}
                                    productData={productData}
                                    onOptionSelect={onOptionSelect}
                                    isOptionOpen={optionOpen}
                                    setIsOptionOpen={setOptionOpen}
                                    setHomeMobileCartHeight={setHomeMobileCartHeight}
                                />
                            ) : (
                                <Dropdown
                                    setLoginModal={setLoginModal}
                                    isLogin={isLogin}
                                    overflowChecker={overflowChecker}
                                    placeholder={`[${productData.product.attributes[0].name}] 옵션을 선택해 주세요`}
                                    menuItems={productData.product.product_variations}
                                    // optionQuantityData={optionQuantityData}
                                    product={productData.product}
                                    onOptionSelect={onOptionSelect}
                                    isOptionOpen={optionOpen}
                                    setIsOptionOpen={setOptionOpen}
                                    isDesktop={true}
                                    setHomeMobileCartHeight={setHomeMobileCartHeight}
                                    homeMobileCartHeight={homeMobileCartHeight}
                                    productTitle={productData.product.name}
                                    showIcon={true}
                                />
                            )}
                        </div>
                    )}
            </div>}

            {productData?.product?.up_sale_products?.length > 0 && optionData && optionData.length > 0 && (
                productData?.product?.up_sale_products[0].type === "variable" ?
                    <div className="upsell-wrapper">
                        <div className="upsell-title">{productData.product.add_product_wording}</div>
                        {productData.product.up_sale_products.map((product, i) => {
                            return (
                                <div style={{marginBottom: '8px'}} key={i}>
                                    <Dropdown
                                        isLogin={isLogin}
                                        overflowChecker={overflowChecker}
                                        placeholder="상품 옵션을 선택해 주세요"
                                        menuItems={product.type !== 'simple' ? product.product_variations : [product]}
                                        // optionQuantityData={optionQuantityData}
                                        onOptionSelect={onOptionSelect}
                                        isOptionOpen={optionOpen}
                                        setIsOptionOpen={setOptionOpen}
                                        isDesktop={true}
                                        setHomeMobileCartHeight={setHomeMobileCartHeight}
                                        homeMobileCartHeight={homeMobileCartHeight}
                                        menuZIndex={i + 1}
                                        header={{image: product.add_product_thumbnail, title: product.name}}
                                        productTitle={product.name}
                                        showIcon={true}
                                        product={product}

                                        optionData={optionData ? optionData : null}
                                        slug={product.slug}
                                        upSaleProducts={productData.product && productData.product?.up_sale_products}
                                    />
                                </div>
                            );
                        })}
                    </div> : <div className={"upsell-only-one-wrapper"}>
                        <div className="upsell-title">{productData.product.add_product_wording}</div>
                        <div className="upsell-only-one-item-wrapper">
                            <div className="upsell-only-one-item-info-wrapper">
                                <div className="upsell-only-one-item-img-wrapper">
                                    <img
                                        src={productData.product.up_sale_products[0].thumbnail}/>
                                </div>
                                <div className="upsell-only-one-item-description-wrapper">
                                    <div className="upsell-only-one-item-title">
                                        {productData.product.up_sale_products[0].name}
                                    </div>
                                    {productData.product.up_sale_products[0].on_sale ?
                                        <div className="upsell-only-one-item-description ">
                                            <div
                                                className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                            <div style={{display: 'flex'}}>
                                                                <span
                                                                    className="upsell-only-one-item-description-sale-price">{productData.product.up_sale_products[0].display_price}</span>
                                                <span
                                                    className="upsell-only-one-item-description-discount-rate"> {productData.product.up_sale_products[0].discount_rate}</span>
                                            </div>
                                        </div>
                                        :
                                        ((productData.product.slug === "moodwater-yuja" || productData.product.slug === "moodwater-violet" || productData.product.slug === "moodwater-juniper" || productData.product.slug === "moodwater-variety-3")
                                        && productData.product.up_sale_products[0].title === "3종 시향 키트" ?
                                            <div className="upsell-only-one-item-description">
                                                <div
                                                    className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                <div style={{display: 'flex'}}>
                                                                    <span
                                                                        className="upsell-only-one-item-description-sale-price">3,500원</span>
                                                    <span
                                                        className="upsell-only-one-item-description-discount-rate">(-30%)</span>
                                                </div>
                                            </div> : (productData.product.slug === "collins-incense" && productData.product.up_sale_products[0].id === 150708
                                            && validationById(34141) ?
                                                <div className="upsell-only-one-item-description">
                                                    <div
                                                        className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                    <div style={{display: 'flex'}}>
                                                                        <span
                                                                            className="upsell-only-one-item-description-sale-price">1,900원</span>
                                                        <span
                                                            className="upsell-only-one-item-description-discount-rate">(-24%)</span>
                                                    </div>
                                                </div> : (productData.product.slug === "collins-incense" && productData.product.up_sale_products[0].id === 150709 && !validationById(34141) ?
                                                    <div className="upsell-only-one-item-description">
                                                        <div
                                                            className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                        <div style={{display: 'flex'}}>
                                                                            <span
                                                                                className="upsell-only-one-item-description-sale-price">2,400원</span>
                                                            <span
                                                                className="upsell-only-one-item-description-discount-rate">(-20%)</span>
                                                        </div>
                                                    </div>
                                                    : (productData.product.slug === "incense-chamber-honeybadger" && (productData.product.up_sale_products[0].id === 34136 || productData.product.up_sale_products[0].id === 34137 ||
                                                        productData.product.up_sale_products[0].id === 34138 || productData.product.up_sale_products[0].id === 34139 || productData.product.up_sale_products[0].id === 34140) ?
                                                        <div className="upsell-only-one-item-description">
                                                            <div
                                                                className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                            <div style={{display: 'flex'}}>
                                                                                <span
                                                                                    className="upsell-only-one-item-description-sale-price">14,310원</span>
                                                                <span
                                                                    className="upsell-only-one-item-description-discount-rate">(-10%)</span>
                                                            </div>
                                                        </div> : <div className="upsell-only-one-item-description ">
                                                            <div
                                                                className="upsell-only-one-item-description-regular-price"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                        </div>))))
                                    }
                                    {productData.product.up_sale_products[0].stock_status === 'outofstock' ?
                                        <div className="upsell-only-one-restocking-button"
                                             onClick={(event) => {
                                                 setIsRestockingModalVisible(true);
                                                 setIsRestockingModalProductId(productData.product.up_sale_products[0].id);
                                                 event.stopPropagation();
                                             }}>
                                            <div className="alarm-img-wrapper">
                                                <img src={require("../../../img/icon/alarm_black.png")}/>
                                            </div>
                                            <div className="alarm-text">
                                                입고 알림 받기
                                            </div>
                                        </div>
                                        : <div className="upsell-only-one-item-button"
                                               onClick={() => {
                                                   let itemName = "";
                                                   if (productData.product.up_sale_products[0].type === 'simple') {
                                                       itemName = productData.product.up_sale_products[0].name;
                                                   } else {
                                                       itemName = productData.product.up_sale_products[0].attributes[0].option;
                                                   }
                                                   if (productData.product.up_sale_products[0].additional_info?.login_required) {
                                                       if (!isLogin) {
                                                           setLoginModal(true)
                                                           return;
                                                       }
                                                   }
                                                   if (productData.product.up_sale_products[0].name) {
                                                       itemName = productData.product.up_sale_products[0].name + ' - ' + itemName;
                                                   }
                                                   onOptionSelect({
                                                       id: productData.product.up_sale_products[0].id,
                                                       name: itemName,
                                                       cnt: 1,
                                                       img: productData.product.up_sale_products[0].image,
                                                       regularPrice: productData.product.up_sale_products[0].display_regular_price,
                                                       price: productData.product.up_sale_products[0].display_price,
                                                       discountRate: productData.product.up_sale_products[0].discount_rate,
                                                       onSale: productData.product.up_sale_products[0].on_sale,
                                                       product_id: productData.product.up_sale_products[0].id,
                                                       additional_info: productData.product.up_sale_products[0].additional_info ? productData.product.up_sale_products[0].additional_info : null
                                                   });
                                               }}>
                                            함께 구매하기
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
            )}

            <OptionAndTotalPrice
                isSimple={productData.product && productData.product.type === 'simple'}
                onOptionSelect={onOptionSelect}
                totalPrice={totalPrice}
                resetOptionInfo={resetOptionInfo}
                isInMobileFloating={isInMobileFloating}
                isForProductNpay={isForProductNpay}
                isInSearch={isInSearch}
                hasUpSale={
                    productData.product &&
                    productData.product.up_sale_products &&
                    productData.product.up_sale_products.length > 0
                }
                optionData={optionData ? optionData : null}
                slug={productData.product && productData.product?.slug}
                upSaleProducts={productData.product && productData.product?.up_sale_products}
            />

            {/* {isButtonInclude && */}
            {/* } */}
        </div>
    );
};

export default PurchaseMenu;
