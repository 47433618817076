import React from 'react';

import {callHiddenNav} from '../../utils/utils';
import './HambergerForHiddenNav.scss';
import HamburgerIcon from "../../../img/gnb_menu_40.svg"

const HambergerForHiddenNav: React.FunctionComponent<{ className?: string }> =
    ({
         ...otherProps
     }) => {
        return (
            <img
                // src="https://fly.gitt.co/collins/img/gnb_menu_40.svg"
                src={HamburgerIcon}
                style={{cursor: 'pointer'}}
                onClick={() => {
                    callHiddenNav();
                }}
                className="hamberger"
                {...otherProps}
            />
        );
    };

export default HambergerForHiddenNav;
