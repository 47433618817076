import React, {FunctionComponent, useEffect, useState} from 'react';
import {collectionBlock, collectionlLstItem, collectionMeta, productDataType} from '../../@types/types';
import './CollectionItemList.scss';
import CollectionItem from '../CollectionItem/CollectionItem.component';
import CollectionItemMoodwater from '../CollectionItemMoodwater/CollectionItemMoodwater.component';

import CartPopUp from '../CartPopUp/CartPopUp.component';
import {convertImgFormat, filterThumbnailImageByFormat, moveToLink} from '../../utils/utils';
import PreloadImage from 'react-preload-image';
import {breakpointDt_mid, kakaoGiftList} from "../../constants/constants";
import KakaoGiftModal from "../KakaoGiftModal/KakaoGiftModal.component";
import {element} from "prop-types";
import {useNavigate} from "react-router-dom";

const CollectionItemList: FunctionComponent<{
    curSort?: string;
    isLogin: boolean;
    moodwater?: boolean;
    collectionNow?: collectionMeta;
    listItem: Array<collectionlLstItem>;
    collectionPromoText?: string;
    callCartCount: CallableFunction;
    setIsSpinnerShow: CallableFunction;
    isInSearch?: boolean;
    categoryName?: string;
}> = ({
          moodwater = false,
          isLogin,
          curSort,
          listItem,
          collectionNow,
          collectionPromoText,
          callCartCount,
          setIsSpinnerShow,
          isInSearch,
          categoryName = null
      }) => {
    const [dataForCart, setDataForCart] = useState<any>({});
    const [imageDataForCart, setImageDataForCart] = useState<Array<string>>([]);
    const [isCartPopUp, setIsCartPopUp] = useState<boolean>();
    const [isKakaoGiftPopup, setIsKakaoGiftPopup] = useState<boolean>(false)
    const [isMobile, setIsMobile] = useState<boolean>(true)
    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth >= breakpointDt_mid) {
            setIsMobile(false)
        } else {
            setIsMobile(true)
        }
    }, [])

    useEffect(() => {
        const assignAnimationDelay = () => {
            // let delay = isInSearch ? 0.3 :1.3;
            let delay = 0.3;
            const elements = Array.from(
                // eslint-disable-next-line no-undef
                document.getElementsByClassName('collectionItemEl') as HTMLCollectionOf<HTMLElement>
            );

            elements.forEach((el) => {
                if (el.classList.contains('addAni')) {
                    return;
                }
                el.style.animationDelay = `${delay}s`;
                el.classList.add('addAni');
                delay = delay + 0.2;
            });
        };

        assignAnimationDelay();

    }, [listItem, listItem.length]);

    // 여기에 카트 팝업이 들어가야 함
    useEffect(() => {
        if (isKakaoGiftPopup) {
            if (
                /iP(hone)/.test(navigator.platform)
            ) {
                document.body.style.overflow = 'hidden';
                document.body.style.position = 'fixed';
                document.body.style.left = '0';
                document.body.style.right = '0';
            } else {
                document.body.style.overflow = 'hidden';
            }
        } else {
            if (
                /iP(hone)/.test(navigator.platform)
            ) {
                document.body.style.removeProperty('overflow');
                document.body.style.removeProperty('position');
                document.body.style.removeProperty('left');
                document.body.style.removeProperty('right');
            } else {
                document.body.style.removeProperty('overflow');
            }
        }
    }, [isKakaoGiftPopup])

    const checkValidUrl = (url: string) => {
        return url.indexOf('http') !== -1
            ? url
            : 'https://scontent-ssn1-1.xx.fbcdn.net/v/t1.0-9/135494597_3772588622833918_2738139586651013592_n.jpg?_nc_cat=105&ccb=2&_nc_sid=730e14&_nc_ohc=dslC5dI-xm4AX-a7jDf&_nc_ht=scontent-ssn1-1.xx&oh=a9eb25136b7e56e8d16af0d5107ff4f9&oe=601C2418';
    };
    return (
        <>
            {listItem.map((el, i) => {
                if (el.blockType === 'banner') {
                    return (
                        <div
                            id={el.block.anchor}
                            onClick={(e) => {
                                if (el.block.link) {
                                    if (e) {
                                        if (e.metaKey || e.ctrlKey) {
                                            window.open(el.block.link);
                                            return;
                                        }
                                        // else window.location = url;
                                    }
                                    navigate(el.block.link);
                                } else {
                                    return;
                                }
                            }}
                            style={{
                                cursor: el.block.link ? 'pointer' : '',
                            }}
                            className={`collectionLongImg collectionItemEl ${moodwater ? "moodwater" : ''}`}

                            key={`${el.block.imgUrl}${collectionNow.id}${curSort}${i}`}
                        >
                            <PreloadImage
                                style={{
                                    backgroundColor: '#f7f7ed',
                                    paddingTop: '75%',
                                }}
                                src={checkValidUrl(el.block.imgUrl)}
                                className="longimg"
                                alt=""
                                // lazy
                            />

                            {/* <img src={checkValidUrl(el.block.imgUrl) } className ='longimg' alt=""/> */}
                            {/* <img src={checkValidUrl(el.block.imgUrlMo) } className ='longimg mo' alt=""/> */}
                        </div>
                    );
                } else if (el.blockType === 'product') {
                    const imgList = filterThumbnailImageByFormat(el.block.images)
                    return (
                        <div
                            className="collectionItem collectionItemEl"
                            key={`${el.block.title}${collectionNow.id}${curSort}${i}`}
                        >{categoryName === "mood" ? <CollectionItemMoodwater
                                mainThumbnail={imgList.size > 0 && convertImgFormat(Array.from(imgList)[0][1])}
                                subThumbnail={
                                    imgList.size > 1 && convertImgFormat(Array.from(imgList)[1][1])
                                }
                                event={el.block.promo_text ? el.block.promo_text : collectionPromoText}
                                subName={el.block.sub_title}
                                name={el.block.title}
                                onSale={el.block.on_sale}
                                score={el.block.average_rating}
                                reviewCount={el.block.rating_count}
                                discountRate={el.block.origin_discount_rate}
                                regularPrice={el.block.display_regular_price}
                                discountPrice={el.block.display_price}
                                link={el.block.url}
                                itemData={el.block}
                                setDataForCart={setDataForCart}
                                setIsCartPopUp={setIsCartPopUp}
                                setIsKakaoGiftPopup={setIsKakaoGiftPopup}
                                setImageDataForCart={setImageDataForCart}
                            /> :
                            <CollectionItem
                                mainThumbnail={imgList.size > 0 && convertImgFormat(Array.from(imgList)[0][1])}
                                subThumbnail={
                                    imgList.size > 1 && convertImgFormat(Array.from(imgList)[1][1])
                                }
                                event={el.block.promo_text ? el.block.promo_text : collectionPromoText}
                                subName={el.block.sub_title}
                                name={el.block.title}
                                onSale={el.block.on_sale}
                                score={el.block.average_rating}
                                reviewCount={el.block.rating_count}
                                discountRate={el.block.origin_discount_rate}
                                regularPrice={el.block.display_regular_price}
                                discountPrice={el.block.display_price}
                                link={el.block.url}
                                itemData={el.block}
                                setDataForCart={setDataForCart}
                                setIsCartPopUp={setIsCartPopUp}
                                setIsKakaoGiftPopup={setIsKakaoGiftPopup}
                                setImageDataForCart={setImageDataForCart}
                            />
                        }
                        </div>
                    );
                } else if (el.blockType === 'blank') {
                    return (
                        <div
                            className="collectionItem collectionItemEl"
                            key={`${el.block.imgUrl}${collectionNow.id}${curSort}${i}`}
                        >
                            <img src={checkValidUrl(el.block.imgUrl)} className="shortimg" alt=""/>
                        </div>
                    );
                }
            })}
            <KakaoGiftModal isKakaoGiftPopup={isKakaoGiftPopup} closeAction={() => {
                setIsKakaoGiftPopup(false)
            }} productData={dataForCart}/>
            <CartPopUp

                isLogin={isLogin}
                productData={dataForCart}
                imageData={imageDataForCart}
                closeAction={() => {
                    setIsCartPopUp(false);
                }}
                visible={isCartPopUp}
                callCartCount={callCartCount}
                setIsSpinnerShow={setIsSpinnerShow}
                isInSearch={isInSearch}
            />
        </>
    );
};

export default CollectionItemList;
