import React, {Component, FunctionComponent, useEffect, useRef, useState} from 'react';

import './DropdownMulti.scss';
import Dropdown2 from '../DropdownSimple/Dropdown2.component';


const DropdownMulti: FunctionComponent<{
    productData?: any,
    onOptionSelect?: CallableFunction,
    setIsOptionOpen?: CallableFunction,
    isDesktop: boolean,
    isOptionOpen?: boolean,
    setHomeMobileCartHeight?: CallableFunction,
}> = (
    {
        productData,
        onOptionSelect,
        setHomeMobileCartHeight,
    }
) => {

    //전체 옵션 중 선택가능한 옵션 array
    const [allSelectablePaths, setAllSelectablePaths] = useState([]);

    //menuItems
    const [selectableOptionsArr, setSelectableOptionsArr] = useState([[]]);
    const [selectedPaths, setSelectedPaths] = useState([]);
    const [soldoutOptionKeys, setSoldoutOptionKeys] = useState([]);

    const [dropdowns, setDropdowns] = useState([]);

    useEffect(() => {
        const productVariations = productData.product.product_variations;

        const selectablePaths = [];
        if (productVariations) {

            productVariations.map((productVariation) => {

                const path = [];
                productVariation.attributes.map((attr) => {
                    path.push(attr.option);
                });

                selectablePaths.push(path);
            });

            const allOptionKeys = new Set();
            const instockOptionkeys = new Set();
            productVariations.map((productVariation) => {

                productVariation.attributes.map((attr) => {
                    allOptionKeys.add(attr.option);
                });

                if (productVariation.stock_status === 'instock') {
                    productVariation.attributes.map((attr) => {
                        instockOptionkeys.add(attr.option);
                    })

                }
            });

            const soldoutOptionKeys = Array.from(allOptionKeys).filter(key =>
                !instockOptionkeys.has(key)
            );
            setSoldoutOptionKeys(soldoutOptionKeys);

        }
        setAllSelectablePaths(selectablePaths);

    }, [productData]);

    const initDropdowns = () => {

        if (!selectableOptionsArr || selectableOptionsArr.length < 1 || !allSelectablePaths || allSelectablePaths.length < 1) {
            return;
        }

        const dropdowns = [];

        for (let i = 0; i < selectedPaths.length + 1; i++) {

            if (i == productData.product.attributes.length) {
                //마지막 선택됨
                break;
            }

            const menusItmes = [];
            selectableOptionsArr[i].map((path) => {
                menusItmes.push(
                    {
                        key: path,
                        value: path
                    }
                )

                soldoutOptionKeys.forEach((soldOptionkey) => {
                    if (soldOptionkey == path) {
                        menusItmes[menusItmes.length - 1].soldout = true;
                    }
                })
            });

            //sort by stack_status

            let sortedMenusItems = [];
            if (menusItmes) {
                sortedMenusItems = menusItmes.sort(function (a, b) {
                    const aval = a.soldout ? 1 : 0;
                    const bval = b.soldout ? 1 : 0;
                    return aval - bval;
                })
            }


            let placeholder = "";
            if (selectedPaths.length == i && productData?.product?.attributes[i]) {
                placeholder = "[" + productData.product.attributes[i].name + "] 옵션을 선택해 주세요";
            } else {
                placeholder = selectedPaths[i];
            }


            dropdowns.push({
                placeholder: placeholder,
                menuItems: sortedMenusItems,
                selectAction: (selectedOption) => {

                    let rePaths = [];

                    if (selectedPaths.length == i) {
                        //insert
                        rePaths = [...selectedPaths, selectedOption.id];
                    } else if (selectedPaths.length > i) {
                        //이전 옵션 선택함
                        selectedPaths[i] = selectedOption.id;
                        rePaths = selectedPaths.slice(i - 1);

                    }
                    setSelectedPaths(rePaths);
                },
                isOptionOpen: false,
                setHomeMobileCartHeight: setHomeMobileCartHeight,
            });
        }
        setDropdowns(dropdowns);
    }

    // 초기 render or dropdown 선택 시 동작함
    useEffect(() => {
        if (!allSelectablePaths || allSelectablePaths.length < 1) {
            return;
        }

        if (selectedPaths.length === productData?.product?.attributes?.length) {
            //모든 옵션 선택됨
            addOption();

            return;
        }

        const arr = [];

        if (selectedPaths.length == 0) {

            allSelectablePaths.map((selectableOption) => {

                selectableOption.map((path, i) => {
                    if (i == 0) {
                        arr.push(path);
                    }
                });
            });

        } else {
            for (let i = 0; i < allSelectablePaths.length; i++) {

                const selectablePaths = allSelectablePaths[i];

                let allContains = true;
                for (let j = 0; j < selectablePaths.length; j++) {

                    if (selectedPaths.length - 1 >= j && selectablePaths[j] !== selectedPaths[j]) {
                        allContains = false;
                    }
                }

                if (allContains) {
                    arr.push(selectablePaths[selectedPaths.length]);
                }

            }

        }

        const arrUnique = [...new Set(arr)];
        selectableOptionsArr.splice(selectedPaths.length, 0, arrUnique);
        setSelectableOptionsArr(selectableOptionsArr.filter(ele => ele.length > 0));

    }, [allSelectablePaths, selectedPaths]);

    useEffect(() => {
        initDropdowns();

    }, [selectableOptionsArr]);


    const addOption = () => {
        let targetVariation = null;
        //find variation
        const productVariations = productData.product.product_variations;

        for (let i = 0; i < productVariations.length; i++) {

            let isAllKeyMatched = true;

            for (let j = 0; j < productVariations[i].attributes?.length; j++) {

                if (productVariations[i].attributes[j].option !== selectedPaths[j]) {
                    isAllKeyMatched = false;
                    break;
                }
            }

            if (isAllKeyMatched) {

                targetVariation = productVariations[i];
                break;
            }
        }
        let optionName = "";
        if (productData.product && targetVariation) {

            optionName = "";

            targetVariation.attributes.map((attr, i) => {

                if (i !== 0) {
                    optionName += " & ";
                }
                optionName += attr.option;

            })

            //add item
            onOptionSelect({
                id: targetVariation.id,
                name: optionName,
                cnt: 1,
                img: targetVariation.image,
                regularPrice: targetVariation.display_regular_price,
                price: targetVariation.display_price,
                discountRate: targetVariation.discount_rate,
                onSale: targetVariation.on_sale,
                product_id: productData.product ? productData.product.id : null,
                additional_info: targetVariation.additional_info ? targetVariation.additional_info : null
            });

            //옵션 초기화
            setSelectedPaths([]);
        }

    }

    return (

        <div className="dropdowns">
            {dropdowns.map((dropdown, i) => {
                return (
                    <Dropdown2
                        key={Math.random()}
                        placeholder={dropdown.placeholder}
                        menuItems={dropdown.menuItems}
                        selectAction={dropdown.selectAction}
                        isOptionOpen={dropdown.isOptionOpen}
                        setHomeMobileCartHeight={dropdown.setHomeMobileCartHeight}
                        setSelectedOption={dropdown.setSelectedOption}
                        zIndex={20 - i}
                    />
                );
            })}

            {/* <div>
					<DropdownSimple placeholder={`[${parentOptionsAttr}] 옵션을 선택해 주세요`}
						menuItems={parentOptions}
						onOptionSelect={updateChildOptions}
						isOptionOpen={isOptionOpen}
						setIsOptionOpen={setIsOptionOpen}
						// isDesktop={true}
						setHomeMobileCartHeight={setHomeMobileCartHeight}
						homeMobileCartHeight={homeMobileCartHeight}
						selectedOption={selectedParentOption}
						setSelectedOption={setSelectedParentOption}
					/>
				</div> */}

            {/* {selectedParentOption && <div className="childOption">
					<Dropdown placeholder={`[${childOptionsAttr}] 옵션을 선택해 주세요2`}
						menuItems={childOptions}
						onOptionSelect={onOptionSelect}
						isOptionOpen={isOptionOpen}
						setIsOptionOpen={setIsOptionOpen}
						isDesktop={true}
						setHomeMobileCartHeight={setHomeMobileCartHeight}
						homeMobileCartHeight={homeMobileCartHeight}
						attributeIdx={1}
						selectedParentOption={selectedParentOption}
						setSelectedParentOption={setSelectedParentOption}
						productTitle={productData.product.display_title1}
						showIcon={false}
					/>
				</div>
				} */}
        </div>
    );
};

export default DropdownMulti;