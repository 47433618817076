import React, {FunctionComponent, useEffect, useState} from 'react';
import SortingIcon from "../../../img/sorting.svg"
import './CollectionSortControll.scss';


const CollectionSortControll: FunctionComponent<{
    curSort: string;
    setSortCriteria: CallableFunction;
}> = ({curSort, setSortCriteria}) => {


    const [isOpen, setIsOpen] = useState<boolean>();
    const removeFocusOnElements = (collectionSorts) => {
        collectionSorts.forEach(selector => {
                selector.classList.remove('focus');
            }
        );
    };

    useEffect(() => {
        const collectionSorts = document.querySelectorAll('.collectionSort');

        collectionSorts.forEach(selector => {
            selector.addEventListener('mouseover', (e) => {
                removeFocusOnElements(collectionSorts);
                e.target.classList.add('focus');
            });
        });

        const addCloseEventOnBody = (e) => {
            if (e.target.classList[0] === 'curSort'
                || e.target.classList[0] === 'sortLogo'
                || e.target.classList[0] === 'collectionSort'
            ) {
                return;
            }

            setIsOpen(false);
        };
        if (document.body) {
            document.body.addEventListener('click', addCloseEventOnBody);
        }


        return () => {
            document.body.removeEventListener('click', addCloseEventOnBody);

        };


    }, []);


    useEffect(() => {
        const collectionSorts = document.querySelectorAll('.collectionSort');
        removeFocusOnElements(collectionSorts);
        collectionSorts.forEach(selector => {
            if (selector.id == curSort) {
                selector.classList.add('focus');
            }
        });
    }, [isOpen, curSort]);


    return (
        <div
            className="collectionSortWrapper"
            onClick={() => {
                setIsOpen(!isOpen);
            }}

        >
            <div className="curSort">
                {curSort}
                <img className="sortLogo" src={SortingIcon} alt=""/>
            </div>


            <div className={`collectionSortWrapper ${isOpen ? '' : 'hidden'}`}>
                <div
                    id="기본순"
                    className={`collectionSort ${curSort === '기본순' ? 'focus' : ''}`}
                    onClick={() => {
                        setSortCriteria('기본순');
                    }}
                >기본순
                </div>
                <div
                    id="인기순"
                    className={`collectionSort ${curSort === '인기순' ? 'focus' : ''}`}
                    onClick={() => {
                        setSortCriteria('인기순');
                    }}
                >
                    인기순
                </div>
                <div
                    id="높은할인순"
                    className={`collectionSort ${curSort === '높은할인순' ? 'focus' : ''}`}
                    onClick={() => {
                        setSortCriteria('높은할인순');
                    }}
                >
                    높은할인순
                </div>
                <div
                    id="리뷰순"
                    className={`collectionSort ${curSort === '리뷰순' ? 'focus' : ''}`}
                    onClick={() => {
                        setSortCriteria('리뷰순');
                    }}
                >
                    리뷰순
                </div>
                <div
                    id="높은가격순"
                    className={`collectionSort ${curSort === '높은가격순' ? 'focus' : ''}`}
                    onClick={() => {
                        setSortCriteria('높은가격순');
                    }}
                >
                    높은가격순
                </div>
                <div
                    id="낮은가격순"
                    className={`collectionSort ${curSort === '낮은가격순' ? 'focus' : ''}`}
                    onClick={() => {
                        setSortCriteria('낮은가격순');
                    }}
                >
                    낮은가격순
                </div>
            </div>


        </div>
    );
};


export default CollectionSortControll;