import React, {useEffect, useRef, useState} from 'react';
import './ProductDetailMobileFloatingButtons.scss';
import Dropdown from '../Dropdown/Dropdown.component';
import DropdownMulti from '../DropdownMulti/DropdownMulti.component';
import OptionAndTotalPrice from '../OptionAndTotalPrice/OptionAndTotalPrice.component.jsx';
import {WhiteCloseIcon} from '../../../assets/CloseIcon';
import CallToActionButton from '../CallToActionButton/CallToActionButton.component.tsx';
import {breakpointDt, kakaoGiftList, OUTOFSTOCK_OPTION_SELECT_TEXT} from '../../constants/constants';
import {checkCSSHasSafeArea, createCh, deleteCh, SetScreenSize} from '../../utils/utils';
import ExternalIcon from "../../../img/icon/icon-external-link.svg"
import KakaoGiftLogo from "../../../img/icon/kakaogift-logo.svg"
import RestockingButton from "../RestockingButton/RestockingButton";
import {AppContext} from "../../context/AppContext";

const ProductDetailMobileFloatingButtons = ({
                                                productData,
                                                onOptionSelect,
                                                totalPrice,
                                                optionQuantityData,
                                                cartOpenOrAction,
                                                buyNowAction,
                                                isForProductNpay,
                                                resetOptionInfo,
                                                isSimple,
                                                slug,
                                                setLoginModal,
                                                isLogin
                                            }) => {
    const [floatingOpen, setFloatingOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [mobilePayMode, setMobilePayMode] = useState();
    const [btns, setBtns] = useState([]);
    const [visible, setVisible] = useState();
    const [optionOpen, setOptionOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0)
    const {modalDataStore} = React.useContext(AppContext);

    const {setIsRestockingModalProductId, setIsRestockingModalVisible} = modalDataStore
    const closeAction = () => {
        setMobilePayMode(undefined);
        setIsOpen(false);
    };
    useEffect(() => {
        SetScreenSize()
    }, [])
    useEffect(() => {
        if (isOpen) {
            const windowScrollPosition = window.pageYOffset
            setScrollPosition(windowScrollPosition);
            if (
                /iP(hone)/.test(navigator.platform)
            ) {
                document.body.style.position = 'fixed';
                document.body.style.left = '0';
                document.body.style.right = '0';
                document.body.style.top = `-${windowScrollPosition}px`;

                //const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
                //const version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
                //
                // if (version[0] >= 15) {
                //     document.body.style.position = 'fixed';
                // }
            } else {
                document.body.style.overflow = 'hidden';

            }
        } else {
            if (
                /iP(hone)/.test(navigator.platform)
            ) {
                document.body.style.removeProperty('position');
                document.body.style.removeProperty('left');
                document.body.style.removeProperty('right');
                document.body.style.removeProperty('top');
                window.scrollTo({left: 0, top: scrollPosition, behavior: "auto"});
                //window.scrollTo({top: scrollPosition});
                //const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
                //const version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];

                // if (version[0] >= 15) {
                //     document.body.style.position = 'static';
                // }
            } else {
                document.body.style.removeProperty('overflow');
            }
        }
    }, [isOpen]);
    useEffect(() => {

        let btnsTemp = [];
        if (mobilePayMode === 'normal') {
            btnsTemp[0] = {
                buyButtonColor: 'white',
                clickAction: () => {
                    cartOpenOrAction(optionQuantityData);
                },
                btnDisplay: '장바구니 담기',
            };

            btnsTemp[1] = {
                buyButtonColor: 'orange',
                clickAction: () => {
                    buyNowAction();
                },
                btnDisplay: '바로 구매하기',
            };
        } else if (!isForProductNpay) {
            btnsTemp[0] = {
                buyButtonColor: 'orange',
                clickAction: () => {
                    setMobilePayMode('normal');
                    setIsOpen(true);
                },
                btnDisplay: '구매하기',
            };
        } else if (mobilePayMode === 'npay') {
            //없음
        } else {
            //default
            // if (window.sessionStorage.getItem('showNpay') === 'true') {
            btnsTemp[0] = {
                buyButtonColor: 'black',
                clickAction: () => {
                    setMobilePayMode('npay');
                    setIsOpen(true);
                },
                btnDisplay: <img src={require("../../../img/button_npay_text.png")} alt=""/>,
            };

            btnsTemp[1] = {
                buyButtonColor: 'orange',
                clickAction: () => {
                    setMobilePayMode('normal');
                    setIsOpen(true);
                },
                btnDisplay: '구매하기',
            };
            // } else {
            // btnsTemp[0] = {
            //   buyButtonColor: 'orange',
            //   clickAction: () => {
            //     setMobilePayMode('normal');
            //     setIsOpen(true);
            //   },
            //   btnDisplay: '구매하기',
            //   // };
            // };
        }

        setBtns(btnsTemp);
    }, [mobilePayMode, optionQuantityData, isForProductNpay]);

    const buttonWrapperRef = useRef();
    const overflowChecker = useRef()

    // useEffect(() => {
    //     const handleVisibility = () => {
    //         // 한번이라도 걸리면 영구, 한번도 안걸리면 계속 체크
    //         if (window.scrollY > 15) {
    //             setVisible(true);
    //             if (checkCSSHasSafeArea()) {
    //                 buttonWrapperRef.current.style.paddingBottom = '20px';
    //             }
    //         }
    //     };
    //     window.addEventListener('scroll', handleVisibility);
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleVisibility);
    //     };
    // }, []);

    useEffect(() => {
        if (window.innerWidth < breakpointDt) {
            deleteCh()
        } else {
            createCh()
        }
    }, []);

    useEffect(() => {
        if (overflowChecker.current?.getBoundingClientRect().height > 300) {
            overflowChecker.current.style.overflow = "auto"
        } else {
            overflowChecker.current.style.overflow = "visible"
        }
    }, [overflowChecker.current?.getBoundingClientRect().height, isOpen])


    const validationById = (id) => {
        const targetOptionData = optionQuantityData?.filter(data => productData.product?.up_sale_products?.filter(product => product.id === data.id).length === 0)
        if (targetOptionData?.length === 1 && targetOptionData[0].id === id) {
            return true
        }
        return false
    }

    return (
        <div
            className={`mobilefloatingbutton-container visible ${
                isOpen ? 'fullheight visible' : ''
            } `}
            ref={buttonWrapperRef}

            style={{
                backgroundColor: isOpen ? 'transparent' : '',
            }}
        >
            <div
                className={isOpen ? 'dimarea' : ''}
                onClick={() => {
                    isOpen ? closeAction() : '';
                }}
            ></div>

            <div className="contentWrapper"
                 style={{
                     display: isOpen ? '' : 'none',
                     position: "absolute",
                     bottom: mobilePayMode === 'npay' ? "116px" : "79px",
                     width: "100%"
                 }}>
                <div
                    className="closeBtn"
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '4px 16px',

                    }}
                    onClick={() => {
                        closeAction();
                    }}
                >
                    <WhiteCloseIcon/>
                </div>
                <div className="menu-info" style={{minHeight: floatingOpen ? '340px' : ''}}>
                    <div
                        ref={overflowChecker}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflowY: "visible",
                            paddingBottom: "70px",
                            maxHeight: mobilePayMode === 'npay' ? "calc(calc(var(--vh, 1vh) * 100) - 156px)" : "calc(calc(var(--vh, 1vh) * 100) - 120px)",
                        }}
                    >
                        <div
                            className="option-padding"
                            style={{
                                display: isSimple ? 'none' : '',
                            }}
                        >
                            <div className="option-description">옵션 선택</div>

                            <div key={optionOpen}>
                                {productData.product.attributes && productData.product.attributes.length > 1 ? (
                                    <DropdownMulti
                                        productData={productData}
                                        onOptionSelect={onOptionSelect}
                                        isOptionOpen={optionOpen}
                                        setIsOptionOpen={setOptionOpen}
                                        doesContainSpace={true}
                                        setLoginModal={setLoginModal}
                                        sizeControl={setFloatingOpen}
                                    />
                                ) : (
                                    <Dropdown
                                        setLoginModal={setLoginModal}
                                        placeholder="상품 옵션을 선택해 주세요"
                                        menuItems={productData.product.product_variations}
                                        onOptionSelect={onOptionSelect}
                                        doesContainSpace={true}
                                        sizeControl={setFloatingOpen}
                                        isOptionOpen={optionOpen}
                                        setIsOptionOpen={setOptionOpen}
                                        productTitle={productData.product.name}
                                        showIcon={true}
                                        slug={slug}
                                    />
                                )}
                            </div>
                        </div>
                        {productData.product &&
                            productData.product.up_sale_products &&
                            optionQuantityData &&
                            optionQuantityData.length > 0 && (
                                productData.product.up_sale_products[0].type === "variable" ?
                                    <div className="upsell-wrapper option-padding">
                                        <div className="upsell-title">{productData.product.add_product_wording}</div>
                                        {productData.product.up_sale_products.map((product, i) => {
                                            return (
                                                <div style={{marginBottom: '8px'}} key={i}>
                                                    <Dropdown
                                                        placeholder="상품 옵션을 선택해 주세요"
                                                        menuItems={
                                                            product.type !== 'simple' ? product.product_variations : [product]
                                                        }
                                                        optionQuantityData={optionQuantityData}
                                                        onOptionSelect={onOptionSelect}
                                                        isOptionOpen={optionOpen}
                                                        setIsOptionOpen={setOptionOpen}
                                                        oesContainSpace={true}
                                                        sizeControl={setFloatingOpen}
                                                        // isDesktop={true}
                                                        // setHomeMobileCartHeight={setHomeMobileCartHeight}
                                                        // homeMobileCartHeight={homeMobileCartHeight}
                                                        menuZIndex={i + 1}
                                                        header={{
                                                            image: product.add_product_thumbnail,
                                                            title: product.name
                                                        }}
                                                        productTitle={product.name}
                                                        showIcon={true}
                                                        product={product}
                                                        optionData={optionQuantityData ? optionQuantityData : null}
                                                        slug={slug}
                                                        upSaleProducts={productData.product && productData.product?.up_sale_products}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div> : <div className={"upsell-only-one-wrapper upsell-option-padding"}>
                                        <div className="upsell-title">{productData.product.add_product_wording}</div>
                                        <div className="upsell-only-one-item-wrapper">
                                            <div className="upsell-only-one-item-info-wrapper">
                                                <div className="upsell-only-one-item-img-wrapper">
                                                    <img
                                                        src={productData.product.up_sale_products[0].thumbnail}/>
                                                </div>
                                                <div className="upsell-only-one-item-description-wrapper">
                                                    <div className="upsell-only-one-item-title">
                                                        {productData.product.up_sale_products[0].name}
                                                    </div>
                                                    {productData.product.up_sale_products[0].on_sale ?
                                                        <div className="upsell-only-one-item-description ">
                                                            <div
                                                                className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                            <div style={{display: 'flex'}}>
                                                                <span
                                                                    className="upsell-only-one-item-description-sale-price">{productData.product.up_sale_products[0].display_price}</span>
                                                                <span
                                                                    className="upsell-only-one-item-description-discount-rate"> {productData.product.up_sale_products[0].discount_rate}</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        ((productData.product.slug === "moodwater-yuja" || productData.product.slug === "moodwater-violet" || productData.product.slug === "moodwater-juniper" || productData.product.slug === "moodwater-variety-3")
                                                        && productData.product.up_sale_products[0].title === "3종 시향 키트" ?
                                                            <div className="upsell-only-one-item-description">
                                                                <div
                                                                    className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                                <div style={{display: 'flex'}}>
                                                                    <span
                                                                        className="upsell-only-one-item-description-sale-price">3,500원</span>
                                                                    <span
                                                                        className="upsell-only-one-item-description-discount-rate">(-30%)</span>
                                                                </div>
                                                            </div> : (productData.product.slug === "collins-incense" && productData.product.up_sale_products[0].id === 150708
                                                            && validationById(34141) ?
                                                                <div className="upsell-only-one-item-description">
                                                                    <div
                                                                        className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                                    <div style={{display: 'flex'}}>
                                                                        <span
                                                                            className="upsell-only-one-item-description-sale-price">1,900원</span>
                                                                        <span
                                                                            className="upsell-only-one-item-description-discount-rate">(-24%)</span>
                                                                    </div>
                                                                </div> : (productData.product.slug === "collins-incense" && productData.product.up_sale_products[0].id === 150709 && !validationById(34141) ?
                                                                    <div className="upsell-only-one-item-description">
                                                                        <div
                                                                            className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                                        <div style={{display: 'flex'}}>
                                                                            <span
                                                                                className="upsell-only-one-item-description-sale-price">2,400원</span>
                                                                            <span
                                                                                className="upsell-only-one-item-description-discount-rate">(-20%)</span>
                                                                        </div>
                                                                    </div>
                                                                    : (productData.product.slug === "incense-chamber-honeybadger" && (productData.product.up_sale_products[0].id === 34136 || productData.product.up_sale_products[0].id === 34137 ||
                                                                        productData.product.up_sale_products[0].id === 34138 || productData.product.up_sale_products[0].id === 34139 || productData.product.up_sale_products[0].id === 34140) ?
                                                                        <div className="upsell-only-one-item-description">
                                                                            <div
                                                                                className="upsell-only-one-item-description-regular-price line-through"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                                            <div style={{display: 'flex'}}>
                                                                                <span
                                                                                    className="upsell-only-one-item-description-sale-price">14,310원</span>
                                                                                <span
                                                                                    className="upsell-only-one-item-description-discount-rate">(-10%)</span>
                                                                            </div>
                                                                        </div> :
                                                                        <div className="upsell-only-one-item-description ">
                                                                            <div
                                                                                className="upsell-only-one-item-description-regular-price"> {productData.product.up_sale_products[0].display_regular_price} </div>
                                                                        </div>))))
                                                    }
                                                    {productData.product.up_sale_products[0].stock_status === 'outofstock' ?
                                                        <div className="upsell-only-one-restocking-button"
                                                             onClick={(event) => {
                                                                 setIsRestockingModalVisible(true);
                                                                 setIsRestockingModalProductId(productData.product.up_sale_products[0].id);
                                                                 event.stopPropagation();
                                                             }}>
                                                            <div className="alarm-img-wrapper">
                                                                <img src={require("../../../img/icon/alarm_black.png")}/>
                                                            </div>
                                                            <div className="alarm-text">
                                                                입고 알림 받기
                                                            </div>
                                                        </div>
                                                        : <div className="upsell-only-one-item-button"
                                                               onClick={() => {
                                                                   let itemName = "";
                                                                   if (productData.product.up_sale_products[0].type === 'simple') {
                                                                       itemName = productData.product.up_sale_products[0].name;
                                                                   } else {
                                                                       itemName = productData.product.up_sale_products[0].attributes[0].option;
                                                                   }
                                                                   if (productData.product.up_sale_products[0].additional_info?.login_required) {
                                                                       if (!isLogin) {
                                                                           setLoginModal(true)
                                                                           return;
                                                                       }
                                                                   }
                                                                   if (productData.product.up_sale_products[0].name) {
                                                                       itemName = productData.product.up_sale_products[0].name + ' - ' + itemName;
                                                                   }
                                                                   onOptionSelect({
                                                                       id: productData.product.up_sale_products[0].id,
                                                                       name: itemName,
                                                                       cnt: 1,
                                                                       img: productData.product.up_sale_products[0].image,
                                                                       regularPrice: productData.product.up_sale_products[0].display_regular_price,
                                                                       price: productData.product.up_sale_products[0].display_price,
                                                                       discountRate: productData.product.up_sale_products[0].discount_rate,
                                                                       onSale: productData.product.up_sale_products[0].on_sale,
                                                                       product_id: productData.product.up_sale_products[0].id,
                                                                       additional_info: productData.product.up_sale_products[0].additional_info ? productData.product.up_sale_products[0].additional_info : null
                                                                   });
                                                               }}>
                                                            함께 구매하기
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            )}
                        <OptionAndTotalPrice
                            optionData={optionQuantityData}
                            onOptionSelect={onOptionSelect}
                            totalPrice={totalPrice}
                            floatingOpen={floatingOpen}
                            isInMobileFloating={true}
                            isForProductNpay={isForProductNpay}
                            mobilePayMode={mobilePayMode}
                            resetOptionInfo={resetOptionInfo}
                            isSimple={isSimple}
                            fromMobile={true}
                            hasUpSale={
                                productData.product &&
                                productData.product.up_sale_products &&
                                productData.product.up_sale_products.length > 0
                            }
                            slug={productData.product.slug}
                            upSaleProducts={productData.product && productData.product?.up_sale_products}
                        />
                    </div>
                </div>
            </div>
            {kakaoGiftList.get(productData?.product?.slug) && <div className={'kakaoGift-button-wrapper'}>
                <div className={'kakaoGift-button'}
                     onClick={() => window.open(kakaoGiftList.get(productData?.product?.slug), '_blank').focus()}>
                    <div className={'gift-icon'}><img
                        src={KakaoGiftLogo}
                    /></div>
                    <div className={'title-wrapper'}>
                        <div className={'text'}>카카오톡 선물하기</div>
                        <div className={'link-icon'}><img src={ExternalIcon}/>
                        </div>
                    </div>
                    <div></div>
                </div>
                <div className={'kakaoGift-subText'}>선물하기 / 일반 구매 모두 가능</div>
            </div>
            }
            {!kakaoGiftList.get(productData?.product?.slug) &&
                productData?.product?.stock_status === "outofstock" &&
                <RestockingButton productId={productData?.product.id}/>
            }
            {/*{!kakaoGiftList.get(productData?.product?.slug) &&*/}
            {/*    productData?.product?.stock_status === "outofstock" && <div className="restocking-button-wrapper">*/}
            {/*        <div className="temporarily-out-of-stock">*/}
            {/*            <div className="temporarily-out-of-stock-text">*/}
            {/*                일시품절*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            {productData?.product?.slug && <div
                className={`mobilefloatingbutton-wrapper ${mobilePayMode === 'npay' ? 'hidden' : ''}   ${
                    isOpen ? 'no-border' : ''
                } `}
                style={{
                    display: (kakaoGiftList.get(productData?.product?.slug) || productData?.product?.stock_status === "outofstock")
                        ? "none" : "visible"
                }}
            >{btns.length > 1 ? btns.map((btn, i) => {
                return (
                    <div className="mobilefloatingbutton-button" key={i}>
                        <CallToActionButton
                            color={btn.buyButtonColor}
                            className="mobileFloating"
                            onClick={btn.clickAction}
                        >
                            {btn.btnDisplay}
                        </CallToActionButton>
                    </div>
                );
            }) : <div className="mobilefloatingbutton-button" style={{width: "100%"}}>
                <CallToActionButton
                    color={btns[0]?.buyButtonColor}
                    className="mobileFloating"
                    onClick={btns[0]?.clickAction}
                >
                    {btns[0]?.btnDisplay}
                </CallToActionButton>
            </div>}
            </div>}
            {isForProductNpay && (<div
                id="iamport-naverpay-product-button4"
                className={`npay-button-cart ${mobilePayMode !== 'npay' ? 'hidden' : ''}`}
                style={{backgroundColor: "#fffff5"}}
                // style={{ display: "none" }}
            ></div>)}
        </div>
    );
};

export default ProductDetailMobileFloatingButtons;
