import React, {Component, FunctionComponent, useEffect, useRef, useState} from 'react';
import './Dropdown.scss';
import {UpArrow, DownArrow} from '../../../assets/Arrows.jsx';
import {OUTOFSTOCK_OPTION_SELECT_TEXT} from '../../constants/constants.js';
import Modal from "../Modal/Modal.component";
import RestockingModal from "../RestockingModal/RestockingModal";
import {AppContext} from "../../context/AppContext";

const Dropdown: FunctionComponent<{
    isLogin?: boolean,
    placeholder?: string,
    menuItems?: any,
    onOptionSelect?: CallableFunction,
    setIsOptionOpen?: CallableFunction,
    isDesktop: boolean,
    isOptionOpen?: boolean,
    setHomeMobileCartHeight?: CallableFunction,
    homeMobileCartHeight?: string | number,
    sizeControl?: any,
    attributeIdx?: number,
    header?: any,
    menuZIndex?: number,
    productTitle?: string,
    selectedParentOption?: any,
    setSelectedParentOption?: CallableFunction,
    showIcon?: boolean,
    product?: any,
    optionData?: any,
    slug?: string,
    upSaleProducts?: any,
    setLoginModal?: (b: boolean) => void
}> = (
    {
        isLogin,
        placeholder,
        menuItems,
        onOptionSelect,
        setIsOptionOpen,
        isOptionOpen,
        setHomeMobileCartHeight,
        sizeControl,
        attributeIdx,
        header,
        menuZIndex,
        productTitle,
        selectedParentOption,
        setSelectedParentOption,
        showIcon,
        product,
        optionData,
        slug,
        upSaleProducts,
        setLoginModal
    }
) => {
    const {modalDataStore} = React.useContext(AppContext);
    const {setIsRestockingModalProductId, setIsRestockingModalVisible} = modalDataStore
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(/webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const dropdownMenuRef = useRef<HTMLDivElement>();

    const closeMenu = () => {
        setIsShowMenu(false);
        sizeControl && sizeControl(false);
        setHomeMobileCartHeight && setHomeMobileCartHeight(false);
    };

    const showMenu = () => {
        // e.preventDefault();
        // document.addEventListener('click', closeMenu);

        setIsShowMenu(true);
        sizeControl && sizeControl(true);
        setHomeMobileCartHeight && setHomeMobileCartHeight(true);

    };

    const closeOnSelection = () => {
        setIsShowMenu(false);
        document.removeEventListener('click', closeMenu);
        sizeControl && sizeControl(false);
        setIsOptionOpen && setIsOptionOpen(false);
        setHomeMobileCartHeight && setHomeMobileCartHeight(false);

        if (selectedParentOption) {
            setSelectedParentOption(null);
        }
    };

    useEffect(() => {
        // if (isDesktop) {
        if (isOptionOpen) {
            // alert(isOptionOpen)
            setIsShowMenu(isOptionOpen);
        }
        // }
        setHomeMobileCartHeight && setHomeMobileCartHeight(isOptionOpen);


    }, []);
    const closeByClikingOthers = () => {
        if (isShowMenu) {
            setIsShowMenu(false);
            sizeControl && sizeControl(false);
            setHomeMobileCartHeight && setHomeMobileCartHeight(false);
        }
    };

    // useEffect(() => {
    //     setTimeout(()=>document.addEventListener('click', closeByClikingOthers),1);
    //     return () => {
    //         document.removeEventListener('click', closeByClikingOthers);
    //     };
    //
    // }, [isShowMenu]);


    if (menuItems && Array.isArray(menuItems)) {
        menuItems = menuItems.sort(function (a, b) {

            const aval = a.stock_status == 'outofstock' ? 1 : 0;
            const bval = b.stock_status == 'outofstock' ? 1 : 0;
            return aval - bval;
        })
    }

    const validationById = (id) => {
        const targetOptionData = optionData?.filter(data => upSaleProducts?.filter(product => product.id === data.id).length === 0)
        if (targetOptionData?.length === 1 && targetOptionData[0].id === id) {
            return true
        }
        return false
    }

    return (
        <div
            className="dropdown-wrapper" style={{zIndex: menuZIndex ? 40 - menuZIndex : 40}}>
            <div className="dropdown-default"
                 style={{height: (header) ? '64px' : '48px'}}
                 data-testid="dropdownDt"
                 onClick={() => {
                     if (!isShowMenu) {
                         showMenu();
                     } else {
                         closeMenu();
                     }

                 }}>
                {
                    (header) ? <div className="dropdown-header">
                            {header.image && <img src={header.image} alt="" width="48" height="48"/>}
                            <span> {header.title} </span>
                        </div> :
                        <span> {placeholder} </span>
                }
                <div
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                >
                    {isShowMenu ? <UpArrow/> : <DownArrow/>}
                </div>
            </div>
            {
                (isShowMenu && menuItems)
                    ? (
                        <div
                            data-testid="dropdownMewnu"
                            className="dropdown-menu"
                            ref={dropdownMenuRef}
                        >
                            {menuItems && menuItems.filter((filterItem) => {
                                if (slug !== "incense-chamber-honeybadger") {
                                    return true
                                } else if (filterItem.id === 34136 || filterItem.id === 34137 ||
                                    filterItem.id === 34138 || filterItem.id === 34139 || filterItem.id === 34140) {
                                    return true
                                } else {
                                    return false
                                }
                            }).map((menuItem, i) => {
                                let itemName = "";
                                if (menuItem.type === 'simple') {
                                    itemName = menuItem.name;
                                } else {
                                    itemName = menuItem.attributes[attributeIdx ? attributeIdx : 0].option;
                                }
                                return (
                                    <div
                                        className={`dropdown-item ${isMobile ? 'mobile' : ''}  `}
                                        data-testid="dropdownItem"
                                        onClick={() => {
                                            if (menuItem.stock_status === 'outofstock') {

                                                alert(OUTOFSTOCK_OPTION_SELECT_TEXT);
                                                return;
                                            }
                                            if (menuItem.additional_info?.login_required) {
                                                if (!isLogin) {
                                                    setLoginModal(true)
                                                    return;
                                                }
                                            }
                                            closeOnSelection();

                                            if (selectedParentOption) {

                                                menuItem.attributes.map((attr) => {

                                                    if (itemName !== '') {
                                                        itemName = itemName + ', ';
                                                    }
                                                    itemName = itemName + attr.name + ' : ' + attr.option;

                                                })
                                                menuItem.attributes[0].name + ' : ' + menuItem.attributes[0].option;

                                            }


                                            if (productTitle) {
                                                itemName = productTitle + ' - ' + itemName;
                                            }

                                            onOptionSelect({
                                                id: menuItem.id,
                                                name: itemName,
                                                cnt: 1,
                                                img: menuItem.image,
                                                regularPrice: menuItem.display_regular_price,
                                                price: menuItem.display_price,
                                                discountRate: menuItem.discount_rate,
                                                onSale: menuItem.on_sale,
                                                product_id: product ? product.id : null,
                                                additional_info: menuItem.additional_info ? menuItem.additional_info : null
                                            });
                                        }}
                                        key={i}>

                                        <div
                                            className={`imgAndName  ${menuItem.stock_status === 'outofstock' ? 'noHover' : ''}`}>
                                            {showIcon && menuItem.image && <img src={menuItem.image} alt=""/>}

                                            <span> {itemName} </span>
                                        </div>
                                        {
                                            menuItem.stock_status === 'outofstock' ?
                                                <div className="outOfStock-wrapper">
                                                    <div className="outOfStock">
                                                        일시품절
                                                    </div>
                                                    <div className="restocking-alarm-wrapper"
                                                         onClick={(event) => {
                                                             setIsRestockingModalVisible(true);
                                                             setIsRestockingModalProductId(menuItem.id);
                                                             event.stopPropagation();
                                                         }}>

                                                        <div className="alarm-img-wrapper">
                                                            <img className="alarm-img"
                                                                 src={require("../../../img/icon/alarm_green_12.png")}/>
                                                        </div>
                                                        <div className="alarm-text">입고 알림 받기</div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    {menuItem.on_sale ?
                                                        <div className="price-infos ">
                                                            <div
                                                                className="regular-price crosslined"> {menuItem.display_regular_price} </div>
                                                            <div>
                                                                <span className="price">{menuItem.display_price}</span>
                                                                <span
                                                                    className="discount-rate"> {menuItem.discount_rate}</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        ((slug === "moodwater-yuja" || slug === "moodwater-violet" || slug === "moodwater-juniper" || slug === "moodwater-variety-3")
                                                        && menuItem.title === "3종 시향 키트" ?
                                                            <div className="price-infos ">
                                                                <div
                                                                    className="regular-price crosslined"> {menuItem.display_regular_price} </div>
                                                                <div>
                                                                    <span className="price">3,500원</span>
                                                                    <span
                                                                        className="discount-rate">(-30%)</span>
                                                                </div>
                                                            </div> : (slug === "collins-incense" && menuItem.id === 150708 && !validationById(34141) ?
                                                                <div className="price-infos ">
                                                                    <div
                                                                        className="regular-price crosslined"> {menuItem.display_regular_price} </div>
                                                                    <div>
                                                                        <span className="price">1,900원</span>
                                                                        <span
                                                                            className="discount-rate">(-24%)</span>
                                                                    </div>
                                                                </div> : (slug === "collins-incense" && menuItem.id === 150709 && !validationById(34141) ?
                                                                    <div className="price-infos ">
                                                                        <div
                                                                            className="regular-price crosslined"> {menuItem.display_regular_price} </div>
                                                                        <div>
                                                                            <span className="price">2,400원</span>
                                                                            <span
                                                                                className="discount-rate">(-20%)</span>
                                                                        </div>
                                                                    </div>
                                                                    : (slug === "incense-chamber-honeybadger" && (menuItem.id === 34136 || menuItem.id === 34137 ||
                                                                        menuItem.id === 34138 || menuItem.id === 34139 || menuItem.id === 34140) ?
                                                                        <div className="price-infos ">
                                                                            <div
                                                                                className="regular-price crosslined"> {menuItem.display_regular_price} </div>
                                                                            <div>
                                                                                <span className="price">14,310원</span>
                                                                                <span
                                                                                    className="discount-rate">(-10%)</span>
                                                                            </div>
                                                                        </div> : <div className="regular-price">
                                                                            {menuItem.display_price}</div>))))
                                                    }
                                                </div>
                                        }

                                    </div>
                                );
                            })
                            }
                        </div>
                    )
                    : (
                        null
                    )
            }

        </div>

    );
};

export default Dropdown;
