
import React, { useState } from 'react'; 
import Spinner from '../../../assets/Spinner.jsx';
import './SpinnerControl.scss';


const SpinnerControl = ({children, isSpinnerShow}) =>{

    


  return (
    <>
      <div className='spinner-wrapper'>
        {children}
      </div>

      {isSpinnerShow &&
        <Spinner />
      }
    </>

  );





};

export default SpinnerControl;