import {makeAutoObservable, toJS} from 'mobx';
import {collectionMeta} from '../@types/types';
import {fetchAndReceiveJSON, moveToAnchor} from '../utils/utils';
import {apiUrl} from "../constants/constants";

const C_COLLECTION_ID = null;
const C_SUB_COLLECTION_ID = null;

export class CollectionPageStore {
    collectionNow = {
        id: C_COLLECTION_ID ? C_COLLECTION_ID : 'all',
        title: null,
        titleDescription: null,
        promoText: '프로모션 문구 테스트',
        url: 'test1',
        subCategories: [],
        subCategory: {
            slug: '',
            term_id: C_SUB_COLLECTION_ID ? C_SUB_COLLECTION_ID : 0,
            collection_id: 0,
            name: '',
        },
    };

    sortCriteria = '기본순';
    isControlSticky = false;
    allCollections = [];
    collectionItems = [];
    subCollectionItems = [];
    defaultCollectionTitle = '';
    collectionPromoText = '';
    titleDescriptionDefault = '';
    totalItemCnt = 0;
    maxPage = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setTotalItemCnt = (cnt: number) => {
        this.totalItemCnt = cnt;
    };

    setCollectionNow = (collectionMeta: collectionMeta) => {
        if (collectionMeta.subCategory == null) {
            collectionMeta.subCategory = {
                slug: '',
                term_id: C_SUB_COLLECTION_ID ? C_SUB_COLLECTION_ID : 0,
                collection_id: 0,
                name: '',
            };
        }

        this.collectionNow = collectionMeta;
    };

    setSortCriteria = (crietria: string) => {
        this.sortCriteria = crietria;
    };

    setIsControlSticky = (sticky: boolean) => {
        this.isControlSticky = sticky;
    };

    setTitleDescription = (titleDesc: string) => {
        this.titleDescriptionDefault = titleDesc;
    };

    setAllCollections = (collections) => {
        const allFilter = {
            term_id: 0,
            collection_id: 0,
            slug: '',
            name: 'All',
        };

        for (let i = 0; i < collections.length; i++) {
            const collection = collections[i];

            if (collection.subCategories !== null && collection.subCategories.length > 0) {
                allFilter.collection_id = collection.id;
                collection.subCategories.unshift(allFilter);

                if (C_SUB_COLLECTION_ID !== null && C_SUB_COLLECTION_ID !== undefined) {
                    for (const j in collection.subCategories) {
                        if (C_SUB_COLLECTION_ID === collection.subCategories[j].term_id) {
                            collection.subCategory = collection.subCategories[j];
                            break;
                        }
                    }
                } else {
                    collection.subCategory = allFilter;
                }
            }

            if (collection.id == this.collectionNow.id) {

                this.collectionNow = collection;

                // if(this.collectionNow.subCategories !== undefined && this.collectionNow.subCategories.length > 0) {
                //   this.collectionNow.subCategories.map((subCategory , i) => {
                //     if(subCategory.term_id == this.subCollectionNow.term_id) {
                //       this.setSubCollectionNow(subCategory);
                //     }
                //   })
                // }
            }
        }

        this.allCollections = collections;
    };

    callAllCollections = async () => {
        const res = await fetchAndReceiveJSON({
            url: apiUrl + '/api/v2/collection/all',
            method: 'GET',
            body: '',
        });

        this.setAllCollections(res.data);
    };

    public getCollectionTitle() {
        const paths: Array<string> = [];

        const title = this.collectionNow.title ? this.collectionNow.title : this.defaultCollectionTitle;
        paths.push(title);

        for (const i in this.collectionNow.subCategories) {
            if (this.collectionNow.subCategories[i].isSelected) {
                paths.push(this.collectionNow.subCategories[i].name);
            }
        }

        if (this.collectionNow.subCategory && this.collectionNow.subCategory.name !== '') {
            paths.push(this.collectionNow.subCategory.name);
        }

        return paths;
    }

    private convertType = (sortType: string) => {
        if (sortType === '기본순') {
            return 'regular';
        }
        if (sortType === '인기순') {
            return 'hot';
        }
        if (sortType === '높은할인순') {
            return 'sale';
        }
        if (sortType === '리뷰순') {
            return 'review';
        }
        if (sortType === '높은가격순') {
            return 'priceDs';
        }
        if (sortType === '낮은가격순') {
            return 'priceAs';
        }
    };

    callCollectionItems = async (id) => {
        const sortType = this.convertType(this.sortCriteria);

        // let subCollectionNow = {
        //   'slug' : '',
        //   'term_id' : C_SUB_COLLECTION_ID ? C_SUB_COLLECTION_ID : 0,
        //   'collection_id' : 0,
        //   'name' : ''
        //   // 'collection' : {}
        // };
        // console.log(this.collectionNow);
        // for(let i=0; i<this.collectionNow.subCategories.length; i++) {
        //   const subCollection = this.collectionNow.subCategories[i];
        //   if(subCollection.isSelected) {
        //     subCollectionNow = subCollection;
        //   }
        // }
        // console.log(subCollectionNow);
        // const subCollectionNot = this.collectionNow.subCategories.filter

        // console.log(toJS(this.collectionNow));

        //TODO 왜 숫자가 박혀 있지?
        let url = apiUrl + `/api/v2/collection/list?id=${
            id ? id : this.collectionNow.id
        }&type=${sortType}`;
        if (this.collectionNow.subCategory) {
            url += `&sub_id=${this.collectionNow.subCategory.term_id}`;
        }
        const res = await fetchAndReceiveJSON({url: url, method: 'GET', body: ''});
        this.collectionItems = res.data.data.list;
        this.defaultCollectionTitle = res.data.data.title;
        this.collectionPromoText = res.data.data.promoText;
        this.titleDescriptionDefault = res.data.data.titleDescription;
        this.totalItemCnt = res.data.pageinfo.itemCnt;

        this.maxPage = res.data.pageinfo.totalPageCnt;
        // this.testCnt++;
        moveToAnchor(500);
    };
}
