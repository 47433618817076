import React, {useEffect, useMemo, useState} from 'react';
import {BrownCloseIcon} from '../../../assets/CloseIcon';
import {Minus, Plus} from '../../../assets/Control.jsx';
import './OptionAndTotalPrice.scss';
import Modal from "../Modal/Modal.component";
import QuestionMarkIcon from "../../../img/question_mark.svg"


import BubbleContent from '../BubbleContent/BubbleContent.component.tsx';

const OptionAndTotalPrice = ({
                                 isSimple,
                                 optionData,
                                 onOptionSelect,
                                 totalPrice,
                                 isInMobileFloating,
                                 floatingOpen,
                                 fromMobile,
                                 slug,
                                 hasUpSale,
                                 upSaleProducts,
                                 resetOptionInfo
                             }) => {
    const isBulkInfoShow = useMemo(() => {
        const found = ['a-card', 'a-card-5-pack', 'a-card-gift-set-2021cal', 'a-card-family-pack'].find((item) => item === slug);

        return found;
    }, [slug]);
    let optionsStyle = {};

    if (floatingOpen) {
        optionsStyle['minHeight'] = '160px';
    }

    if (!isSimple && optionData.length > 0) {
        optionsStyle['marginBottom'] = '20px';
    }
    const [onePurchaseModal, setOnePurchaseModal] = useState(false);

    useEffect(() => {
        if (hasUpSale && optionData.length > 0 && optionsValidation()) {
            resetOptionInfo()
        }
    }, [optionData])

    const optionsValidation = () => {
        if (optionData?.filter(data => upSaleProducts?.some(product => product.id === data.id)).length === optionData.length) {
            return true
        } else {
            return false
        }
    }

    const validationById = (id) => {
        const targetOptionData = optionData?.filter(data => upSaleProducts?.filter(product => product.id === data.id).length === 0)
        if (targetOptionData.length === 1 && targetOptionData[0].id === id) {
            return true
        }
        return false
    }


    const countSaleProduct = (name, cost) => {
        let sum = 0;
        const targetProduct = optionData.filter(data => data.name.includes(name))
        targetProduct.map(product => sum += product.quantity * cost)
        return sum
    }

    const optionNameConverter = (name) => {
        const basicName = name.split(" - ");
        if (basicName[0] === basicName[1]) {
            return basicName[0]
        } else {
            return name
        }
    }
    return (<div className={'chosen-options-and-total'}>
        {onePurchaseModal && <Modal
            text={`해당 제품은 1회에 1개씩만 구매 가능합니다.`}
            buttonText={`확인`}
            buttonAction={() => {
                setOnePurchaseModal(false);
            }}
            closeAction={() => {
                setOnePurchaseModal(false);
            }}
        />}
        <div
            className={` ${isInMobileFloating ? 'minHeight-scroll option-padding ' : ""}`}
            style={{minHeight: isInMobileFloating ? (isSimple ? "35px" : "15px") : ""}}
        >
            {// 선택된 옵션들이 나오는 곳
                optionData.map((item, i) => {
                    return item ? (<div key={item.id}>
                        <div
                            className={`selected-option-wrapper ${isSimple && !hasUpSale ? 'simple' : ''} ${isInMobileFloating ? 'floating' : ''}`}>
                            {!isSimple && (<div className="selecte-option-top">
                      <span className="selecte-option-img-and-name">
                        {item.img && <img className="option-img" src={item.img} alt=""/>}
                          <span className="option-name"> {optionNameConverter(item.name)} </span>
                      </span>
                                <div
                                    className="close-btn"
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    onClick={() => {
                                        onOptionSelect({id: item.id, cnt: 0});
                                    }}
                                >
                                    {' '}
                                    <BrownCloseIcon/>
                                </div>
                            </div>)}

                            {isSimple && hasUpSale && (<div className="selecte-option-top">
                      <span className="selecte-option-img-and-name">
                        <span className="option-name"> {item.name}</span>
                      </span>
                                {i > 0 && (<div
                                    className="close-btn"
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    onClick={() => {
                                        onOptionSelect({id: item.id, cnt: 0});
                                    }}
                                >
                                    {' '}
                                    <BrownCloseIcon/>
                                </div>)}
                            </div>)}

                            <div className="selected-option-bottom">
                                <div className="quantity-control">
                      <span
                          className="minus"
                          onClick={() => {
                              if (item.quantity === 1) {
                                  return;
                              }
                              onOptionSelect({id: item.id, cnt: -1});
                          }}
                      >
                        <Minus/>
                      </span>
                                    <span className="quantity">
                        {/* <input type = 'number'
                          pattern="\d*"
                        /> */}
                                        <input
                                            style={{
                                                textAlign: 'center', width: '30px', height: "fit-content"
                                            }}
                                            type="number"
                                            pattern="\d*"
                                            className="quantityInput"
                                            value={item.quantity}
                                            onChange={(e) => {
                                                if (e.target.value === '') {
                                                    onOptionSelect({id: item.id, cnt: 0, isDirectChange: true});
                                                    // e.target.value = 0;
                                                    return;
                                                }
                                                onOptionSelect({
                                                    id: item.id, cnt: parseInt(e.target.value), isDirectChange: true,
                                                });
                                            }}/>
                      </span>
                                    <span
                                        className="plus"
                                        onClick={() => {
                                            if (item) {
                                                if (item.additional_info && item.additional_info.one_purchased) {
                                                    setOnePurchaseModal(true)
                                                    return;
                                                }
                                            }
                                            onOptionSelect({id: item.id, cnt: 1});
                                        }}>
                        <Plus/>
                      </span>
                                </div>
                                {!isSimple || fromMobile || (isSimple && hasUpSale) ? (<div className="price-info">
                                    {item.onSale ? (<>
                                                        <span
                                                            className="regular-price crosslined"> {item.regularPrice} </span>
                                        <span className="price"> {item.price} </span>
                                        <span className="discount-rate"> {item.discountRate} </span>
                                    </>) : ((slug?.includes("moodwater-yuja") || slug?.includes("moodwater-violet") || slug?.includes("moodwater-juniper") || slug?.includes("moodwater-variety-3"))
                                    && !optionsValidation() && item.name === "[MOOD WATER] 3종 시향 키트 - [MOOD WATER] 3종 시향 키트" ?
                                        <>
                                            <span className="regular-price crosslined"> {item.regularPrice} </span>
                                            <span className="price">3,500원</span>
                                            <span className="discount-rate">(-30%)</span>
                                        </> : (slug?.includes("collins-incense") && !optionsValidation() && item.id === 150708 && !validationById(34141) ?
                                            <>
                                                <span className="regular-price crosslined"> {item.regularPrice} </span>
                                                <span className="price">1,900원</span>
                                                <span className="discount-rate">(-24%)</span>
                                            </> : (slug?.includes("collins-incense") && !optionsValidation() && item.id === 150709 && !validationById(34141) ?
                                                <>
                                                    <span
                                                        className="regular-price crosslined"> {item.regularPrice} </span>
                                                    <span className="price">2,400원</span>
                                                    <span className="discount-rate">(-20%)</span>
                                                </> : (slug === "incense-chamber-honeybadger" && (item.id === 34136 || item.id === 34137 ||
                                                    item.id === 34138 || item.id === 34139 || item.id === 34140) ? <>
                                                    <span
                                                        className="regular-price crosslined"> {item.regularPrice} </span>
                                                    <span className="price">14,310원</span>
                                                    <span className="discount-rate">(-10%)</span>
                                                </> : <span className="regular-price"> {item.price} </span>))))}
                                </div>) : (<div className="price-info"></div>)}
                            </div>
                        </div>
                    </div>) : null;
                })}
        </div>

        {(optionData.length > 0 || isInMobileFloating) && (<div className="totalAndPaymentButtonWrapper">
            <div
                style={{
                    marginTop: (isSimple || isInMobileFloating) ? '30px' : '20px',
                    width: "100%",
                    backgroundColor: isInMobileFloating ? "#fffff5" : "inherit",
                    bottom: 0,
                }}
                className={`total-price ${isInMobileFloating ? 'add-padding-margin mobile-position' : ''} `}
            >
                <span className="total-text">구매 상품 금액 합</span>
                {(slug?.includes("moodwater-yuja") || slug?.includes("moodwater-violet") || slug?.includes("moodwater-juniper") || slug?.includes("moodwater-variety-3")) && !optionsValidation() && optionData.some(data => data.name.includes("3종 시향 키트")) ?
                    <span>{(totalPrice - countSaleProduct("3종 시향 키트", 1500)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span> :
                    slug?.includes("incense") && !optionsValidation() && optionData.some(data => data.name.includes("콜린스 미니 라이터")) && !validationById(34141) ?
                        <span>{(totalPrice - countSaleProduct("콜린스 미니 라이터", 600)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span> :
                        slug?.includes("incense-chamber-honeybadger") && optionData.some(data => data.name.includes("[5가지 향] 콜린스 인센스")) ?
                            <span>{(totalPrice - countSaleProduct("[5가지 향] 콜린스 인센스", 1590)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span> :
                            <span>{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                }
            </div>
        </div>)}
        {!isInMobileFloating && isBulkInfoShow && (<div className="bubblePosition">
            <BubbleContent
                bubbleTarget={<div className="optionAndTotalBubbleTarget" id={'bubbleContent'}
                >
                    <span>커스터마이즈/대량 구매 안내 </span>
                    <img src={QuestionMarkIcon} alt=""/>
                </div>}
                bubbleContent={<div className="noBubbleClose optionAndTotalBubble">
                    [a Card] 휴대용 손소독제의 대량 구매 또는 커스터마이즈 주문을 원하실 경우
                    문의해주세요.
                    <a
                        href="https://www.notion.so/Collins-c69293f0367e4ca8a05a7b8010328bd9"
                        // target="_blank"
                    >
                        문의하기
                    </a>
                </div>}
                autoHide={true}
            />
        </div>)}
    </div>);
};

export default OptionAndTotalPrice;
