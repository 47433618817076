import React from 'react';

export const WhiteCloseIcon = ({color}) => {

    return (
        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" style={{cursor: 'pointer'}}>
            <g id="MO/btn/gnb_close_32" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                <rect id="Rectangle" fill={color ? color : "#FFFFF5"}
                      transform="translate(16.000000, 16.750000) rotate(45.000000) translate(-16.000000, -16.750000) "
                      x="5" y="16" width="22" height="1.5"></rect>
                <rect id="Rectangle" fill={color ? color : "#FFFFF5"}
                      transform="translate(16.000000, 16.750000) scale(-1, 1) rotate(45.000000) translate(-16.000000, -16.750000) "
                      x="5" y="16" width="22" height="1.5"></rect>
            </g>
        </svg>
    );
}


export const BrownCloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="#C0A691" fillRule="evenodd">
                <path d="M-1 5H11V6H-1z" transform="translate(3 2.5) rotate(45 5 5.5)"/>
                <path d="M-1 5H11V6H-1z" transform="translate(3 2.5) scale(-1 1) rotate(45 0 -6.571)"/>
            </g>
        </svg>
    );
}


export const BigBrownCloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H40V40H0z"/>
                <path fill="#604448" d="M6.25 20H33.75V21.6H6.25z" transform="rotate(45 20 20.8)"/>
                <path fill="#604448" d="M6.25 20H33.75V21.6H6.25z" transform="scale(-1 1) rotate(45 0 -27.484)"/>
            </g>
        </svg>
    );
}

export const MediumBrownCloseIcon = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <path d="M0 0H32V32H0z" transform="translate(-312 -12) translate(312 12)"/>
                    <path fill="#604448" d="M5 16H27V17.5H5z"
                          transform="translate(-312 -12) translate(312 12) rotate(45 16 16.75)"/>
                    <path fill="#604448" d="M5 16H27V17.5H5z"
                          transform="translate(-312 -12) translate(312 12) scale(-1 1) rotate(45 0 -21.877)"/>
                </g>
            </g>
        </g>
    </svg>)
}


export const SmallCloseIconWidthBackground = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <path fill="#604448" d="M0 0H16V16H0z" opacity=".798"
                          transform="translate(-104 -561) translate(104 561)"/>
                    <g fill="#FFFFF5">
                        <path d="M3 0L3.7 0 3.7 8 3 8z"
                              transform="translate(-104 -561) translate(104 561) translate(4.548 4) rotate(-42 3.35 4)"/>
                        <path d="M3 0L3.7 0 3.7 8 3 8z"
                              transform="translate(-104 -561) translate(104 561) translate(4.548 4) scale(-1 1) rotate(-42 0 12.727)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>)
}


export const MidSmallBrownCloseIcon = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <path d="M0 0H24V24H0z" transform="translate(-304 -217) translate(304 217)"/>
                        <path fill="#604448" d="M3.75 12H20.25V13.125H3.75z"
                              transform="translate(-304 -217) translate(304 217) rotate(45 12 12.563)"/>
                        <path fill="#604448" d="M3.75 12H20.25V13.125H3.75z"
                              transform="translate(-304 -217) translate(304 217) scale(-1 1) rotate(45 0 -16.408)"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}
