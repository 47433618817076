import React from "react"

export const SearchIconColor = ({color}) => {
    return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h40v40H0z"/>
                <g transform="translate(7.5 8.75)" stroke="#F6EDDE" strokeWidth="1.875">
                    <circle cx="11.25" cy="11.25" r="10.313" stroke={color}/>
                    <path d="m19.375 19.375 5 4.375" stroke={color}/>
                </g>
            </g>
        </svg>

    )
}