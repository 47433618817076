import React from 'react';

// 여기다가 반응형을 집어 넣어도 좋지 않을까

export const CartWithNumberDt = ({number}) => {
    return (
        <div style={{position: 'relative', cursor: 'pointer', height: '48', display: 'flex', alignItems: "center"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0H40V40H0z"/>
                    <g stroke="#604448" strokeWidth="1.5" transform="translate(10 7.5)">
                        <rect width="18.5" height="18.5" x=".75" y="5.75" rx="1.25"/>
                        <path d="M5 8.75V5c0-2.761 2.239-5 5-5s5 2.239 5 5v3.75"/>
                    </g>
                </g>
            </svg>

            <div
                style={{
                    width: '14px',
                    height: '14px',
                    borderRadius: '50%',
                    backgroundColor: '#604448',
                    color: '#ffffff',
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '9px',
                    fontWeight: 800,
                    position: 'absolute',
                    right: '4px',
                    top: '7px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: `${number <= 0 ? 'none' : 'flex'}`,
                }}
            >
        <span
            style={{
                transform: 'translate(0.2px, 0.5px)',
            }}
        >
          {number}
        </span>
            </div>
        </div>
    );
};

export const CartWithNumberMo = ({number}) => {
    return (
        <div style={{position: 'relative', cursor: 'pointer', height: '32px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <path d="M0 0H32V32H0z" transform="translate(-270 -12) translate(270 12)"/>
                            <g
                                stroke="#604448"
                                strokeWidth="1.3"
                                transform="translate(-270 -12) translate(270 12) translate(8 6)"
                            >
                                <rect width="14.7" height="14.7" x=".65" y="4.65" rx="1"/>
                                <path d="M4 7V4c0-2.21 1.79-4 4-4s4 1.79 4 4v3"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>

            <div
                style={{
                    width: '13px',
                    height: '13px',
                    borderRadius: '50%',
                    backgroundColor: '#604448',
                    color: '#ffffff',
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '9px',
                    fontWeight: 800,
                    position: 'absolute',
                    right: '2px',
                    top: '6px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: `${number <= 0 ? 'none' : 'flex'}`,
                }}
            >
        <span
            style={{
                transform: 'translate(0.4px, 0.4px)',
            }}
        >
          {number}
        </span>
            </div>
        </div>
    );
};

export const CartIconIncenseVerDt = ({number}) => {
    return (
        <div style={{position: 'relative', cursor: 'pointer', height: '48'}}>
            <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h40v40H0z"/>
                    <g id="cartIconGTag" transform="translate(10 7.5)" stroke="#F6EDDE" strokeWidth="1.5">
                        <rect x=".75" y="5.75" width="18.5" height="18.5" rx="1.25"/>
                        <path d="M5 8.75V5a5 5 0 1 1 10 0v3.75"/>
                    </g>
                </g>
            </svg>

            <div
                id="homeCartIconSearchOpened"
                style={{
                    width: '14px',
                    height: '14px',
                    borderRadius: '50%',
                    backgroundColor: '#F6EDDE',
                    color: '#000',
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '9px',
                    fontWeight: 800,
                    position: 'absolute',
                    right: '4px',
                    top: '7px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: `${number <= 0 ? 'none' : 'flex'}`,
                }}
            >
        <span
            style={{
                transform: 'translate(0.2px, 0.5px)',
            }}
        >
          {number}
        </span>
            </div>
        </div>
    );
};

export const CartIconIncenseVerMo = ({number}) => {
    return (
        <div style={{position: 'relative', cursor: 'pointer', height: '32px'}}>
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h32v32H0z"/>
                    <g transform="translate(8 6)" stroke="#ECE0CE" strokeWidth="1.5">
                        <rect x=".75" y="4.75" width="14.5" height="14.5" rx="1"/>
                        <path d="M4 7V4a4 4 0 1 1 8 0v3"/>
                    </g>
                </g>
            </svg>

            <div
                style={{
                    width: '13px',
                    height: '13px',
                    borderRadius: '50%',
                    backgroundColor: '#F6EDDE',
                    color: '#000',
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '9px',
                    fontWeight: 800,
                    position: 'absolute',
                    right: '2px',
                    top: '6px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: `${number <= 0 ? 'none' : 'flex'}`,
                }}
            >
        <span
            style={{
                transform: 'translate(0.4px, 0.4px)',
            }}
        >
          {number}
        </span>
            </div>
        </div>
    );
};


export const CartWithNumberDtChangeColor = ({number, color, fontColor}) => {
    return (
        <div style={{position: 'relative', cursor: 'pointer', height: '48', display: 'flex', alignItems: "center"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0H40V40H0z"/>
                    <g stroke={color} strokeWidth="1.5" transform="translate(10 7.5)">
                        <rect width="18.5" height="18.5" x=".75" y="5.75" rx="1.25"/>
                        <path d="M5 8.75V5c0-2.761 2.239-5 5-5s5 2.239 5 5v3.75"/>
                    </g>
                </g>
            </svg>

            <div
                style={{
                    width: '14px',
                    height: '14px',
                    borderRadius: '50%',
                    backgroundColor: color,
                    color: fontColor,
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '9px',
                    fontWeight: 800,
                    position: 'absolute',
                    right: '4px',
                    top: '7px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: `${number <= 0 ? 'none' : 'flex'}`,
                }}
            >
        <span
            style={{
                transform: 'translate(0.2px, 0.5px)',
            }}
        >
          {number}
        </span>
            </div>
        </div>
    );
};

export const CartWithNumberMoChangeColor = ({number, color, fontColor}) => {
    return (
        <div style={{position: 'relative', cursor: 'pointer', height: '32px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <path d="M0 0H32V32H0z" transform="translate(-270 -12) translate(270 12)"/>
                            <g
                                stroke={color}
                                strokeWidth="1.3"
                                transform="translate(-270 -12) translate(270 12) translate(8 6)"
                            >
                                <rect width="14.7" height="14.7" x=".65" y="4.65" rx="1"/>
                                <path d="M4 7V4c0-2.21 1.79-4 4-4s4 1.79 4 4v3"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>

            <div
                style={{
                    width: '13px',
                    height: '13px',
                    borderRadius: '50%',
                    backgroundColor: color,
                    color: fontColor,
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '9px',
                    fontWeight: 800,
                    position: 'absolute',
                    right: '2px',
                    top: '6px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: `${number <= 0 ? 'none' : 'flex'}`,
                }}
            >
        <span
            style={{
                transform: 'translate(0.4px, 0.4px)',
            }}
        >
          {number}
        </span>
            </div>
        </div>
    );
};
