import React, {useEffect, useMemo, useRef, useState} from 'react';
import PurchaseMenu from '../PurchaseMenu/PurchaseMenu.component.jsx';
import ImageSlider from '../ImageSlider/ImageSlider.component.jsx';
import {
    BigBrownCloseIcon,
    BrownCloseIcon,
    MediumBrownCloseIcon,
    WhiteCloseIcon,
} from '../../../assets/CloseIcon';
import './CartPopUp.scss';
import CTAButton from '../CTAButton/CTAButton.component.jsx';
import {apiUrl, cartAddurl, kakaoGiftList, NpayButtonKey} from '../../constants/constants.js';
import PopupWrapper from '../PopupWrapper/PopupWrapper.component.jsx';
import Modal from '../Modal/Modal.component.jsx';
import {
    buyNowNpayAction,
    getProductsForNpayZzim,
    makeNpayButton,
} from '../../utils/paymentUtils.js';
import {
    addDenom,
    checkCSSHasSafeArea,
    createCh,
    deleteCh,
    fetchAndReceiveJSON, filterThumbnailImageByFormat,
    moveToLink
} from '../../utils/utils.js';
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import RestockingButton from "../RestockingButton/RestockingButton";

// 스테이트  관리가  메임 프로덕트 수준에서 이루어 져야 한다.
// 기존에 한걸어떻게 가져올 수 있지?

let C_IAMPORT_CODE = process.env.REACT_APP_IAMPORT_CODE;


const CartPopUp = ({
                       productData,
                       imageData,
                       closeAction,
                       visible,
                       callCartCount,
                       setIsSpinnerShow,
                       isInSearch,
                       isLogin,
                   }) => {
    let [optionInfo, setOptionInfo] = useState([]);
    const [loginModal, setLoginModal] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isUpSellingPopUp, setIsUpSellingPopUp] = useState(false);
    const [upSellDiscountRate, setUpSellDiscountRate] = useState('%');
    const [upSellText1, setUpSellText1] = useState('');
    const [upSellText2, setUpSellText2] = useState('');
    const [upSellLink, setUpSellLink] = useState('');
    const [randkey, setRnadkey] = useState(1);
    const [cartModal, setCartModal] = useState(false)
    const [upSellImage, setUpSellImage] = useState('');
    const [upSellButtonText, setUpSellButtonText] = useState('');
    const [cartSuccsessModalShow, setCartSuccsessModalShow] = useState(false);
    const [homeMobileCartHeight, setHomeMobileCartHeight] = useState(false);
    const [totalOptionCount, setTotalOptionCount] = useState(0);
    const [isMinAmountNotMet, setIsMinAmountNotMet] = useState(false);
    const [onePurchaseModal, setOnePurchaseModal] = useState(false);
    const [customAlert, setCustomAlert] = useState(null);
    const {cartDataStore} =
        React.useContext(AppContext);
    const cartQuantity = cartDataStore.cartQuantity
    // const deleteOption = async (id) => {
    //   const newOptionInfo = optionInfo.filter((option) => option && option.id !== id);
    //   setOptionInfo(newOptionInfo);
    // };

    const type = useMemo(() => productData.product && productData.product.type, [productData]);
    const buttonWrapperRef = useRef();

    const infoRef = useRef(optionInfo);
    const productDataRef = useRef(productData);
    const totalPriceRef = useRef(0);
    const navigate = useNavigate()
    useEffect(() => {
        infoRef.current = optionInfo;

        // calculateTotal();
    }, [optionInfo]);

    useEffect(() => {
        productDataRef.current = productData;
    }, [productData]);

    useEffect(() => {
        totalPriceRef.current = totalPrice;
    }, [totalPrice]);

    useEffect(() => {
        if (type === 'simple') {
            adjustIndividualOptionCount({
                id: productDataRef.current.product.id,
                name: productDataRef.current.product.title,
                quantity: 1,
            });
            cartOpenOrAction();
            setOptionInfo([]);
        }
    }, [productData]);


    const adjustIndividualOptionCount = async ({
                                                   id,
                                                   name,
                                                   cnt,
                                                   img,
                                                   regularPrice,
                                                   price,
                                                   discountRate,
                                                   onSale,
                                                   isDirectChange,
                                                   product_id,
                                                   additional_info,

                                               }) => {

        // 무조건 스테이트 변화를 만들어서 하위 컴포넌트 업데이트 시키는 용...
        setRnadkey(Math.random());
        let isExist = false;

        optionInfo.forEach((item) => {
            if (item && item.id === id) {
                isExist = true;
            }
        });

        if (isDirectChange) {
            let newOptionInfo = [];
            optionInfo.forEach((option) => {
                if (option && option.id === id) {
                    if (additional_info?.one_purchased) {
                        if (cnt === 1) {
                            newOptionInfo.push({...option, quantity: option.quantity})
                            setOnePurchaseModal(true)
                            return;
                        }
                    }
                    if (option.quantity + cnt > 0) {
                        newOptionInfo.push({...option, quantity: cnt});
                    }
                } else {
                    // 아이디가 다른건 그냥 둔다.
                    newOptionInfo.push(option);
                }
            });
            //optionInfo = newOptionInfo;

            setOptionInfo([...newOptionInfo]);
            // calculateTotal();
            return;
        }

        if (isExist) {
            // 0개가 들어오면 삭제
            if (cnt === 0) {
                // deleteOption(id);
                const newOptionInfo = optionInfo.filter((option) => option && option.id !== id);

                setOptionInfo([...newOptionInfo]);
                return;
            }

            let newOptionInfo = [];
            optionInfo.forEach((option) => {
                if (option && option.id === id) {
                    if (additional_info?.one_purchased) {
                        if (cnt === 1) {
                            newOptionInfo.push({...option, quantity: option.quantity})
                            setOnePurchaseModal(true)
                            return;
                        }
                    }
                    // 마이너스 값이 들어오는 상황을 고려, 개수가 0 이하면 없어진다. => 더이상 사용하지 않는 기능
                    if (option.quantity + cnt > 0) {
                        newOptionInfo.push({...option, quantity: option.quantity + cnt});
                    }
                } else {
                    // 아이디가 다른건 그냥 둔다.
                    newOptionInfo.push(option);
                }
            });

            setOptionInfo([...newOptionInfo]);
        } else {
            optionInfo.push({
                id: id,
                name: name,
                quantity: 1,
                img: img,
                regularPrice: regularPrice,
                price: price,
                discountRate: discountRate,
                onSale: onSale,
                product_id: product_id,
                additional_info: additional_info
            });
            // 여기까지만 optionInfo로 인지를 하고 다음 차례부터는 아예 다른걸 가져가는 듯, 다음 push부터는 완전 다른 push 니까

            setOptionInfo([...optionInfo]);
            // calculateTotal();
        }
    };

    const calculateTotal = () => {
        let total = 0;
        let totalCount = 0;

        // if (optionInfo[0]?.price) {
        optionInfo.forEach((option) => {
            total += Number(option.price.replace(',', '').replace('원', '')) * option.quantity;
            totalCount += option.quantity;
        });

        setTotalPrice(total);
        setTotalOptionCount(totalCount);
        if (productData.product && productData.product.slug === 'a-card') {
            if (totalCount >= 3) {
                callUpSellingPopUp();
            }
        } else if (productData.product && productData.product.slug === 'a-card-5-pack') {
            if (totalCount >= 2) {
                callUpSellingPopUp();
            }
        }
        // }
    };

    const callUpSellingPopUp = () => {
        const sessionCheckForACard = sessionStorage.getItem('didUpSellingPopUpACard');
        const sessionCheckFor5Set = sessionStorage.getItem('didUpSellingPopUp5Set');
        if (!sessionCheckForACard && productData.product.slug === 'a-card') {
            sessionStorage.setItem('didUpSellingPopUpACard', 'true');
            setIsUpSellingPopUp(true);
            return;
        }

        if (!sessionCheckFor5Set && productData.product.slug === 'a-card-5-pack') {
            sessionStorage.setItem('didUpSellingPopUp5Set', 'true');
            setIsUpSellingPopUp(true);
            return;
        }
    };

    useEffect(() => {
        if (productData.product && productData.product.slug === 'a-card-5-pack') {
            setUpSellDiscountRate('-38%');
            setUpSellText1('더 넉넉하고 저렴하게 구매해보세요.');
            setUpSellText2(`패밀리 팩으로 구매하면, 
                        더욱 저렴한 가격으로 구매하실 수 있어요.`);
            setUpSellLink('/product/a-card-family-pack');
            setUpSellImage('/img/a_card_family_upsell.png');
            setUpSellButtonText('패밀리 팩 구매하러 가기');
        } else if (productData.product && productData.product.slug === 'a-card') {
            setUpSellDiscountRate('-25%');
            setUpSellText1('더 넉넉하고 저렴하게 구매해보세요.');
            setUpSellText2(`세트로 구매하면,
                        더욱 저렴한 가격으로 구매하실 수 있어요.`);
            setUpSellLink('/product/a-card-5-pack');
            setUpSellImage('/img/a_card_5_upsell.png');
            setUpSellButtonText('세트 구매하러 가기');
        }
    }, [productData]);


    const resetOptionInfo = () => {
        setOptionInfo([])
    }

    const buyNowAction = async () => {
        if (optionInfo.length === 0) {
            alert('옵션을 선택해야 합니다');
        } else {
            setIsSpinnerShow(true);
            const dataToSend = makeDataForCart(optionInfo);
            const res = await fetch(apiUrl + '/api/cart/buynow', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(dataToSend),
            });

            const data = await res.json();
            if (res.status === 200) {
                setIsSpinnerShow(false);
                window.location.href = '/cart';
            } else {
                alert(data.message);
                setIsSpinnerShow(false);
            }
        }
    };

    const makeDataForCart = (options) => {
        const res = [];
        options.forEach((option) => {
            res.push({
                product_id: option.product_id ? option.product_id : productDataRef.current.product.id,
                variation_id: option.variation_id ? option.variation_id : option.id === productDataRef.current.product.id ? null : option.id,
                quantity: option.quantity,
            });
        });
        return res;
    };

    const cartOpenOrAction = async () => {
        if (optionInfo.length === 0) {
            alert('옵션을 선택해야 합니다');
        } else {
            setIsSpinnerShow(true);
            const dataToSend = makeDataForCart(optionInfo);
            const fetchResult = await fetchAndReceiveJSON({
                url: apiUrl + cartAddurl,
                method: 'POST',
                body: dataToSend,
            });
            if (fetchResult === "Login Required") {
                setLoginModal(true);
                setIsSpinnerShow(false);
            } else if (fetchResult === "One Purchased") {
                setCartModal(true)
                setIsSpinnerShow(false);
            } else if (fetchResult) {
                await callCartCount();
                setIsSpinnerShow(false);
                setCartSuccsessModalShow(true);
            } else {
                setIsSpinnerShow(false);
            }
        }
    };

    // useEffect(() => {

    //   const npayPlace = document.querySelector('.nPayPlace.mo');
    //   const placeToMoveNPayPlace = document.querySelector('.totalAndPaymentButtonWrapper');
    //   placeToMoveNPayPlace.insertBefore(
    //     npayPlace,
    //     document.querySelector('.chosen-options-and-total .total-price')
    //   );
    //   // alert(isInSearch);
    // }, []);

    const bodyOverFlowRef = useRef();
    const headeOverFlowRef = useRef();
    useEffect(() => {
        const searchResults = document.querySelector('.searchResults');

        if (!visible) {
            if (productData.product) {
                createCh();
                if (
                    /iP(hone)/.test(navigator.platform)
                ) {
                    document.body.style.removeProperty('overflow');
                    document.body.style.removeProperty('position');
                    document.body.style.removeProperty('left');
                    document.body.style.removeProperty('right');
                    //window.scrollTo({top: scrollPosition});
                    //const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
                    //const version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];

                    // if (version[0] >= 15) {
                    //     document.body.style.position = 'static';
                    // }
                } else {
                    document.body.style.removeProperty('overflow');
                }
                //document.body.style.overflow = bodyOverFlowRef.current;
                // if(searchResults){
                //   searchResults.style.overflow = headeOverFlowRef.current;

                // }
            }
        }
        if (productData.product && visible) {
            deleteCh();
            if (
                /iP(hone)/.test(navigator.platform)
            ) {
                document.body.style.overflow = 'hidden';
                document.body.style.position = 'fixed';
                document.body.style.left = '0';
                document.body.style.right = '0';
                //const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
                //const version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
                //
                // if (version[0] >= 15) {
                //     document.body.style.position = 'fixed';
                // }
            } else {
                document.body.style.overflow = 'hidden';
            }
            //bodyOverFlowRef.current = document.body.style.overflow || 'scroll';
            //document.body.style.overflow = 'hidden';
            // if(searchResults){
            //   headeOverFlowRef.current = searchResults.style.overflow || 'scroll';
            //   searchResults.style.overflow = 'visible';
            // }
        }
    }, [productData, visible]);

    const bgClickClose = (e) => {
        if (e.target.classList[0] === 'cartPopup-bg') {
            closeAction();
            setOptionInfo([]);
            setTotalPrice(0);
        } else {
            return;
        }
    };

    useEffect(() => {
        // 여기의 값을
        const naverpayButtonComponent = document.getElementById(`iamport-naverpay-product-button-main-cart${isInSearch ? 'search' : ''}`)
        const naverpayButtonComponent2 = document.getElementById(`iamport-naverpay-product-button-main-cart2${isInSearch ? 'search' : ''}`)
        if (naverpayButtonComponent && naverpayButtonComponent2) {
            makeNpayButton({
                elemIds: [
                    `iamport-naverpay-product-button-main-cart${isInSearch ? 'search' : ''}`,
                    `iamport-naverpay-product-button-main-cart2${isInSearch ? 'search' : ''}`,
                ],
                buttonKey: NpayButtonKey,
                buyAction: () => {
                    const dataToSend = makeDataForCart(infoRef.current);


                    if (!dataToSend || dataToSend.length === 0) {
                        setCustomAlert('옵션을 선택 해주세요');
                        return;
                    }

                    buyNowNpayAction({
                        dataToSend: makeDataForCart(dataToSend),
                        // dataToSend: makeDataForCart(optionInfo),
                    });
                },
                getProductsForNpayZzim: () => {
                    if (productDataRef.current) {
                        window.IMP.init(C_IAMPORT_CODE);
                        return getProductsForNpayZzim({
                            id: productDataRef.current.product?.id,
                            name: productDataRef.current.product?.name,
                            uprice: productDataRef.current.product?.price,
                            slug: productDataRef.current.product?.slug,
                            image: productDataRef.current.product?.thumbnail,
                        });
                    }
                },
                withZzim: true,
            });
        }
        return () => createCh();

    }, []);

    const addCartBtn = () => {
        if (optionInfo.length == 0) {
            return;
        }

        cartOpenOrAction();

    };

    const buyNowBtn = () => {
        if (optionInfo.length == 0) {
            return;
        }
        buyNowAction();

    };

    const npayBtnAction = () => {
        const npayBtnEleDt = document.querySelector('.cartPopup-bg.null .nPayPlace.dt .npay_btn_link');
        npayBtnEleDt.click();
    };


    return (
        <>
            {customAlert && (
                <Modal
                    text={customAlert}
                    buttonText="확인"
                    closeAction={() => {
                        setCustomAlert(null);
                    }}
                    alignText="center"
                    buttonAction={() => {
                        setCustomAlert(null);
                    }}
                />
            )}
            {cartModal && <Modal
                text={'장바구니에 해당 제품이 이미 담겨 있습니다. (해당 제품은 1회에 1개씩만 구매 가능합니다.)'}
                buttonText={'확인'}
                buttonText2={'장바구니 보기'}
                buttonAction={() => setCartModal(false)}
                buttonAction2={() => navigate('/cart')}
                closeAction={() => {
                    setCartModal(false);
                }}
            />}
            {onePurchaseModal && <Modal
                text={`해당 제품은 1회에 1개씩만 구매 가능합니다.`}
                buttonText={`확인`}
                buttonAction={() => {
                    setOnePurchaseModal(false);
                }}
                closeAction={() => {
                    setOnePurchaseModal(false);
                }}
            />}
            {loginModal && <Modal
                text={`해당 제품은 로그인 상태에서만 구매 가능합니다.`}
                buttonText={`가입/로그인하기`}
                buttonText2={`확인`}
                buttonAction={() => {
                    moveToLink('/signup/menu');
                }}
                closeAction={() => {
                    setLoginModal(false);
                }}
                buttonAction2={() => {
                    setLoginModal(false);
                }} alignText={undefined} oneButton={undefined} oneButtonText={undefined} oneButtonAction={undefined}
                vertiCalButton={undefined} withClose={undefined}/>}

            <div
                className={`cartPopup-bg ${isInSearch ? 'inSearch' : null}`}
                onClick={(e) => {
                    bgClickClose(e);
                }}
                style={{display: visible ? '' : 'none'}}
            >
                <div
                    className="cartPopup-box"
                >
                    <div className="cartPopup-contents">
                        <div className="cartPopup-images">
                            {imageData.length > 0 && (
                                <ImageSlider data={filterThumbnailImageByFormat(imageData)} sliderHeight="100%"
                                             alwaysSlide={true}/>
                            )}
                        </div>
                        <div style={{position: "relative"}}>
                            <div
                                className={productData?.product?.stock_status === "outofstock" ? "mobile-cartPopup-restock-wrapper" : "mobile-cartPopup-wrapper"}
                            >
                                <div
                                    className="closeIcon"
                                    onClick={() => {
                                        closeAction();
                                        setOptionInfo([]);
                                        setTotalPrice(0);
                                    }}>
                                     <span className="dt-close">
                                         <MediumBrownCloseIcon/>
                                     </span>
                                    <span className="mo-close">
                                        <WhiteCloseIcon/>
                                     </span>
                                </div>
                                <PurchaseMenu
                                    setLoginModal={setLoginModal}
                                    isLogin={isLogin}
                                    productData={productData}
                                    optionQuantityData={optionInfo}
                                    resetOptionInfo={resetOptionInfo}
                                    onOptionSelect={adjustIndividualOptionCount}
                                    totalPrice={totalPrice}
                                    cartOpenOrAction={addCartBtn}
                                    isButtonInclude={true}
                                    buyNowAction={buyNowBtn}
                                    isInMobileFloating={true}
                                    setHomeMobileCartHeight={setHomeMobileCartHeight}
                                    homeMobileCartHeight={homeMobileCartHeight}
                                    moveToOtherPage={true}
                                    isInSearch={isInSearch}
                                    isInPopUp={true}
                                />
                            </div>
                            {
                                productData?.product?.stock_status === "outofstock" &&
                                <RestockingButton productId={productData?.product.id}/>
                            }
                            {/*{*/}
                            {/*    productData?.product?.stock_status === "outofstock" &&*/}
                            {/*    <div className="restocking-button-wrapper">*/}
                            {/*        <div className="temporarily-out-of-stock">*/}
                            {/*            <div className="temporarily-out-of-stock-text">*/}
                            {/*                일시품절*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                            <div style={{display: productData?.product?.stock_status === "outofstock" ? "none" : ""}}>
                                <div className="dropdownBtnArea">
                                    {/* deskTop btns */}
                                    <div className="desktop-button-wrapper deskTopOnly" ref={buttonWrapperRef}>
                                        <div className="first-row-btns">
                                            <CTAButton className="desktop-button" color="white-cta"
                                                       onClick={addCartBtn}>
                                                장바구니 담기
                                            </CTAButton>
                                            <CTAButton className="desktop-button" color="orange-cta"
                                                       onClick={buyNowBtn}>
                                                바로 구매하기
                                            </CTAButton>
                                        </div>
                                        <div className="second-row-btns">
                                            <div className="npayBtn" style={{marginTop: '16px'}}>
                                                {/* <div className="npay-custom-btn" onClick={npayBtnAction}>
                <img className="npay-img" src="/img/button_npay_text.png"></img>
              </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    {/* mobile btns */}
                                    <div className="desktop-button-wrapper bottomCta mobileOnly" ref={buttonWrapperRef}>
                                        <div className="first-row-btns">
                                            <CTAButton className="desktop-button" color="white-cta"
                                                       onClick={addCartBtn}>
                                                장바구니 담기
                                            </CTAButton>
                                            <CTAButton className="desktop-button" color="orange-cta"
                                                       onClick={buyNowBtn}>
                                                바로 구매하기
                                            </CTAButton>
                                        </div>
                                        <div className="second-row-btns">
                                            <div className="npayBtn" style={{marginTop: '8px'}}>
                                                {/* <div className="npay-custom-btn" onClick={npayBtnAction}>
                <img className="npay-img" src="/img/button_npay_text.png"></img>
              </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="nPayPlace mo"
                                    id={`iamport-naverpay-product-button-main-cart${isInSearch ? 'search' : ''}`}
                                    // style={{ display: "none" }}
                                >
                                    {/* npay가 동적으로 들어가는 곳 */}
                                </div>
                                <div
                                    className="nPayPlace dt"
                                    id={`iamport-naverpay-product-button-main-cart2${isInSearch ? 'search' : ''}`}
                                    // style={{ display: "none" }}
                                >
                                    {/* npay가 동적으로 들어가는 곳 */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*    <PopupWrapper*/}
                {/*        popUpCloseAction={() => {*/}
                {/*            setIsUpSellingPopUp(false);*/}
                {/*        }}*/}
                {/*        className={`upSellingPopUp ${isUpSellingPopUp ? 'visible' : 'invisible'}`}*/}
                {/*        aleadyRendered={true}*/}
                {/*        aleadyRenderedVisibility={isUpSellingPopUp}*/}
                {/*    >*/}
                {/*        <div className="upSellContent">*/}
                {/*            <div className="discountBadge">최대 {upSellDiscountRate} 할인</div>*/}

                {/*            <div className="upSellText1">*/}
                {/*                {upSellText1.split('\n').map((line, i) => (*/}
                {/*                    <span key={`text${i}`}>*/}
                {/*      {line}*/}
                {/*                        <br/>*/}
                {/*    </span>*/}
                {/*                ))}*/}
                {/*            </div>*/}

                {/*            <div className="upSellText2">*/}
                {/*                {upSellText2.split('\n').map((line, i) => (*/}
                {/*                    <span key={`text${i}`}>*/}
                {/*      {line}*/}
                {/*                        <br/>*/}
                {/*    </span>*/}
                {/*                ))}*/}
                {/*            </div>*/}

                {/*            {upSellImage && <img src={upSellImage} className="upSellImage"></img>}*/}

                {/*            <CTAButton*/}
                {/*                className="upSellButton"*/}
                {/*                onClick={() => {*/}
                {/*                    window.location.href = upSellLink;*/}
                {/*                }}*/}
                {/*                width={undefined}*/}
                {/*                height={undefined}*/}
                {/*                font={undefined}*/}
                {/*                color={undefined}*/}
                {/*            >*/}
                {/*                {upSellButtonText}*/}
                {/*            </CTAButton>*/}

                {/*            <span*/}
                {/*                className=" upSellClose mo"*/}
                {/*                style={{cursor: 'pointer'}}*/}
                {/*                onClick={() => {*/}
                {/*                    setIsUpSellingPopUp(false);*/}
                {/*                }}*/}
                {/*            >*/}
                {/*  <WhiteCloseIcon color={undefined}/>*/}
                {/*</span>*/}
                {/*            <span*/}
                {/*                className="upSellClose dt"*/}
                {/*                style={{cursor: 'pointer'}}*/}
                {/*                onClick={() => {*/}
                {/*                    setIsUpSellingPopUp(false);*/}
                {/*                }}*/}
                {/*            >*/}
                {/*  <MediumBrownCloseIcon/>*/}
                {/*</span>*/}
                {/*        </div>*/}
                {/*    </PopupWrapper>*/}
            </div>

            {cartSuccsessModalShow && (
                <Modal
                    text={'선택한 상품이 \n 장바구니에 잘 담겼어요.'}
                    closeAction={() => {
                        setCartSuccsessModalShow(false);
                    }}
                    buttonText2={'장바구니 보기'}
                    buttonText={'계속 쇼핑할게요'}
                    buttonAction2={() => {
                        navigate('/cart');
                    }}
                    buttonAction={() => {
                        setCartSuccsessModalShow(false);
                    }}
                    alignText={undefined}
                    oneButton={undefined}
                    oneButtonText={undefined}
                    oneButtonAction={undefined}
                    vertiCalButton={undefined}
                    withClose={undefined}
                />
            )}
        </>
    );
};

export default CartPopUp;
