import React, {useEffect, useRef, useState} from 'react';
import './TopBanner.scss';
import {moveToLink, msToDHM, msToHMS, stringLineBreak} from '../../utils/utils.js';
import {breakpointDt} from '../../constants/constants';
import {WhiteCloseIcon} from '../../../assets/CloseIcon';
import {useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../../context/AppContext";

export interface TopBannerProps {
    data: {
        title: string,
        text_color: string,
        background_color: string,
        url?: string,
        isFatInMobile?: boolean
        isNoCloseButton?: boolean;
        end_date?: string,
        start_date?: string,

    },
    // setIsTopBannerEnabled? : any,
    id?: string

}


const TopBanner: React.FC<TopBannerProps> = ({
                                                 data,
                                                 // setIsTopBannerEnabled,
                                                 ...otherProps
                                             }) => {

    if (!data) {
        return <span></span>;
    }
    const {bannerDataStore} =
        React.useContext(AppContext);
    const {setIsTopBannerClose} = bannerDataStore
    const location = useLocation()
    const {title, text_color, background_color, url, isNoCloseButton, end_date} = data;
    const [visible, setVisible] = useState(data && !sessionStorage.getItem('topBannerSession') ? true : false);
    const topBannerMoRef = useRef<HTMLDivElement>();
    const topBannerDtRef = useRef<HTMLDivElement>();
    const [mainTitle, setMainTitle] = useState(title)


    useEffect(() => {
        const topBannerSession = sessionStorage.getItem('topBannerSession');
        if (topBannerSession) {
            setVisible(false);
        }
    }, []);

    useEffect(() => {
        if (title.includes('end_date_count')) {
            checkTitleParma(title)
            const interval = setInterval(() => checkTitleParma(title), 1000)
            return () => clearInterval(interval)
        }
    }, [title])

    const checkTitleParma = (title) => {
        if (end_date && end_date !== "") {
            const replaceEndDate = end_date.replaceAll("-", "/")
            const diffTime = new Date(replaceEndDate).getTime() - new Date().getTime()
            const remindTime = msToDHM(diffTime);
            const mainTitleReplace = title.replace('${end_date_count}', `<span style="color:#E60012"}>${remindTime}</span>`)
            setMainTitle(mainTitleReplace)
        }
    }

    useEffect(() => {
        topBannerResizeHandler()
    }, [visible]);

    useEffect(() => {
        window.addEventListener('resize', topBannerResizeHandler);
        return () => {
            window.removeEventListener('resize', topBannerResizeHandler);
        }
    }, [])

    const topBannerResizeHandler = () => {
        if (visible) {
            // setIsTopBannerEnabled && setIsTopBannerEnabled(true);
            const topBannerMoHeight = topBannerMoRef.current.getBoundingClientRect().height;
            const topBannerDtHeight = topBannerDtRef.current.getBoundingClientRect().height;

            if (window.innerWidth < breakpointDt) {
                document.body.style.paddingTop = `${topBannerMoHeight}px`;
            } else {
                document.body.style.paddingTop = `${topBannerDtHeight}px`;
            }


        } else {
            // setIsTopBannerEnabled && setIsTopBannerEnabled(false);
            document.body.style.paddingTop = '0px';
        }
    }

    return (

        <>
            <div className="topBannerMo"
                 ref={topBannerMoRef}
                 style={{
                     display: visible ? 'flex' : 'none',
                     backgroundColor: background_color, color: text_color,
                     height: data.isFatInMobile ? '56px' : ''

                 }}
                 data-testid="test1"
                 {...otherProps}
                 onClick={(e) => {
                     url && moveToLink(url, e);
                 }}
            >
                {/* 탑베너 들어갈 자리 */}
                <div className="topBanner-text"
                     style={{cursor: url ? 'pointer' : 'default'}}
                     dangerouslySetInnerHTML={{__html: mainTitle}}
                ></div>
                {!isNoCloseButton && <div className="close-btn"
                                          onClick={(e) => {
                                              setIsTopBannerClose(true)
                                              setVisible(false);
                                              sessionStorage.setItem('topBannerSession', 'true');
                                              e.preventDefault()
                                              e.stopPropagation()
                                          }}
                                          data-testid="close-icon">
                    <WhiteCloseIcon
                        color={text_color}
                    />
                </div>}
            </div>

            <div className={`topBannerDt ${visible ? 'flex' : 'none'}`}
                 style={{backgroundColor: background_color, color: text_color, cursor: url ? 'pointer' : 'default'}}
                 data-testid="test2"
                 ref={topBannerDtRef}
                 onClick={(e) => {
                     if (url) moveToLink(url, e);
                 }}
            >
                {/* 탑베너 들어갈 자리 */}
                <div className="topBanner-text"
                     onClick={(e) => {
                         if (url) moveToLink(url, e);
                     }}
                     dangerouslySetInnerHTML={{__html: mainTitle}}></div>
                {!isNoCloseButton && <div className="close-btn"
                                          onClick={(e) => {
                                              setIsTopBannerClose(true)
                                              setVisible(false);
                                              sessionStorage.setItem('topBannerSession', 'true');
                                              e.preventDefault()
                                              e.stopPropagation()
                                          }}>
                    <WhiteCloseIcon
                        color={text_color}
                    />
                </div>}
            </div>
        </>


    );

};


export default TopBanner;