import React from "react"
import {useParams} from "react-router";

export const VideoRedirectPage = (id) => {
    const userAgent = navigator.userAgent.toLowerCase();
    const data = {
        "mw-official-kr": {
            ios: "vnd.youtube://www.youtube.com/watch?v=yZduOWL4DlE",
            android: "intent://www.youtube.com/watch?v=yZduOWL4DlE#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/yZduOWL4DlE"
        },
        "mw-yuja-kr": {
            ios: "vnd.youtube://www.youtube.com/watch?v=Jq8ygK1rGBQ",
            android: "intent://www.youtube.com/watch?v=Jq8ygK1rGBQ#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/Jq8ygK1rGBQ"
        },
        "mw-violet-kr": {
            ios: "vnd.youtube://www.youtube.com/watch?v=jxqeL8JQTEI",
            android: "intent://www.youtube.com/watch?v=jxqeL8JQTEI#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/jxqeL8JQTEI"
        },
        "mw-juniper-kr": {
            ios: "vnd.youtube://www.youtube.com/watch?v=xSX2EGarf3s",
            android: "intent://www.youtube.com/watch?v=xSX2EGarf3s#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/xSX2EGarf3s"
        },
        "mw-official-eng": {
            ios: "vnd.youtube://www.youtube.com/watch?v=2FFp0CLC8ZY",
            android: "intent://www.youtube.com/watch?v=2FFp0CLC8ZY#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/2FFp0CLC8ZY"
        },
        "mw-yuja-eng": {
            ios: "vnd.youtube://www.youtube.com/watch?v=Ka2MCS5MyGg",
            android: "intent://www.youtube.com/watch?v=Ka2MCS5MyGg#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/Ka2MCS5MyGg"

        },
        "mw-violet-eng": {
            ios: "vnd.youtube://www.youtube.com/watch?v=UodtbuA6FTo",
            android: "intent://www.youtube.com/watch?v=UodtbuA6FTo#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/UodtbuA6FTo"
        },
        "mw-juniper-eng": {
            ios: "vnd.youtube://www.youtube.com/watch?v=ymIbYnRQBbU",
            android: "intent://www.youtube.com/watch?v=ymIbYnRQBbU#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/ymIbYnRQBbU"
        },
        "mw-official-jp": {
            ios: "vnd.youtube://www.youtube.com/watch?v=yZduOWL4DlE",
            android: "intent://www.youtube.com/watch?v=yZduOWL4DlE#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/yZduOWL4DlE"
        },
        "mw-yuja-jp": {
            ios: "vnd.youtube://www.youtube.com/watch?v=Jq8ygK1rGBQ",
            android: "intent://www.youtube.com/watch?v=Jq8ygK1rGBQ#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/Jq8ygK1rGBQ"
        },
        "mw-violet-jp": {
            ios: "vnd.youtube://www.youtube.com/watch?v=jxqeL8JQTEI",
            android: "intent://www.youtube.com/watch?v=jxqeL8JQTEI#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/jxqeL8JQTEI"
        },
        "mw-juniper-jp": {
            ios: "vnd.youtube://www.youtube.com/watch?v=xSX2EGarf3s",
            android: "intent://www.youtube.com/watch?v=xSX2EGarf3s#Intent;package=com.google.android.youtube;scheme=https;end",
            web: "https://youtu.be/xSX2EGarf3s"
        }
    }
    const targetLink = data[id]
    if (userAgent.indexOf("iphone") > -1 ||
        userAgent.indexOf("ipad") > -1 ||
        userAgent.indexOf("ipod") > -1) {
        try {
            window.location.href = targetLink.ios
        } catch (e) {
            window.location.href = targetLink.web
        }
    } else if (userAgent.indexOf("android") > -1) {
        try {
            window.location.href = targetLink.android
        } catch (e) {
            window.location.href = targetLink.web
        }
    } else {
        window.location.href = targetLink.web
    }
    return
}
