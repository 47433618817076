import {observer} from 'mobx-react';
import React, {FunctionComponent, useEffect, useState, useRef} from 'react';
import {AppContext} from '../../context/AppContext';
import {collectionMeta} from '../../@types/types';
import './CollectionSelector.scss';
import {breakpointDt} from '../../constants/constants';
import DropdownCloseIcon from '../../../img/dropdownclose.svg'
import DropdownOpenIcon from '../../../img/dropdownopen.svg'
import {useNavigate} from "react-router-dom";


const CollectionSelector: FunctionComponent = observer(() => {
    const [isOpen, setIsOpen] = useState<boolean>();
    const {collectionPageStore} = React.useContext(AppContext);
    const [parentCategory, setParentCategory] = useState(null);
    const [subCategories, setSubCategories] = useState<any>(
        collectionPageStore.collectionNow.subCategories
    );
    const navigate = useNavigate()


    const parentCategoryRef = useRef(parentCategory);

    useEffect(() => {
        parentCategoryRef.current = parentCategory;
    }, [parentCategory]);

    // const removeFocusOnElements = (collectionSelectors : NodeListOf<Element>) =>{

    //   collectionSelectors.forEach( selector =>{
    //     selector.classList.remove('focus');
    //   }
    //   );

    // };

    const focusToSelectedCategory = (selectedId: string, isSub = false) => {
        const collectionSelectors = isSub
            ? document.querySelectorAll('.collectionSelect.sub')
            : document.querySelectorAll('.collectionSelect:not(.sub)');

        collectionSelectors.forEach((selector) => {
            if (selectedId == selector.id) {
                selector.classList.add('focus');
            } else {
                selector.classList.remove('focus');
            }
        });
    };

    function renderSubCategoriesInStickyNav(collection: collectionMeta) {

        setParentCategory(collection);
        //change subCategories
        setSubCategories(collection.subCategories);
        if (collection.subCategories.length < 1) {

            const collectionSelectWrapper = document.querySelector('.collectionSelectWrapper.expand');
            if (collectionSelectWrapper !== undefined && collectionSelectWrapper !== null) {
                collectionSelectWrapper.classList.remove('expand');

            }
        } else {
            const collectionSelectWrapper = document.querySelector('.collectionSelectWrapper.sub');
            if (collectionSelectWrapper !== undefined && collectionSelectWrapper !== null) {
                collectionSelectWrapper.classList.add('expand');
            }
        }

    }

    useEffect(() => {
        renderSubCategoriesInStickyNav(collectionPageStore.collectionNow);

        const addCloseEventOnBody = (e) => {
            const firstClass = (e.target as HTMLTextAreaElement).classList[0];

            if (
                firstClass === 'curCollection' ||
                firstClass === 'dropdownlogo'
                // || firstClass === 'collectionSelect'
            ) {
                return;
            }

            if (firstClass === 'collectionSelect' && e.target.dataset['hassubcollection']) {
                return;
            }
            setIsOpen(false);
        };
        if (document.body) {
            document.body.addEventListener('click', addCloseEventOnBody);
        }

        return () => {
            document.body.removeEventListener('click', addCloseEventOnBody);
        };
    }, [collectionPageStore.allCollections]);

    useEffect(() => {
        if (isOpen == false) {
            if (collectionPageStore.collectionNow) {
                setParentCategory(collectionPageStore.collectionNow);
            }
        } else {
            if (collectionPageStore.collectionNow) {
                focusToSelectedCategory(collectionPageStore.collectionNow.id);
            }
            //const menus = document.querySelectorAll('.collectionSelect:not(.sub)');
        }
    }, [isOpen]);

    // 닫힐 때 마다 포커싱 되는 컴포넌트를 현재 focus Component로 바꾸는 effect
    // useEffect(() =>{

    //   const collectionSelectors = document.querySelectorAll('.collectionSelect');
    //   removeFocusOnElements(collectionSelectors);
    //   collectionSelectors.forEach( selector => {
    //     if(selector.id == collectionPageStore.collectionNow.id){
    //       selector.classList.add('focus');
    //     }
    //   });

    //   //redner 필요
    //   renderSubCategoriesInStickyNav(collectionPageStore.collectionNow);

    //   const subCollectionSelectors = document.querySelectorAll('.collectionSelect.sub');
    //   removeFocusOnElements(subCollectionSelectors);
    //   subCollectionSelectors.forEach( selector => {
    //     if(selector.id == collectionPageStore.getSubCollectionNow().slug){
    //       selector.classList.add('focus');
    //     }

    //   });
    // }, [isOpen, collectionPageStore.collectionNow] );

    const pathEle = [];
    const paths = collectionPageStore.getCollectionTitle();
    if (paths !== null || paths.length > 1) {
        paths.map((path, i) => {
            if (i == 0) {
                pathEle.push(<div className="path">{path}</div>);
            } else {
                pathEle.push(
                    <>
                        <div className="arrow"></div>
                        <div className="path">{path} </div>
                    </>
                );
            }
        });
    } else if (paths.length > 0) {
        pathEle.push(<div className="path">{paths[0]}</div>);
    }

    const pathsEle = <div className="paths">{pathEle}</div>;

    return (
        <div
            className="collectionSelectorWrapper"
            // onClick={()=>{
            //   setIsOpen(!isOpen);
            // }}
        >
            <div
                className="curCollection"
                style={{
                    opacity: collectionPageStore.collectionNow.title
                        ? 1
                        : collectionPageStore.defaultCollectionTitle.length === 0
                            ? 0
                            : 1,
                    transform: collectionPageStore.collectionNow.title
                        ? 'unset'
                        : collectionPageStore.defaultCollectionTitle.length === 0
                            ? 'translateY(20px)'
                            : 'unset',
                    transition: 'opacity 800ms 500ms, transform 800ms 500ms',
                }}
                onClick={() => {
                    setIsOpen(isOpen ? false : true);
                    const collectionBackgroundWrapper = document.querySelector('.collectionHeadComponent');
                    if (isOpen) {
                        collectionBackgroundWrapper.classList.remove('expand');
                    } else {
                        collectionBackgroundWrapper.classList.add('expand')
                    }
                }}
            >
                <div className="stickyHeader">{pathsEle}</div>
                {/* { collectionPageStore.collectionNow.title : collectionPageStore.defaultCollectionTitle}   */}
                {isOpen ? (
                    <img className="dropdownlogo" src={DropdownCloseIcon} alt=""/>
                ) : (
                    <img className="dropdownlogo" src={DropdownOpenIcon} alt=""/>
                )}
            </div>

            {/* <div className={`collectionSelectWrapper sub ${isOpen ? '' : 'hidden'} ${subCategories.length < 0 ? 'expand' : ''}`} > */}
            <div
                className={`collectionSelectWrapper sub ${isOpen ?'opened' : 'hidden'} ${
                    collectionPageStore.collectionNow.subCategories.length > 0 ? 'expand' : ''
                }`}
            >
                <div className={'menusSelectArea'}>
                    {collectionPageStore.allCollections &&
                        collectionPageStore.allCollections.filter(item => item.isExpose).map((collection, i) => {
                            return (
                                <div
                                    key={i}
                                    id={collection.id}
                                    className={`collectionSelect ${
                                        collectionPageStore.collectionNow &&
                                        collectionPageStore.collectionNow.id === collection.id
                                            ? 'focus'
                                            : ''
                                    }`}
                                    data-hassubcollection={collection.subCategories.length > 0}
                                    onClick={(e) => {
                                        // const menus = document.querySelectorAll('.collectionSelect:not(.sub)');
                                        // removeFocusOnElements(menus);
                                        // (e.target as HTMLTextAreaElement).classList.add('focus');

                                        focusToSelectedCategory(collection.id);
                                        //서브 카테고리 있을 시, 페이지 이동 x & subCategory 표시
                                        if (collection.subCategories.length > 0) {
                                            renderSubCategoriesInStickyNav(collection);
                                        } else {
                                            collectionPageStore.setCollectionNow(collection);
                                            navigate(`/collection/${collection.url}`)

                                        }
                                    }}
                                    onMouseOver={(e) => {
                                        if (window.innerWidth <= breakpointDt) {
                                            return;
                                        }
                                        //active
                                        // const menus = document.querySelectorAll('.collectionSelect:not(.sub)');
                                        // removeFocusOnElements(menus);
                                        // (e.target as HTMLTextAreaElement).classList.add('focus');
                                        focusToSelectedCategory(collection.id);
                                        renderSubCategoriesInStickyNav(collection);
                                    }}
                                >
                                    {collection.title}
                                </div>
                            );
                        })}
                </div>
                {parentCategory && subCategories && subCategories.length > 0 && (
                    <div className={'subSelectArea'}>
                        {subCategories.map((subCategory, i) => {
                            return (
                                <div
                                    key={`sub${i}`}
                                    id={subCategory.slug}
                                    className={`collectionSelect sub ${
                                        collectionPageStore.collectionNow.subCategory &&
                                        collectionPageStore.collectionNow.subCategory.slug == subCategory.slug
                                            ? 'focus'
                                            : ''
                                    }`}
                                    onClick={(e) => {
                                        const collectionNow = parentCategoryRef.current;
                                        collectionNow.subCategory = subCategory;
                                        collectionPageStore.setCollectionNow(JSON.parse(JSON.stringify(collectionNow)));
                                        navigate(`/collection/${collectionNow.url}`)
                                    }}
                                    onMouseOver={(e) => {
                                        if (window.innerWidth <= breakpointDt) {
                                            return;
                                        }

                                        focusToSelectedCategory(subCategory.slug, true);

                                        // const subMenus = document.querySelectorAll('.collectionSelect.sub');
                                        // removeFocusOnElements(subMenus);
                                        // (e.target as HTMLTextAreaElement).classList.add('focus');
                                    }}
                                >
                                    {subCategory.name}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
});

export default CollectionSelector;
