
import React from 'react';

export const Minus = ()=>{

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" fillRule="evenodd" stroke="#C0A691" strokeLinecap="square" d="M7 12.5L17 12.5"/>
</svg>


    )
}



export const Plus = ()=>{

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd" stroke="#C0A691" strokeLinecap="square">
            <path d="M0 5.5L10 5.5M5.357.367L5.357 10.633" transform="translate(7 7)"/>
        </g>
    </svg>



    )
}


export const SmallPlus =() =>{
    
    return(

        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
    <g fill="none" fillRule="evenodd" strokeLinecap="square">
        <g stroke="#C0A691">
            <g>
                <g>
                    <path d="M0 5.5L10 5.5M5.357.367L5.357 10.633" transform="translate(-238 -590) translate(208 560) translate(31 31)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
    )
    
}

export const Delete = () =>{


    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" style={{cursor: 'pointer'}}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H20V20H0z"/>
            <g stroke="#C0A691">
                <path d="M12.123 2.037c0 .02 0 .04-.002.06l-.754 9.802c-.032.46-.413.815-.873.815H3.378c-.46 0-.842-.356-.873-.815L1.75 2.097" transform="translate(3 4)"/>
                <path strokeLinecap="round" d="M0.438 2.188L13.563 2.188" transform="translate(3 4)"/>
                <path d="M4.375 1.75V.834c0-.46.392-.834.875-.834h3.5c.483 0 .875.373.875.834v.916" transform="translate(3 4)"/>
                <path strokeLinecap="round" d="M5.688 4.813L5.688 10.063M8.313 4.813L8.313 10.063" transform="translate(3 4)"/>
            </g>
        </g>
    </svg>

    )
   
}