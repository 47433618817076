import {makeAutoObservable} from 'mobx';
import {topBanner} from '../@types/types';
import {fetchAndReceiveJSON} from '../utils/utils';
import {apiUrl} from "../constants/constants";

// import TopBanner from '../components/TopBanner/TopBanner.component';

export class BannerDataStore {
    topBannerData;
    isTopBannerClose = false;

    constructor() {
        makeAutoObservable(this);
    }

    fetchTopBannerData = async () => {
        this.topBannerData = await fetchAndReceiveJSON({
            url: apiUrl + '/api/top-line-banner',
            method: 'GET',
            body: '',
        });
    };

    setTopBannerData = (data: topBanner) => {
        this.topBannerData = data;
    };

    setIsTopBannerClose = (flag: boolean) => {
        this.isTopBannerClose = flag;
    }


    callTopBannerInfo = () => {
        this.fetchTopBannerData();
    };
}
