import React, {useEffect, useMemo, useState, Suspense} from "react"
import ProductVideoSectionComponent from "./Templates/ProductVideoSection/ProductVideoSection.component";
import {breakpointDt, breakpointDt_mid} from "../../constants/constants";
import "./CollinsHomeRenewal.scss"
import MoodwaterSectionComponent from "./Templates/MoodwaterSection/MoodwaterSection.component";

interface IProps {
    userName: string;
    setDataForCart?: CallableFunction;
    setIsCartPopUp?: CallableFunction;
    setImageDataForCart?: CallableFunction;
}


function CollinsHomeRenewalComponent(props: IProps) {
    const {userName, setDataForCart, setImageDataForCart, setIsCartPopUp} = props
    const [device, setDevice] = useState<"MB" | "TB" | "DT">("MB")
    const getDevice = () => {
        if (breakpointDt > window.innerWidth && window.innerWidth >= breakpointDt_mid) {
            setDevice("TB")
        } else if (window.innerWidth >= breakpointDt) {
            setDevice("DT")
        } else {
            setDevice("MB")
        }
    }


    const ProductCardASwiperSectionComponent = useMemo(
        () => React.lazy(() => import('./Templates/ProductCardASwiperSection/ProductCardASwiperSection.component')), []);

    const ProductCardBSectionComponent = useMemo(
        () => React.lazy(() => import("./Templates/ProductCardBSection/ProductCardBSection.component")), []);

    const ProductSwiperSectionComponent = useMemo(
        () => React.lazy(() => import("./Templates/ProductSwiperSection/ProductSwiperSection.component")), [])

    const GoodNightSectionComponent = useMemo(
        () => React.lazy(() => import("./Templates/GoodNightSection/GoodNightSection.component")), [])

    const BrandGoodsBlackSectionComponent = useMemo(
        () => React.lazy(() => import("./Templates/BrandGoodsBlackSection/BrandGoodsBlackSection.component")), [])

    const ContentsBannerSectionComponent = useMemo(
        () => React.lazy(() => import("./Templates/ContentsBannerSection/ContentsBannerSection.component")), [])

    const HomeRenewalFooterComponent = useMemo(
        () => React.lazy(() => import("./Templates/HomeRenewalFooter/HomeRenewalFooter.component")), [])

    const MoodwaterSectionComponent = useMemo(() => React.lazy(() => import("./Templates/MoodwaterSection/MoodwaterSection.component")), [])


    useEffect(() => {
            getDevice()
            window.addEventListener('resize', function () {
                getDevice()
            });

            return () => {
                window.removeEventListener('resize', function () {
                    getDevice()
                })
            }
        }
        , [])

    return (
        <div className="collins-home-renewal-wrapper">
            <ProductVideoSectionComponent device={device}/>
            <Suspense fallback={<div style={{minHeight: '100vh'}}></div>}>
                <MoodwaterSectionComponent setDataForCart={setDataForCart}
                                           setIsCartPopUp={setIsCartPopUp}
                                           setImageDataForCart={setImageDataForCart}/>
            </Suspense>
            {/*<Suspense fallback={<div style={{minHeight: '100vh'}}></div>}>*/}
            {/*    <ProductSwiperSectionComponent device={device}/>*/}
            {/*</Suspense>*/}
            <Suspense fallback={<div style={{minHeight: '100vh'}}></div>}>
                <ProductCardASwiperSectionComponent device={device}
                                                    setDataForCart={setDataForCart}
                                                    setIsCartPopUp={setIsCartPopUp}
                                                    setImageDataForCart={setImageDataForCart}/>
            </Suspense>
            <Suspense fallback={<div style={{minHeight: '100vh'}}></div>}>
                <ProductCardBSectionComponent device={device}
                                              setDataForCart={setDataForCart}
                                              setIsCartPopUp={setIsCartPopUp}
                                              setImageDataForCart={setImageDataForCart}/>
            </Suspense>
            <Suspense fallback={<div style={{minHeight: '100vh'}}></div>}>
                <GoodNightSectionComponent device={device} userName={userName}/>
            </Suspense>
            <Suspense fallback={<div style={{minHeight: '100vh'}}></div>}>
                <BrandGoodsBlackSectionComponent device={device} userName={userName}/>
            </Suspense>
            <Suspense fallback={<div style={{minHeight: '100vh'}}></div>}>
                <ContentsBannerSectionComponent/>
            </Suspense>
            <Suspense fallback={<div></div>}>
                <HomeRenewalFooterComponent/>
            </Suspense>
        </div>)

}

export default CollinsHomeRenewalComponent
