import React, {memo, useEffect} from "react"
import VideoTitleComponent from "../../Atoms/VideoTitle/VideoTitle.component";
import "./ProductVideoSection.scss"
import {AppContext} from "../../../../context/AppContext";
import {observer} from "mobx-react";

interface IProps {
    device: "MB" | "TB" | "DT"
}

const ProductVideoSectionComponent: React.FC<IProps> = observer(({device}) => {
    const {bannerDataStore} =
        React.useContext(AppContext);
    const {isTopBannerClose} = bannerDataStore

    const isShowTopBanner = (flag = false) => {
        const topBannerId = document.getElementById('topBanner')
        if (topBannerId) {
            const topBannerHeight = topBannerId.getBoundingClientRect().height;
            const productVideoElement = document.getElementById('productVideoSection')
            if (productVideoElement && (topBannerHeight > 0 || flag)) {
                const documentHeight = document.body.getBoundingClientRect().height;
                productVideoElement.style.height = `${documentHeight - topBannerHeight}px`;
                productVideoElement.style.maxHeight = `${documentHeight - topBannerHeight}px`;

            }
        }
    }

    useEffect(() => {
        isShowTopBanner()
    }, [device])

    useEffect(()=>{
        if(isTopBannerClose){
            isShowTopBanner(true)
        }
    },[isTopBannerClose])

    return (
        <div className="product-video-section-wrapper" id={'productVideoSection'}>
            <VideoTitleComponent device={device}/>
        </div>
    )
})

export default ProductVideoSectionComponent