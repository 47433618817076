import React, {useEffect} from 'react';
import './DetailImages.scss'
import ImageSlider from '../ImageSlider/ImageSlider.component.jsx';
import {breakpointDt_mid} from "../../constants/constants";


const DetailImages = ({imageData, setLoadedThumbnail, soldOutStatus}) => {


    // useEffect(() => {
    //     preload(imageData)
    // }, [])
    //
    // const preload = (imageArray) => {
    //     if (imageArray) {
    //         const img = new Image();
    //         img.onload = () => {
    //             setLoadedThumbnail()
    //         }
    //         img.src = imageArray[0].src
    //     }
    // }
    return (
        // 이 넓이가 모바일이면50% 가 되어야 함

        <div className="detail-image-wrapper">
            <div className="detail-inner-position">
                {soldOutStatus &&
                    <div className={'outOfStockArea'}>
                        <div className={'outOfStock-text'}>sold out</div>
                    </div>}
                {
                    imageData &&
                    <ImageSlider data={imageData} sliderHeight="100%" isImageThumbnail={true}
                                 isHoverCursorEnabled={true}/>
                }
            </div>


        </div>

    );
};


export default DetailImages
